import { useEffect, useState } from 'react';
import createCtx from '../../../../App/contexts/GenenricStateContext';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import useFolder from '../../../folders/useFolder';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import { getMultiPlotSelectState } from '../multiPlotSelectSlice';
import { checkForDuplicatePlotThunk } from '../multiPlotSelectThunk';
import DuplicatePlotsContent from './DuplicatePlotsContent';
import SelectFolderContent from './SelectFolderContent';
import styles from './multiselectPlotModal.module.scss';

export interface IMultiSelectPlotModalContentProps {
  onCancel: () => void;
  onClose: () => void;
  plots: Omit<ICreatePlotStudy, 'folder' | 'responsable'>[] | null;
}
const [ctx, MultiselectModalProvider] = createCtx<IFolder | null>(null);
export const MultiselectModalContext = ctx;

function MultiSelectPlotModalContent({
  onCancel,
  onClose,
  plots,
}: IMultiSelectPlotModalContentProps) {
  const [checked, setChecked] = useState(false);
  const { duplicatePlots, duplicatePlotCount } = useAppSelector(
    getMultiPlotSelectState
  );
  const { folders, isLoading } = useFolder({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (((plots && plots.length) ?? 0 > 0) && folders && !checked) {
      setChecked(true);
      dispatch(checkForDuplicatePlotThunk({ plotIds: plots }));
    }
  }, [folders]);

  if (isLoading) {
    return <IndividualCircularLoader size={100} />;
  }

  return (
    <div className={styles.multiselectPlotModal}>
      {duplicatePlots.apiStatus === APIStatus.PENDING && (
        <>
          <h3 className={styles.checkPlotTitle}>Vérification des parcelles ...</h3>
          <IndividualCircularLoader size={150} />
        </>
      )}

      {duplicatePlotCount > 0 && (
        <DuplicatePlotsContent
          duplicatePlots={duplicatePlots.result ?? []}
          duplicatePlotCount={duplicatePlotCount}
          onClose={onCancel}
        />
      )}

      {duplicatePlots.apiStatus === APIStatus.IDLE &&
        duplicatePlots.result !== null &&
        duplicatePlots.result?.length === 0 &&
        plots && (
          <MultiselectModalProvider>
            <SelectFolderContent
              onCancel={onCancel}
              onClose={onClose}
              plotCount={plots.length}
              plots={plots}
            />
          </MultiselectModalProvider>
        )}
    </div>
  );
}

export default MultiSelectPlotModalContent;
