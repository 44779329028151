import React, { Context, useCallback, useContext, useEffect, useRef } from 'react';
import './addFormDisplay.scss';

interface IAddFormDisplay {
  labelOpen: string;
  labelClose: string;
  children: JSX.Element;
  closeForm?: () => void;
  ForceOpenContext: Context<{
    state: boolean;
    update: React.Dispatch<React.SetStateAction<boolean>>;
  }>;
  scroll?: (offset: number) => void;
  onClick?: () => void;
}
export default function AddFormDisplay({
  labelOpen,
  labelClose,
  children,
  ForceOpenContext,
  scroll,
  onClick,
}: IAddFormDisplay) {
  const scrollRef = useRef(null);
  // import context from props because this component can use more than 1 generic context
  const { state, update } = useContext(ForceOpenContext);

  useEffect(() => {
    if (state && scroll) {
      // à mettre en place sur le right-panel-body-content

      scroll(100);
    }
  }, [state]);

  const closeForm = useCallback(() => {
    update(false);
  }, []);

  return (
    <>
      <div
        ref={scrollRef}
        className="display-button"
        onClick={() => {
          update(!state);
          onClick && onClick();
        }}
      >
        <div className="addImage">{state ? '-' : '+'}</div>
        <p className="display-label">{state ? labelClose : labelOpen}</p>
      </div>
      {state && React.cloneElement(children, { closeForm })}
    </>
  );
}
