import { Page, StyleSheet } from '@react-pdf/renderer';
import AtlasAziList from './componants/AtlasAziList';
import AtlasList from './componants/AtlasList';
import ContaminatedAreas from './componants/ContaminatedAreas';
import GenericBloc from './componants/GenericBloc';
import PageFooter from './componants/PageFooter';
import PageHeader from './componants/PageHeader';
import SubTitleGenericBloc from './componants/SubTitleGenericBloc';
import { pdfStyles } from './pdfStyle';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
    position: 'relative',
    paddingBottom: '65px',
  },
});
interface IPageFiveProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };

  contaminatedAreas: ContaminatedAreas;
  environmentRisksDetails: EvironmentRisksDetails;
}
function PageFive(props: IPageFiveProps) {
  return (
    <>
      <Page size="A4" style={pdfStyles.page}>
        <PageHeader date={props.date} address={props.address} fixed />
        <GenericBloc title="Contraintes environnementales">
          {/* <SubTitleGenericBloc subtitle="> Risques recensés sur le territoire de la commune">
            <CityEnvRisk risks={props.risks} />
          </SubTitleGenericBloc> */}

          <SubTitleGenericBloc subtitle="> Liste des Atlas et Documents de gestion des risques existants sur la commune :">
            <AtlasList
              title="• Plan de Prévention des Risques Naturels (PPRN)"
              datas={props.environmentRisksDetails.pprn}
            />
            <AtlasList
              title="• Plan de prévention des risques environnementaux et technologiques ( PPRT)"
              datas={props.environmentRisksDetails.pprt}
            />
            <AtlasList
              title="• Plan de prévention des risques Miniers (PPRM)"
              datas={props.environmentRisksDetails.pprm}
              wrap
            />
            <AtlasAziList
              title="• Atlas des zones inondables (AZI)"
              datas={props.environmentRisksDetails.azi}
              wrap
            />
          </SubTitleGenericBloc>

          <SubTitleGenericBloc
            subtitle="> Sites et sols pollués – dans un périmètre de 500 m par rapport au bien étudiés"
            wrap
          >
            <ContaminatedAreas areas={props.contaminatedAreas} />
          </SubTitleGenericBloc>
        </GenericBloc>
        <PageFooter fixed />
      </Page>
    </>
  );
}

export default PageFive;
