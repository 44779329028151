import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import {
  dashboardReportingActions,
  getDashboardReportingState,
} from '../dashboardReportingSlice';
import { fetchReportingIndividualUserThunk } from '../dashboardReportingThunks';

import SelectCustom from '../../../components/Common/FormComponents/SelectCustom';
import { getAuthState } from '../../auth/authSlice';
import { getCompanyState } from '../../company/companySlice';
import useFolder from '../../folders/useFolder';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import UserPlotStudiesContainer from '../../plotStudies/userPlotStudies/dashboard/UserPlotStudiesContainer';
import useUsers from '../../users/useUsers';
import LineGraphBloc from './LineGraphBloc';
import PieGraphBloc from './PieGraphBloc';

function ReportingIndividual(): JSX.Element {
  const dispatch = useAppDispatch();
  const { companyId } = useAppSelector(getCompanyState);
  const { inputValueReportingIndividual, reportingIndividualUser } = useAppSelector(
    getDashboardReportingState
  );
  const { user } = useAppSelector(getAuthState);
  const { usersForSelect, users } = useUsers({});
  useFolder({});
  const [selectedUser, setselectedUser] = useState<string>(
    inputValueReportingIndividual
  );
  const [userSelected, setUserSelected] = useState<User>();

  const handleChange = (e: ChangeEventCustom) => {
    const userUpdated: string = e.target.value;
    dispatch(
      dashboardReportingActions.setInputValueReportingIndividual(userUpdated)
    );
    setselectedUser(userUpdated);
    setUserSelected(users?.find((u) => u.idIri === userUpdated));
    if (user && companyId) {
      dispatch(
        fetchReportingIndividualUserThunk({
          userIdIri: userUpdated,
          companyId: companyId,
        })
      );
    }
  };

  useEffect(() => {
    if (inputValueReportingIndividual) {
      setUserSelected(users?.find((u) => u.idIri === inputValueReportingIndividual));
    }
  }, [inputValueReportingIndividual]);

  // Loading datas
  if (reportingIndividualUser.apiStatus === APIStatus.PENDING) {
    return (
      <div className="reporting-loader">
        <IndividualCircularLoader size={75} />
        <p>Calcul des statistiques en cours</p>
      </div>
    );
  }

  return (
    <>
      <div className="reporting-table-action">
        <div className="reporting-table-action-filter">
          <div>
            Utilisateur:{' '}
            <SelectCustom
              name="utilisateur"
              noValue="choisir un utilisateur"
              items={usersForSelect()}
              value={selectedUser}
              onChange={handleChange}
              displayEmpty
            />
          </div>
        </div>
      </div>
      {userSelected && (
        <div className="reporting-individual">
          <h3>{"Statistiques de l'utilisateur pour les 12 derniers mois"}</h3>
          <PieGraphBloc plotStatuses={reportingIndividualUser.result.plotsStatus} />

          <LineGraphBloc
            performanceActivity={reportingIndividualUser.result.performanceActivity}
          />

          <div className="table-plots-individual">
            <h4>Opportunités:</h4>
            <div>
              <UserPlotStudiesContainer
                plots={reportingIndividualUser.result.plots}
                userFullName={userSelected.fullName}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportingIndividual;
