import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch } from '../../../App/store';
import Xion from '../../../assets/images/xicon.png';
import { IModalProps } from '../../modals/ModalContainer';
import { modalsActions } from '../../modals/modalsSlice';
import '../../../components/Modals/deleteModal.scss';
import { urbaGptDeleteThunk } from '../urbaGptThunk';

export default function DeleteUrabGptModal({ open }: IModalProps) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.urbaGptDelete(false));
  };

  const handleClick = () => {
    dispatch(urbaGptDeleteThunk());
    handleCloseModal();
  };

  return (
    <Dialog
      className="delete-modal"
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="delete-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="delete-modal-container">
          <div className="xicon-wrapper" onClick={handleCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <div className="delete-modal-container-title">
            Confirmation de la suppression de l’historique
          </div>
          <div className="delete-modal-container-content">
            <p>
              Cliquez sur le bouton pour confirmer, ou sur la croix pour annuler
              l’action.
            </p>
            <button type="button" className="warning-button" onClick={handleClick}>
              Supprimer
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
