import { nanoid } from '@reduxjs/toolkit';
import ProcedureCard from './ProcedureCard';

interface IProcedureListProps {
  onDelete: (idIri: string) => void;
  studyProcedureList: IStudyProcedure[];
  updateAllowed: boolean;
}

function StudyProcedureList({
  onDelete,
  studyProcedureList,
  updateAllowed,
}: IProcedureListProps): JSX.Element {
  return (
    <div className="procedure-list">
      {studyProcedureList.map((p) => (
        <ProcedureCard
          key={nanoid()}
          procedure={p}
          onDelete={onDelete}
          updateAllowed={updateAllowed}
        />
      ))}
    </div>
  );
}

export default StudyProcedureList;
