import Buildings from '../../../../assets/images/building.png';
import Document from '../../../../assets/images/document.png';
import Search from '../../../../assets/images/search.png';
import Signal from '../../../../assets/images/signal.png';

export const menuTabsLibelles = [
  { libelle: 'URBANISME', icon: Buildings },
  { libelle: `RECHERCHE & CALCUL`, icon: Search },
  { libelle: 'ENVIRONNEMENT', icon: Signal },
  { libelle: 'MA PROSPECTION', icon: Document },
];
