import { Image, View } from '@react-pdf/renderer';
import NoImage from '../../../../../assets/images/orpiPdf/imageNotAvailable.png';
import { pdfStyles } from '../pdfStyle';
import PlotDetailInfoBloc from './PlotDetailInfoBloc';
interface IPlotDetailsProps {
  parcelsOnCadastrePicture: string;
  plotIds: string[];
  area: number;
  freeGroundArea: number;
  builtArea: number;
  recalculatedArea: number;
  builtHeight: number;
}

function PlotDetails(props: IPlotDetailsProps) {
  const isMultiPlot = props.plotIds.length > 1;
  return (
    <View style={pdfStyles.plotDetails}>
      <>
        {props.parcelsOnCadastrePicture ? (
          <Image
            src={`data:image/jpeg;base64,${props.parcelsOnCadastrePicture}`}
            style={pdfStyles.plotInfosImage}
          />
        ) : (
          <Image src={NoImage} style={pdfStyles.plotInfosImage} />
        )}
      </>

      <View style={pdfStyles.plotDetailsInfos}>
        <PlotDetailInfoBloc
          data={props.plotIds}
          label={isMultiPlot ? ['N° de parcelles'] : ['N° de parcelle']}
          isMultiPlot={isMultiPlot}
        />

        <PlotDetailInfoBloc
          data={`${props.area} m²`}
          label={['Surface', 'au Cadastre']}
        />

        <PlotDetailInfoBloc
          data={`${props.freeGroundArea} m²`}
          label={['Surface libre', 'au sol estimée']}
        />

        <PlotDetailInfoBloc
          data={`${props.builtArea} m²`}
          label={['Surface de', "l'emprise bâti", 'au sol estimée']}
        />

        <PlotDetailInfoBloc
          data={`${props.recalculatedArea} m²`}
          label={['Surface', 'totale Parcelle', 'recalculée']}
        />

        <PlotDetailInfoBloc
          data={`${props.builtHeight} m`}
          label={['Hauteur', 'estimée', 'du bâtiment']}
        />
      </View>
    </View>
  );
}

export default PlotDetails;
