import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const statusList = [
  { id: 1, status: "A l'étude" },
  { id: 2, status: 'Validée' },
  { id: 3, status: 'Abandonnée' },
  { id: 4, status: 'Absant' },
  { id: 5, status: 'A contacter' },
];
const styles = StyleSheet.create({
  section: {
    width: '40%',
    fontSize: '10px',
  },
  viewLibelle: { marginBottom: '5px', textDecoration: 'underline' },
  viewValue: {
    marginBottom: '5px',
  },
});

interface IProspectionLeftBlocProps {
  status?: string;
  responsable?: string;
}
const ProspectionLeftBloc = ({ status, responsable }: IProspectionLeftBlocProps) => {
  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.viewLibelle}>Responsable de parcelle</Text>
        <Text style={styles.viewValue}>{responsable ?? 'Non défini'}</Text>
      </View>
      <View>
        <Text style={styles.viewLibelle}>Statut de la parcelle</Text>
        <Text style={styles.viewValue}>{status ?? 'Non défini'}</Text>
      </View>
    </View>
  );
};

export default ProspectionLeftBloc;
