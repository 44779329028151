import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';

const styles = StyleSheet.create({
  infoBloc: {
    width: '45%',
    height: '30%',
    border: '1px solid #eee',
    borderRadius: '5px',
    boxShadow: '10px 5px 5px red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataStyle: { fontSize: '16px', marginBottom: '10px' },
  uniteStyle: { fontSize: '14px', fontWeight: 400 },
  textStyle: { fontSize: '9px', color: '#5C5C5D' },
});

interface IDataInfoBlocProps {
  text1: string;
  text2: string;
  data: number | string;
  unite?: string;
}
const DataInfoBloc = ({
  data,
  text1 = '',
  text2 = '',
  unite = '',
}: IDataInfoBlocProps) => {
  return text2 ? (
    <View style={styles.infoBloc}>
      <Text style={styles.dataStyle}>
        {data}
        <Text style={styles.uniteStyle}>{` ${
          data || data === 0 ? unite : ' - '
        }`}</Text>
      </Text>
      <Text style={styles.textStyle}>{text1}</Text>
      <Text style={styles.textStyle}>{text2}</Text>
    </View>
  ) : (
    <View style={styles.infoBloc}>
      <Text style={styles.dataStyle}>
        {data}
        <Text style={styles.uniteStyle}>{` ${isEmpty(data) ? unite : ' - '}`}</Text>
      </Text>
      <Text style={styles.textStyle}>{text1}</Text>
    </View>
  );
};

export default DataInfoBloc;
