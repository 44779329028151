import { TableCell, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../App/store';
import PlotStateBadge from '../../../../components/Common/StatusElement/PlotStateBadge';
import plotActions from '../../../../redux/plot/actions';
import { spacedNumbers } from '../../../../utils/jsFunctions';
import { setStudyParamsThunk } from '../../../study/services/thunks/studyParamsThunks';

interface IUserPlotProps {
  plotStudy: IPlotStudy;
  folderName: string;
}
function UserPlotStudiesTableRow({ plotStudy, folderName }: IUserPlotProps) {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (plotStudy.lng && plotStudy.lat && plotStudy.postalCode) {
      const studyParams: StudyParamsState = {
        type: 'plotStudy',
        idIri: plotStudy.idIri,
        noCheckExisting: true,
        managmentLoader: true,
      };

      dispatch(setStudyParamsThunk(studyParams));
      // load plot data for map and others panel tabs
      dispatch(
        plotActions.fetchPlot.request({
          data: {
            lon: plotStudy.lng,
            lat: plotStudy.lat,
            department: plotStudy.postalCode.substring(0, 2),
            pdf: false,
          },
          studyParams,
        })
      );
    }
  };

  return (
    <TableRow key={nanoid()} className="row">
      <TableCell>{plotStudy.city ?? '-'}</TableCell>
      <TableCell className="clickable" onClick={() => handleClick()}>
        {plotStudy.plotId ?? '-'}
      </TableCell>
      <TableCell>{folderName}</TableCell>
      <TableCell>{plotStudy.zone}</TableCell>
      <TableCell>
        {plotStudy.area ? spacedNumbers(plotStudy.area) + ' m²' : '-'}
      </TableCell>
      <TableCell>
        <PlotStateBadge status={plotStudy.status} />
      </TableCell>
    </TableRow>
  );
}

export default UserPlotStudiesTableRow;
