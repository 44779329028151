import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import CommonBlocTitle from './CommonBlocTitle';
import UrbanismeInfoBlocContainer from './UrbanismeInfoBlocContainer';

const styles = StyleSheet.create({
  section: {
    marginBottom: '5px',
  },
  text: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    fontWeight: 500,
    fontSize: '10px',
    marginBottom: '5px',
  },
  image: { width: '445px', height: '215px', margin: 'auto', marginBottom: '5px' },
  textRed: {
    fontSize: '11px',
    color: '#f00',
  },
});

interface IURbanismeProps {
  image: string;
  zone: string;
  seismic: IEnvPDFDisplay;
  clay: IEnvPDFDisplay;
  peb: IEnvPDFDisplay;
  isDomTom: boolean;
}

const UrbanismeInfo = ({
  image,
  peb,
  zone,
  seismic,
  clay,
  isDomTom,
}: IURbanismeProps) => {
  const PEB = 'NC';

  return (
    <View style={styles.section}>
      <CommonBlocTitle text="Informations d'urbanisme" />

      {zone === 'Information manquante' ? (
        <Text style={styles.text}>
          Précision du plan de zonage : la parcelle se trouve dans une commune non
          couverte par un PLU ou un PLUI
        </Text>
      ) : (
        <Text style={styles.text}>
          Précision du plan de zonage : la parcelle se trouve en zone
          <Text style={styles.textRed}>{` ${zone} `}</Text>du PLU
        </Text>
      )}

      <Image
        src={`data:image/${isDomTom ? 'png' : 'jpeg'};base64,${image}`}
        style={styles.image}
      />

      <UrbanismeInfoBlocContainer seismic={seismic} clay={clay} peb={peb} />
    </View>
  );
};
UrbanismeInfo.propTypes = {
  image: PropTypes.any,
  data: PropTypes.any,
};
export default UrbanismeInfo;
