import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../../features/auth/authSlice';
import { getMapState } from '../../../../features/map/mapSlice';
import PluOtherContainer from '../../../../features/plu/PluOther/PluOtherContainer';
import PluLegend from '../../../../features/plu/pluLegend';
import { getPluState } from '../../../../features/plu/pluSlice';
import { fetchPluOthersThunk } from '../../../../features/plu/pluThunk';

import DeniedAccess from '../../../noRights/DeniedAccess';
import NoCity from '../../../noRights/NoCity';
import '../../styles/urbaEnvContent.scss';
import ArrowTab from '../ArrowTab/ArrowTab';

export enum PLUOtherTypeEnum {
  CONTRAINTES = 'Contraintes urbaines & archi',
  AMENAGEMENT = "Zone d'aménagement",
  DIVERS = 'Divers',
}

const Urbanisme = (): JSX.Element => {
  const [selected, setSelected] = useState<number>(1);
  const { deniedZone } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { pluOthers, activeInsee } = useAppSelector(getPluState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      geolocDatas?.city &&
      geolocDatas?.postalCode &&
      geolocDatas?.inseeCode &&
      geolocDatas?.inseeCode !== activeInsee &&
      !deniedZone
    ) {
      dispatch(
        fetchPluOthersThunk({
          city: geolocDatas.city,
          postalCode: geolocDatas.postalCode,
        })
      );
    }
  }, [geolocDatas?.city, geolocDatas?.postalCode]);

  const handleChangeSelectedTab = (id: number): void => {
    setSelected(id);
  };

  const displayTab = (libelle: string) => {
    return Boolean(
      pluOthers?.find((plu) => {
        return plu.masterCat === libelle;
      })
    );
  };

  if (deniedZone) {
    return <DeniedAccess />;
  }

  if (!geolocDatas?.city) {
    return <NoCity />;
  }

  return (
    <div className="urba-env-content">
      <div className="tabs-wrapper">
        <ArrowTab
          label1="PLU"
          label2="Zonage"
          onClick={() => handleChangeSelectedTab(1)}
          selected={selected === 1}
        />

        {pluOthers && displayTab(PLUOtherTypeEnum.CONTRAINTES) && (
          <ArrowTab
            label1="Contraintes"
            label2={`urbaines & archi`}
            onClick={() => handleChangeSelectedTab(2)}
            selected={selected === 2}
          />
        )}

        {pluOthers && displayTab(PLUOtherTypeEnum.AMENAGEMENT) && (
          <ArrowTab
            label1={`Zone d'aménagement`}
            onClick={() => handleChangeSelectedTab(3)}
            selected={selected === 3}
          />
        )}

        {pluOthers && displayTab(PLUOtherTypeEnum.DIVERS) && (
          <ArrowTab
            label1={`Divers`}
            onClick={() => handleChangeSelectedTab(4)}
            selected={selected === 4}
          />
        )}
      </div>

      <div className="content-wrapper">
        {selected === 1 && <PluLegend />}
        {selected === 2 && <PluOtherContainer type={PLUOtherTypeEnum.CONTRAINTES} />}
        {selected === 3 && <PluOtherContainer type={PLUOtherTypeEnum.AMENAGEMENT} />}
        {selected === 4 && <PluOtherContainer type={PLUOtherTypeEnum.DIVERS} />}
      </div>
    </div>
  );
};

export default memo(Urbanisme);
