import { useAppSelector } from '../../App/store';
import LoginModal from '../auth/login/LoginModal';
import SignUpModal from '../auth/signup/SignUpModal';
import UrbaneaseIntroModal from '../auth/UrbaneaseIntroModal';
import { getModalsState } from './modalsSlice';

function AuthModalContainer() {
  const { signup, signin, urbaneaseIntro } = useAppSelector(getModalsState);
  return (
    <>
      {signup && <SignUpModal open={signup} />}
      {signin && <LoginModal open={signin} />}
      {urbaneaseIntro && <UrbaneaseIntroModal />}
    </>
  );
}

export default AuthModalContainer;
