import { memo } from 'react';
import { useMap } from 'react-leaflet';

const ZoomButtonsMap: React.FC = () => {
  const map = useMap();

  // Create a custom zoom-in button
  const zoomInButton = document.getElementById('zoom-in-button');
  const zoomOutButton = document.getElementById('zoom-out-button');

  // Function to handle the button click event
  zoomInButton?.addEventListener('click', function () {
    map.zoomIn();
  });
  zoomOutButton?.addEventListener('click', function () {
    map.zoomOut();
  });
  return null;
};

export default memo(ZoomButtonsMap);
