import * as Yup from 'yup';

export const validationSchemaPdfForm = Yup.object({
  description: Yup.string().max(
    440,
    (value) => `Le commentaire ne doit pas dépasser ${value.max} caractères`
  ),
  addressCity: Yup.string(),
  addressStreet: Yup.string(),
  propertyType: Yup.string(),
  livingArea: Yup.number(),
  landArea: Yup.number(),
  numberOfRooms: Yup.number(),
  floorNumber: Yup.number(),
  IdentityOwnersOnDocument: Yup.string(),
  lastName: Yup.string(),
  firstName: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  companyName: Yup.string(),
});
