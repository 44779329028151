import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';

interface ISortOrder {
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  initOrder?: Order;
}

export default function SortOrder({ setOrder, initOrder }: ISortOrder) {
  const [selected, setSelected] = useState<Order>(initOrder ?? 'asc');

  const handleSortChange = (value: Order): void => {
    setSelected(value);
    setOrder(value);
  };
  return (
    <div className="sort-buttons">
      <ExpandMoreIcon
        className={`icon desc ${selected === 'desc' ? 'selected' : ''}`}
        onClick={() => handleSortChange('desc')}
      />
      <ExpandMoreIcon
        className={`icon asc ${selected === 'asc' ? 'selected' : ''}`}
        onClick={() => handleSortChange('asc')}
      />
    </div>
  );
}
