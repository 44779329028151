import { delay, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import LocalStorageService from '../../../services/LocalStorageService';
import { getAnnouncementState } from '../../announcement/announcementSlice';
import { fetchAnnouncementThunk } from '../../announcement/announcementThunks';
import {
  ConnectionWorkFlowEnum,
  CONNEXION_PROCESS_DELAY,
} from './ConnectionProcessContent';

enum ProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  END_PROCESS,
}

interface IAnnouncementProcessProps {
  decodedToken: DecodedToken | null;
  updateProcessStep: (value: ConnectionWorkFlowEnum) => void;
}

function AnnouncementProcess({
  decodedToken,
  updateProcessStep,
}: IAnnouncementProcessProps) {
  const { announcement } = useAppSelector(getAnnouncementState);
  const [internalStep, setInternalStep] = useState<ProcessStepEnum>(
    ProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (announcement) {
      setInternalStep(ProcessStepEnum.DATAS_FULFILLED);
    }
  }, [announcement]);

  useEffect(() => {
    switch (internalStep) {
      case ProcessStepEnum.IDLE: {
        setInternalStep(ProcessStepEnum.PROCESS_INIT);
        break;
      }
      case ProcessStepEnum.PROCESS_INIT: {
        delay(() => {
          dispatch(
            fetchAnnouncementThunk({
              lastAuthAt: isEmpty(LocalStorageService.getLastLoginDate())
                ? (decodedToken?.lastAuthAt as string)
                : LocalStorageService.getLastLoginDate(),
            })
          );
        }, CONNEXION_PROCESS_DELAY);
        setInternalStep(ProcessStepEnum.DATAS_PENDING);
        break;
      }
      case ProcessStepEnum.DATAS_PENDING:
        break;
      case ProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(ProcessStepEnum.END_PROCESS);
        break;
      case ProcessStepEnum.END_PROCESS:
        updateProcessStep(ConnectionWorkFlowEnum.START_COMPANY_PROCESS);
        break;
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div>
      {internalStep === ProcessStepEnum.DATAS_PENDING && (
        <p>Chargement des annonces ...</p>
      )}

      {internalStep === ProcessStepEnum.DATAS_FULFILLED && <p>Annonces chargée</p>}
    </div>
  );
}

export default AnnouncementProcess;
