import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';

const styles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 300,
  },
  areaCount: {
    fontSize: '14px',
    fontWeight: 400,
  },
  areaContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    fontWeight: 300,
    fontSize: '10px',
    marginTop: '10px',
  },
  areaRow: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
  },
  areaName: { fontWeight: 400, color: '#f00' },
  areaDescription: { flexGrow: 1 },
});
interface IContaminatedAreasProps {
  areas: ContaminatedAreas;
}
function ContaminatedAreas({ areas }: IContaminatedAreasProps) {
  return (
    <View>
      <View style={styles.areaContainer}>
        {areas.map((m) => {
          return (
            <View key={nanoid()} style={styles.areaRow}>
              <Text style={styles.areaDescription}>
                <Text style={styles.areaName}>{`${m.count} `}</Text>
                {/* first letter to lower case */}
                {m.libelle?.charAt(0).toLowerCase() + m.libelle.slice(1)}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default ContaminatedAreas;
