import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyle';

interface IGenericeBlocProps extends IChildren {
  title: string;
  wrap?: boolean;
}

function GenericBloc({ title, children, wrap }: IGenericeBlocProps) {
  return (
    <View style={pdfStyles.genericBloc} wrap={!wrap}>
      <Text style={pdfStyles.genericBlocTitle}>{title}</Text>
      <View>{children}</View>
    </View>
  );
}

export default GenericBloc;
