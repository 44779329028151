import * as Yup from 'yup';

export const initialForm: Partial<IMember> = {
  password: '',
  verifyPassword: '',
};

export const validationSchema = Yup.object({
  password: Yup.string().required('Mot de passe requis'),
  verifyPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Le mot de passe doit correspondre au nouveau mot de passe'
    )
    .required('Mot de passe requis'),
});
