import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  section: {
    color: '#4E55A2',
    textAlign: 'center',
    fontSize: '8px',
    position: 'absolute',
    bottom: '10px',
    justifyContent: 'center',
  },
  legalText: {
    color: '#BABABA',
    padding: '0px 30px',
  },
  contactText: {
    textAlign: 'center',
    margin: 'auto',
    padding: '0px 30px',
    paddingTop: '5px',
  },
});

const Footer = (): JSX.Element => {
  return (
    <View style={styles.section}>
      <Text style={styles.legalText}>
        cette réponse non contractuelle est délivrée par Urbanease. Elle fait état
        des renseignements connus à ce jour. Elle constitue un simple document
        d’information et ne peut en aucun cas être considérée comme une autorisation
        administrative quelconque ni un certificat d’urbanisme. Par ailleurs elle ne
        saurait engager la responsabilité d’Urbanease dans le cas de l’application de
        l’article L-125-5 du Code de l’Environnement (Risques Majeurs).
      </Text>
      <Text style={styles.contactText}>
        contact : <Link src="mailto:contact@urbanease.fr" /> - SAS URBANEASE – 848
        429 593 RCS BAYONNE
      </Text>
    </View>
  );
};

export default Footer;
