import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch } from '../../../../App/store';
import UnLockIcon from '../../../../assets/images/lockopen.png';
import Xion from '../../../../assets/images/xicon.png';
import { modalsActions } from '../../../modals/modalsSlice';
import LoginContent from './LoginContent';
import './LoginModal.scss';

interface ILoginModalProps {
  open: boolean;
}

const LoginModal = ({ open }: ILoginModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(modalsActions.signin(false));
  };

  const handleOpenSignupModal = () => {
    handleClose();
    dispatch(modalsActions.signup(true));
  };

  return (
    <Dialog
      className="login-modal"
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="sign-in-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="sign-in-modal-container">
          <div className="xicon-wrapper" onClick={handleClose}>
            <img src={Xion} alt="xion" />
          </div>

          <div className="title-pin">
            <img src={UnLockIcon} alt="img" />
          </div>

          <LoginContent />

          <div className="footer-wrapper">
            <div className="left-text">Vous êtes nouveau sur URBANEASE ?</div>
            <div className="right-text" onClick={handleOpenSignupModal}>
              Inscrivez-vous
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
