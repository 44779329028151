import { useEffect, useState } from 'react';
import { getAppState } from '../../../../../App/appSlice';
import createCtx from '../../../../../App/contexts/GenenricStateContext';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import AddFormDisplay from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/AddFormDisplay';
import { getAuthState } from '../../../../auth/authSlice';
import { getCompanyState } from '../../../../company/companySlice';
import { fetchProceduresThunk } from '../../../../procedures/proceduresThunk';
import { getUsersState } from '../../../../users/usersSlice';
import deleteStudyProcedure from '../../../services/deleteStudyProcedure';
import { fetchStudyProcedures } from '../../../services/fetchStudyProcedures';
import StudyProcedureForm from './StudyProcedureForm';
import StudyProcedureList from './StudyProcedureList';
import './procedure.scss';

interface IProcedureContentProps {
  studyIdIri: string | null;
  updateAllowed: boolean;
}

const [ctx, OpenFormProvider] = createCtx(false);
export const ForceOpenContext = ctx;

export default function ProcedureContent({
  studyIdIri,
  updateAllowed,
}: IProcedureContentProps): JSX.Element {
  const { companyId } = useAppSelector(getCompanyState);
  const { users } = useAppSelector(getUsersState);
  const { userIdIri } = useAppSelector(getAuthState);
  const { contactRoles, procedureTypes } = useAppSelector(getAppState);
  const [
    studyProcedureList,
    setStudyProcedureList,
  ] = useState<StudyProcedures | null>(null);
  const dispatch = useAppDispatch();
  const handleDelete = async (idIri: string) => {
    try {
      const deleted = await deleteStudyProcedure(idIri);
      setStudyProcedureList(
        studyProcedureList?.filter((f) => f.idIri !== deleted) ?? []
      );
      dispatch(fetchProceduresThunk({ userIdIri, companyId }));
    } catch (error) {
      console.log('peux pas supprimer studyProcedure');
    }
  };

  const handleCreate = async (sp: IStudyProcedure) => {
    setStudyProcedureList(studyProcedureList ? studyProcedureList.concat(sp) : [sp]);
    dispatch(fetchProceduresThunk({ userIdIri, companyId }));
  };

  const getStudyProcedures = async () => {
    const ps = await fetchStudyProcedures(
      studyIdIri,
      companyId,
      users,
      contactRoles.result,
      procedureTypes.result
    );
    setStudyProcedureList(ps);
  };

  useEffect(() => {
    if (studyIdIri) {
      getStudyProcedures();
    } else {
      setStudyProcedureList(null);
    }
  }, [studyIdIri]);

  return (
    <div className="procedure-content">
      <OpenFormProvider>
        <StudyProcedureList
          studyProcedureList={studyProcedureList ?? []}
          onDelete={handleDelete}
          updateAllowed={updateAllowed}
        />
        {updateAllowed && (
          <AddFormDisplay
            labelOpen="Ajouter une démarche"
            labelClose="Masquer le formulaire"
            ForceOpenContext={ForceOpenContext}
          >
            <StudyProcedureForm onCreate={handleCreate} />
          </AddFormDisplay>
        )}
      </OpenFormProvider>
    </div>
  );
}
