import { ChangeEvent, useState } from 'react';

import { TextField } from '@mui/material';
import styles from './deleteConfirmation.module.scss';
import { makeStyles } from 'tss-react/mui';

interface IDeleteFolderConfirmationProps {
  onConfirmationValid: () => void;
  wordToWrite: string | null;
}

const useStyles = makeStyles()({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '#e28f12 1px solid',
      },
      '&:hover fieldset': {
        border: '#e28f12 1px solid',
      },
      '&.Mui-focused fieldset': {
        border: '#e28f12 1px solid',
      },
    },
  },
});

function DeleteConfirmation({
  onConfirmationValid,
  wordToWrite,
}: IDeleteFolderConfirmationProps) {
  const { classes } = useStyles();
  const [fieldValue, setFieldValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value ?? '';
    setFieldValue(v);

    if (v === wordToWrite ?? 'SUPPRIMER') {
      setIsValid(true);
    } else {
      if (isValid) {
        setIsValid(false);
      }
    }
  };

  const handleClick = () => {
    onConfirmationValid();
  };
  return (
    <div className={styles.deleteConfirmation}>
      <p>
        Veuillez taper le(s) mot(s) <span>{wordToWrite}</span> afin de confirmer
        <br />
        la suppression
      </p>
      <div className={styles.input}>
        <TextField
          inputRef={(input) => input && input.focus()}
          name="field"
          variant="outlined"
          onChange={handleChange}
          value={fieldValue}
          classes={classes}
        />
      </div>
      <button
        onClick={handleClick}
        disabled={!isValid}
        className={`${styles.button} ${isValid ? '' : styles.disabled}`}
      >
        Supprimer
      </button>
    </div>
  );
}

export default DeleteConfirmation;
