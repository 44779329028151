import { memo } from 'react';
import { useAppDispatch } from '../../../../../../../App/store';
import useFolder from '../../../../../../../features/folders/useFolder';
import { createPlotStudyThunk } from '../../../../../../../features/study/services/thunks/studyThunks';
import SelectCustom from '../../../../../../Common/FormComponents/SelectCustom';

interface IFolderSelectProps {
  isPlotStudy: boolean;
  parent: IFolder | null;
  onUpdate: (params: UpdateStudyManagmentProps) => void;
  disabled?: boolean;
}

const FolderSelect = ({
  parent,
  isPlotStudy,
  onUpdate,
  disabled,
}: IFolderSelectProps) => {
  const { foldersForSelect, getFolderByIdIriFromList } = useFolder({ noLoad: true });
  const dispatch = useAppDispatch();

  const handleCreate = (folder: IFolder): void => {
    dispatch(
      createPlotStudyThunk({
        folder,
      })
    );
  };

  const handleFolderChange = (e: ChangeEventCustom): void => {
    const selectedFolderIdIri = e.target.value as string;
    const p = getFolderByIdIriFromList(selectedFolderIdIri);

    if (parent) {
      onUpdate({ parent: p });
    } else {
      handleCreate(p);
    }
  };

  return (
    <SelectCustom
      items={foldersForSelect}
      name="folders"
      onChange={handleFolderChange}
      value={parent?.idIri ?? ''}
      noValue="Choisir un dossier"
      disabled={!isPlotStudy || disabled}
      displayEmpty
      isFolders
    />
  );
};

export default memo(FolderSelect);
