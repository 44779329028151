import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import styles from '../advancedPlotSearchContainer.module.scss';
import InputGroupElement from './InputGroupElement';

interface IPlotCalculationInputGroupProps {
  control: any;
  trigger: UseFormTrigger<AdvancedPlotSearchForm>;
  setValue: UseFormSetValue<AdvancedPlotSearchForm>;
  min: keyof AdvancedPlotSearchForm;
  max: keyof AdvancedPlotSearchForm;
  placeHolder?: string;
  // errorName: keyof Pick<AdvancedPlotSearchForm, 'maxPlot' | 'maxGround'>;
}
function PlotCalculationInputGroup({
  control,
  trigger,
  min,
  max,
  placeHolder,
  setValue,
}: IPlotCalculationInputGroupProps): React.ReactElement {
  const maxErrorName = max as keyof Pick<
    AdvancedPlotSearchForm,
    'maxPlot' | 'maxGround' | 'maxBuiltPercent'
  >;
  const minErrorName = min as keyof Pick<
    AdvancedPlotSearchForm,
    'minPlot' | 'minGround' | 'minBuiltPercent'
  >;

  let label: string[] = ['', ''];
  switch (min) {
    case 'minBuiltPercent':
      label = ['Emprise bâtie mini (%)', 'Emprise bâtie maxi (%)'];
      break;
    case 'minPlot':
      label = ['Surface parcelle mini', 'Surface parcelle maxi'];
      break;
    case 'minGround':
      label = ['Surface libre au sol mini', 'Surface libre au sol maxi'];
      break;
    default:
      break;
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.field}>
        <div className={styles.label}>{label[0]}</div>
        <InputGroupElement
          setValue={setValue}
          name={min}
          trigger={trigger}
          control={control}
          errorName={minErrorName}
          placeHolder={placeHolder}
          keysToTrigger={[minErrorName, maxErrorName]}
        />
      </div>

      <div className={styles.field}>
        <div className={styles.label}>{label[1]}</div>
        <InputGroupElement
          setValue={setValue}
          name={max}
          trigger={trigger}
          control={control}
          errorName={maxErrorName}
          placeHolder={placeHolder}
          keysToTrigger={[minErrorName, maxErrorName]}
        />
      </div>
    </div>
  );
}

export default PlotCalculationInputGroup;
