import * as Yup from 'yup';
import { validationMessages } from '../../../constants/validations';

export const initialForm: {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
} = {
  lastName: '',
  firstName: '',
  email: '',
  password: '',
};

const maxLength = 16;
const maxLengthMessage = 'Doit comporter au maximum 16 caractères';

export const validationAddUserSchema = Yup.object({
  lastName: Yup.string()
    .required(validationMessages.lastName.requis)
    .max(maxLength, maxLengthMessage),
  firstName: Yup.string()
    .required(validationMessages.firstName.requis)
    .max(maxLength, maxLengthMessage),
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
  password: Yup.string(),
});

export const validationEditUserSchema = Yup.object({
  lastName: Yup.string()
    .required(validationMessages.lastName.requis)
    .max(maxLength, maxLengthMessage),
  firstName: Yup.string()
    .required(validationMessages.firstName.requis)
    .max(maxLength, maxLengthMessage),
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
});
