import { makeStyles } from 'tss-react/mui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { frFR, PickersLocaleText } from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';

interface IDatePickerBaseProps {
  minDate: string;
  onChange: (value: Date | null, type: keyof DateFilterType) => void;
  date: Date | null;
  type: keyof DateFilterType;
  hasDay?: boolean;
}

const useStyles = makeStyles()({
  root: {
    margin: '5px',
  },
});

const customFrFRLocaleText: Partial<PickersLocaleText<Date>> = {
  ...frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  okButtonLabel: 'OK',
  cancelButtonLabel: 'Annuler',
  clearButtonLabel: 'Effacer',
};

function DatePickerBase({
  date,
  onChange,
  minDate,
  type,
  hasDay,
}: IDatePickerBaseProps) {
  const { classes } = useStyles();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={frLocale}
      localeText={customFrFRLocaleText}
    >
      <MobileDatePicker
        className={classes.root}
        value={date}
        views={hasDay ? ['month', 'year', 'day'] : ['month', 'year']}
        minDate={new Date(minDate)}
        maxDate={new Date()}
        onChange={(e: Date | null) => onChange(e, type)}
        format="MMMM yyyy"
        slotProps={{
          toolbar: {
            toolbarFormat: 'MMMM yyyy',
            hidden: false,
            toolbarPlaceholder: 'date',
          },
          actionBar: { actions: ['accept', 'clear', 'cancel'] },
          textField: {
            placeholder: 'date',
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerBase;
