import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { EnvIconTypeEnum, GradualColors } from '../Page4';
import EnvironmentGradualScore from './EnvironmentGradualScore';
import ClayIconSvg from './svg/ClayIconSvg';
import ErosionIconSvg from './svg/ErosionIconSvg';
import RadonIconSvg from './svg/RadonIconSvg';
import SeismicIconSvg from './svg/SeismicIconSvg';

interface IEnvironmentalConstraintProps {
  type: EnvIconTypeEnum;
  title: string;
  value?: number;
  max?: number;
  yesNo?: boolean;
  description: string;
}

function EnvironmentalConstraint(props: IEnvironmentalConstraintProps) {
  const titleColor = props.value
    ? GradualColors[props.type][props.value - 1]?.color ?? '#fff'
    : '#fff';

  const styles = StyleSheet.create({
    environmentalConstraint: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '15%',
    },
    logo: {
      width: '62px',
      height: '62px',
    },
    infoContainer: {
      borderLeft: '1px solid #679CC6',
      padding: '10px 0 10px 15px',
      height: '100%',
      flexGrow: 1,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: { fontSize: '14px' },
    valueText: {},
    description: { fontSize: '12px', fontWeight: 300 },
  });

  return (
    <View style={styles.environmentalConstraint}>
      <View style={styles.logoContainer}>
        {props.type === EnvIconTypeEnum.SEISMIC && <SeismicIconSvg />}
        {props.type === EnvIconTypeEnum.CLAY && <ClayIconSvg />}
        {props.type === EnvIconTypeEnum.RADON && <RadonIconSvg />}
        {props.type === EnvIconTypeEnum.EROSION && <ErosionIconSvg />}
      </View>
      <View style={styles.infoContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{`${props.title} `} </Text>
          {props.value && (
            <Text style={styles.valueText}>
              {props.value}/{props.max}
            </Text>
          )}
        </View>

        {props.value ? (
          <>
            <Text style={styles.description}>{props.description}</Text>
            <EnvironmentGradualScore
              type={props.type}
              value={props.value}
              max={props.max}
            />
          </>
        ) : (
          <Text>Absence de contraintes connues</Text>
        )}
      </View>
    </View>
  );
}

export default EnvironmentalConstraint;
