import { useAppSelector } from '../../App/store';
import { getPanelState } from '../../redux/Panels/reducer';
import DashboarButton from '../Dashboard/dashboardButton/DashboardButton';
import RightBottomButtons from './RightBottomButtons';

export default function ButtonsOnMap(): JSX.Element {
  const { rightButtons } = useAppSelector(getPanelState);
  return (
    <>
      {rightButtons && (
        <>
          <DashboarButton />
        </>
      )}
      <RightBottomButtons />
    </>
  );
}
