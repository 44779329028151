import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import TextFieldFormCustom from '../../../../components/Common/FormComponents/TextFieldFormCustom';
import { authActions, getAuthState } from '../../authSlice';

import { classicLoginThunk } from '../../authThunks';

import { initialLoginForm, validationSchemaLoginForm } from './formValidation';

export default function LoginContent() {
  const { error } = useAppSelector(getAuthState);
  const [isPwdVisible, setIsPwdVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginParams>({
    defaultValues: initialLoginForm,
    resolver: yupResolver(validationSchemaLoginForm),
  });

  const submitForm = (data: ILoginParams): void => {
    dispatch(classicLoginThunk(data));
  };

  const handleInputChange = () => {
    if (error) {
      dispatch(authActions.resetError());
    }
  };

  const handleClickShowPassword = () => {
    setIsPwdVisible(!isPwdVisible);
  };

  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(submitForm)}
      autoComplete="off"
    >
      <TextFieldFormCustom
        type="email"
        control={control}
        name="email"
        placeholder="Email"
        required
        error={Boolean(errors.email)}
        errorMsg={errors.email?.message as string}
        onChange={handleInputChange}
      />
      <TextFieldFormCustom
        type={isPwdVisible ? 'text' : 'password'}
        control={control}
        name="password"
        placeholder="Mot de passe"
        required
        onChange={handleInputChange}
        error={Boolean(errors.password)}
        errorMsg={errors.password?.message as string}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                size="large">
                {isPwdVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {error && <p className="error">{error.message}</p>}

      <FormControlLabel
        className="remember-me"
        control={<Checkbox defaultChecked color="default" />}
        label="Se souvenir de moi"
      />
      <button type="submit" className="submit-btn">
        SE CONNECTER
      </button>
    </form>
  );
}
