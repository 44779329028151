import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../App/store';
import { ReactComponent as TickSquare } from '../../../assets/images/tick-square.svg';
import { getAutocompleteState } from '../../autocomplete/autocompleteSlice';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../externalPdfErrialSlice';
import SynthesisMultiPlots from './synthesisMultiPlots/SynthesisMultiPlots';

function LeftPanelErrialBloc() {
  const { isMultiPlotSelectorPdf } = useAppSelector(getExternalPdfErrialState);
  const { inputValue } = useAppSelector(getAutocompleteState);
  const dispatch = useDispatch();
  const onClickParcelleSelector = () => {
    dispatch(
      externalPdfErrialActions.setIsMultiPlotSelectorPdf(!isMultiPlotSelectorPdf)
    );
  };

  return (
    <>
      <div className="left-panel-multi-plots">
        <div className={`${inputValue ? '' : 'left-panel-multi-plots-empty'}`}>
          <TickSquare />
          <p>
            Double-cliquez sur la ou les parcelles concernées pour pouvoir générer
            votre PDF d&apos;analyse
          </p>
        </div>
        <button onClick={onClickParcelleSelector}>
          {isMultiPlotSelectorPdf
            ? 'Annuler la multi-selection de parcelles'
            : 'Multi-selection de parcelles'}
        </button>
      </div>
      <SynthesisMultiPlots />
    </>
  );
}

export default LeftPanelErrialBloc;
