import { Route, Switch } from 'react-router-dom';
import MobileAppDownload from '../components/MobileAppDownload';
import Layout from '../layout';
import ExternalConnection from '../pages/ExternalConnection';
import PasswordResetPage from '../pages/PasswordResetPage';
import RouteContainer from './RouteContainer';

const App = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    window.location = 'urbanease://' as any;
  }

  return (
    <div className="app">
      <Layout>
        <MobileAppDownload />

        <Switch>
          <Route
            path="/external_connection"
            exact={true}
            component={ExternalConnection}
          />
          <Route path="/reset_password" exact={true} component={PasswordResetPage} />
          <Route
            path="/init_first_password"
            exact={true}
            component={PasswordResetPage}
          />
          <RouteContainer />
        </Switch>
      </Layout>
    </div>
  );
};

export default App;
