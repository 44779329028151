import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../assets/images/logo.png';

const int2 = new Intl.DateTimeFormat('fr-FR', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  imageBloc: {
    width: '30%',
  },
  image: {
    width: '100%',
  },
  rightBloc: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '10px',
  },
  editDate: {
    fontSize: '8px',
  },
});

const Header = (): JSX.Element => {
  return (
    <View style={styles.section}>
      <View style={styles.imageBloc}>
        <Image src={logo} style={styles.image} />
      </View>
      <View style={styles.rightBloc}>
        <Text style={styles.title}>Fiche synthétique générée par Urbanease</Text>
        <Text style={styles.editDate}>{`Edité le ${int2.format(new Date())}`}</Text>
      </View>
    </View>
  );
};

export default Header;
