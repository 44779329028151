export default function DemandFailure() {
  return (
    <>
      <p>
        Votre demande n’a pas pu aboutir à cause d’un problème technique ou d’une
        information mal complétée.
        <br /> Vous pouvez nous envoyer un mail de contact directement via le lien
        ci-dessous.
        <br />
        <br />
        En vous remerciant pour votre intérêt
      </p>
      <a
        className="connecter-btn link-btn"
        target="__blank"
        rel="noopener noreferrer"
        href="mailto:support@urbanease.io"
      >
        Nous contacter par mail
      </a>
    </>
  );
}
