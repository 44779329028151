import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getPlotState } from '../../../../redux/plot/reducer';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../externalPdfErrialSlice';
import styles from './synthesisMultiPlots.module.scss';

function SynthesisMultiPlots() {
  const dispatch = useAppDispatch();
  const { parcelle } = useAppSelector(getPlotState);
  const { multiPlotsPdf, plotCountPdf, isMultiPlotSelectorPdf } = useAppSelector(
    getExternalPdfErrialState
  );

  const onClickDeletePlot = (index: number) => {
    dispatch(externalPdfErrialActions.removeMultiPlotsPdfFromSelection(index));
  };

  return (
    <>
      {(isMultiPlotSelectorPdf || parcelle) && (
        <>
          <div className={styles.synthesisMultiPlots}>
            {isMultiPlotSelectorPdf ? (
              <>
                <p>
                  Vous avez séléctionné les parcelles suivantes, limite de 5
                  parcelles :
                </p>
                <p className={styles.warning}>
                  Veuillez sélectionner des parcelles adjacentes uniquement pour un
                  meilleur rendu
                </p>
                <div className={styles.synthesisMultiPlotsContent}>
                  {multiPlotsPdf && !isEmpty(multiPlotsPdf.result) && (
                    <ul>
                      {multiPlotsPdf.result?.map((elt, i) => (
                        <li key={nanoid()}>
                          <span className={styles.number}>{i + 1}</span>
                          <p
                            className={`${
                              multiPlotsPdf?.result?.length === 1
                                ? styles.oneItem
                                : ''
                            }`}
                          >
                            {elt.plotId} - {elt.theoricCapacity} m²
                          </p>
                          <span
                            onClick={() => onClickDeletePlot(elt.index)}
                            className={styles.close}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                  <div>
                    <p className={styles.badge}>Nombre : {plotCountPdf}</p>
                    <p className={styles.badge}>
                      Surface totale :{' '}
                      {multiPlotsPdf?.result
                        ? multiPlotsPdf.result?.reduce(
                            (accumulator, elt) =>
                              accumulator + (elt?.theoricCapacity as number),
                            0
                          )
                        : 0}{' '}
                      m²
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p>Vous avez séléctionné la parcelle suivante:</p>
                <div className={styles.synthesisMultiPlotsContent}>
                  <div>
                    <p className={styles.badge}>
                      {parcelle?.parcelleId.substring(5)} -{' '}
                      {parcelle?.theoricCapacity} m²
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SynthesisMultiPlots;
