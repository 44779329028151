import AreaFieldIcon from '../../../assets/images/areaFieldIcon.svg';
import AreaIconSvg from '../../../assets/images/orpiPdf/areaIcon.svg';
import FloorCountIconSvg from '../../../assets/images/orpiPdf/floorCountIcon.svg';
import GoodTypoIconSvg from '../../../assets/images/orpiPdf/goodTypoIcon.svg';
import RoomCountIconSvg from '../../../assets/images/orpiPdf/roomCountIcon.svg';
import { IErrialPDFFormSectionProps } from './RightPanelModalExternalPdfErrial';
import SelectInputFormPdfErrialCustom from './SelectInputFormPdfErrialCustom';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';
import styles from './rightPanelModalExternalPdfErrial.module.scss';

const typologyItems = [
  { value: 'apartment', display: 'Appartement' },
  { value: 'house', display: 'Maison' },
  { value: 'building', display: 'Immeuble' },
  { value: 'field', display: 'Terrain' },
  { value: 'others', display: 'Autres' },
];

function GoodDetailsSection({
  errors,
  control,
  hideLandArea,
}: IErrialPDFFormSectionProps) {
  return (
    <>
      <div className={styles.rowIcon}>
        <img src={GoodTypoIconSvg} alt="good typo icon" />
        <div className={styles.rowInput}>
          <p>Typologie du bien</p>
          <SelectInputFormPdfErrialCustom
            control={control}
            name="propertyType"
            noValue="Choisir une typologie"
            displayEmpty
            items={typologyItems}
            error={Boolean(errors.propertyType)}
            errorMsg={errors.propertyType?.message}
          />
        </div>
      </div>
      <div className={styles.rowIcon}>
        <img src={RoomCountIconSvg} alt="room count icon" />
        <div className={styles.rowInput}>
          <p>Nombre de pièces</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="numberOfRooms"
            type="number"
            error={Boolean(errors.numberOfRooms)}
            errorMsg={errors.numberOfRooms?.message}
          />
        </div>
      </div>
      <div className={styles.rowIcon}>
        <img src={FloorCountIconSvg} alt="floor count icon" />
        <div className={styles.rowInput}>
          <p>Nombre de niveaux</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="floorNumber"
            type="number"
            error={Boolean(errors.floorNumber)}
            errorMsg={errors.floorNumber?.message}
          />
        </div>
      </div>
      <div className={styles.rowIcon}>
        <img src={AreaIconSvg} alt="area icon" />
        <div className={styles.rowInput}>
          <p>Surface habitable</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="livingArea"
            type="number"
            error={Boolean(errors.livingArea)}
            errorMsg={errors.livingArea?.message}
          />
        </div>
      </div>
      {!hideLandArea && (
        <div className={styles.rowIcon}>
          <img src={AreaFieldIcon} alt="area field icon" />
          <div className={styles.rowInput}>
            <p>Surface du terrain</p>
            <TextInputFormPdfErrialCustom
              control={control}
              name="landArea"
              type="number"
              error={Boolean(errors.landArea)}
              errorMsg={errors.landArea?.message}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default GoodDetailsSection;
