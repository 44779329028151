import { useEffect, useRef } from 'react';
import styles from './urbaToolbar.module.scss';

function ToolbarTooltip({ text }: { text: string }) {
  const div = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (div.current) {
      const width = div.current.offsetWidth;
      const margin = -width / 2 + 30;

      // div.current.style.marginLeft = margin.toString() + 'px';
    }
  }, [div.current]);

  return (
    <div ref={div} className={styles.btnTooltip}>
      {text}
    </div>
  );
}

export default ToolbarTooltip;
