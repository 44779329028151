import { pluStatusDisplay } from './utils';

interface IPluStatusProps {
  status: nullable<IPluStatus>;
}

function PluStatus({ status }: IPluStatusProps) {
  return (
    <div className="plu-status">
      <h4>Aucun plan de zone disponible</h4>
      <p>Document d&rsquo;urbanisme</p>
      <p className="status">{pluStatusDisplay(status?.status ?? null)}</p>
    </div>
  );
}

export default PluStatus;
