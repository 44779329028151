import React, { useState } from 'react';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';

export default function LoginContent() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <>
      <h2>
        {showForgotPassword ? ' Réinitialisation du mot de passe ' : 'Se connecter'}
      </h2>
      <div className="login-form-wrapper">
        {showForgotPassword ? (
          <ForgotPasswordForm setShowForgotPassword={setShowForgotPassword} />
        ) : (
          <LoginForm />
        )}
        <p
          className="forgot-password"
          onClick={() => setShowForgotPassword(!showForgotPassword)}
        >
          {showForgotPassword ? 'Annuler' : 'Mot de passe oublié?'}
        </p>
      </div>
    </>
  );
}
