import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { dateFormat } from '../../../../../../lib/formats/dataFormat';

interface ITransactionProps {
  transaction: ITransaction;
}
export default function Transaction({
  transaction,
}: ITransactionProps): JSX.Element {
  return (
    <div className="transaction">
      <p>
        Parcelle id : <span>{transaction.parcelleId}</span>
      </p>
      <p>
        Date de mutation : <span>{dateFormat(transaction.mutationDate)}</span>
      </p>
      <p>
        Valeur : <span>{`${transaction.landValue} €`}</span>
      </p>
      <p>
        Type : <span>{transaction.localType}</span>
      </p>
      <p>
        Superficie : <span>{`${transaction.area} m²`}</span>
      </p>
      <p>
        Prix au m² : <span>{`${transaction.sqmPrice} €`}</span>
      </p>

      <p>
        Nbre de pièces : <span>{transaction.cntRoom}</span>
      </p>
      {!isEmpty(transaction.lots) && (
        <p>
          Lots de copropriété :{' '}
          {transaction.lots.map((lot, i, tab) => (
            <>
              {' '}
              <span className="lot" key={nanoid()}>
                {lot}
              </span>
              {i < tab.length - 1 ? ',' : ''}
            </>
          ))}
        </p>
      )}
    </div>
  );
}
