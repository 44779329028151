import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import SelectFolderItemCustom from './SelectFolderItemCustom/Index';

interface ISelectCustom {
  items: ISelectItem[];
  onChange: ChangeEventCustom;
  disabled?: boolean;
  label?: nullable<string>;
  name?: string;
  noValue?: string;
  value: string | number;
  displayEmpty?: boolean;
  className?: string;
  isFolders?: boolean;
}
export default function SelectCustom({
  items,
  onChange,
  value = '',
  disabled,
  label,
  name,
  noValue,
  displayEmpty,
  className,
  isFolders = false,
}: ISelectCustom) {
  return (
    <FormControl variant="outlined" disabled={disabled}>
      {label ? (
        // <InputLabel className={classes.root} id={name}>
        <InputLabel id={name}>{label}</InputLabel>
      ) : null}
      <Select
        onChange={onChange}
        value={value}
        labelId={name}
        displayEmpty={displayEmpty}
        disabled={disabled}
        className={className}
      >
        <MenuItem key={uuidv4()} value="" disabled>
          <div>{noValue}</div>
        </MenuItem>

        {items.map((elt) => (
          <MenuItem key={uuidv4()} value={elt.value}>
            {isFolders ? (
              <SelectFolderItemCustom
                value={elt.display as string}
                isSubfolder={elt.isSubfolder}
              />
            ) : (
              elt.display
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
