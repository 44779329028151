import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as Edit } from '../../../../assets/images/picto-edit.svg';
import { getAuthState } from '../../../../features/auth/authSlice';
import { modalsActions } from '../../../../features/modals/modalsSlice';
import { userActions } from '../../../../features/users/usersSlice';
import { userUpdateThunk } from '../../../../features/users/usersThunks';

const updateManagerRole = (roles: string[], value: boolean) => {
  const manager = 'ROLE_MANAGER';
  if (value) {
    if (!roles.includes(manager)) {
      return roles.concat(manager);
    }
  } else {
    return roles.filter((f) => f !== manager);
  }

  return roles;
};

interface IFooterProps {
  user: User;
}

function Footer({ user }: IFooterProps) {
  const auth = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleActiveUserChange = () => {
    dispatch(
      userUpdateThunk({ userIdIri: user.idIri, user: { isActive: !user.isActive } })
    );
  };

  const handleRoleUserChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const roles = updateManagerRole(user.roles, value);
    dispatch(userUpdateThunk({ userIdIri: user.idIri, user: { roles } }));
  };

  const handleEditUser = () => {
    dispatch(userActions.setSelectedUser(user));
    dispatch(userActions.setEditingUser(true));

    dispatch(modalsActions.addUser(true));
  };

  return (
    <div className="card-footer">
      <div className="card-footer-edit">
        <div className="switch">
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={user.isActive}
                required
                onChange={handleActiveUserChange}
                name="check"
              />
            }
            label="compte"
            labelPlacement="start"
          />
        </div>
        <div className="icon">
          <Edit onClick={handleEditUser} />
        </div>
      </div>
      {
        <div className="card-footer-role">
          <p>Prospecteur</p>
          <Switch
            color="primary"
            checked={user.isManager}
            required
            onChange={handleRoleUserChange}
            name="check"
            disabled={auth.userIdIri === user.idIri}
          />
          <p>Manager</p>
        </div>
      }
    </div>
  );
}

export default Footer;
