import { useAppDispatch, useAppSelector } from '../../../../App/store';
import styles from '../dpe.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  initialForm,
  validationSchemaPdfForm,
  dpeGesItems,
  buildingTypes,
} from './formValidation';
import SelectFormCustom from '../../../../components/Common/FormComponents/SelectFormCustom';
import TextFieldFormCustom from '../../../../components/Common/FormComponents/TextFieldFormCustom';
import { fetchDpeAdvancedSearchPointsThunk } from '../dpeThunk';
import { getMapState } from '../../../map/mapSlice';
import { Button } from '@mui/material';
import DatePickerCustom from '../../../../components/Common/DatePickerCustom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { dpeActions, getDpeState } from '../../builtCaracteristicsSlice';
import SectionTitle from '../../SectionTitle';

interface IDpeAdvancedSearch {
  setloader: Dispatch<SetStateAction<boolean>>;
}

function DpeAdvancedSearch({ setloader }: IDpeAdvancedSearch) {
  const defaultError = { hasError: false, message: '' };
  const { geolocDatas } = useAppSelector(getMapState);
  const { dpeAdvancedSearchFormData, dpeVisible, isRecentOnly } = useAppSelector(
    getDpeState
  );
  const [createdAtAfter, setCreatedAtAfter] = useState<Date | null>(
    dpeAdvancedSearchFormData ? dpeAdvancedSearchFormData?.createdAtAfter : null
  );
  const [createdAtBefore, setCreatedAtBefore] = useState<Date | null>(
    dpeAdvancedSearchFormData ? dpeAdvancedSearchFormData?.createdAtBefore : null
  );
  const [dateError, setDateError] = useState<{
    hasError: boolean;
    message: string;
  }>(defaultError);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDpeAdvancedSearchForm>({
    defaultValues: dpeAdvancedSearchFormData ?? initialForm,
    resolver: yupResolver(validationSchemaPdfForm),
  });

  const submitForm = (data: IDpeAdvancedSearchForm): void => {
    setloader(true);
    dispatch(dpeActions.setGenerateDisplay(true));
    if (!dpeVisible) dispatch(dpeActions.showDpe());
    if (isRecentOnly) dispatch(dpeActions.isRecentHide());
    dispatch(
      fetchDpeAdvancedSearchPointsThunk({
        insee: geolocDatas?.inseeCode as string,
        ...data,
        createdAtAfter,
        createdAtBefore,
      })
    );
    dispatch(
      dpeActions.setDpeAdvancedSearchFormData({
        ...data,
        createdAtAfter,
        createdAtBefore,
      })
    );
  };

  const handleDateChange = (type: 'after' | 'before', e: Date | null) => {
    switch (type) {
      case 'after':
        if (e && createdAtBefore && e > createdAtBefore) {
          setDateError({
            hasError: true,
            message: 'La date de début ne doit pas être supérieure à la date de fin',
          });
        } else {
          setCreatedAtAfter(e);
          setDateError(defaultError);
        }
        break;
      case 'before':
        if (e && createdAtAfter && e < createdAtAfter) {
          setDateError({
            hasError: true,
            message: 'La date de fin ne doit pas être inférieure à la date de début',
          });
        } else {
          setCreatedAtBefore(e);
          setDateError(defaultError);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!dpeAdvancedSearchFormData) {
      setCreatedAtAfter(null);
      setCreatedAtBefore(null);
      reset(initialForm);
    }
  }, [dpeAdvancedSearchFormData]);

  return (
    <>
      <SectionTitle title="Recherche Avancée DPE" />
      <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>Surface du bien mini</div>
            <TextFieldFormCustom
              type="number"
              control={control}
              name="builtAreaMin"
              required
              error={Boolean(errors.builtAreaMin)}
              errorMsg={errors.builtAreaMin?.message as string}
              errorTooltip={errors.builtAreaMin}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Surface du bien maxi</div>
            <TextFieldFormCustom
              type="number"
              control={control}
              name="builtAreaMax"
              required
              error={Boolean(errors.builtAreaMax)}
              errorMsg={errors.builtAreaMax?.message as string}
              errorTooltip={errors.builtAreaMax}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>DPE min</div>
            <SelectFormCustom
              control={control}
              items={dpeGesItems}
              name="dpeMin"
              displayEmpty
              noValue="Pas de DPE"
              noChoiceDisabled={false}
              error={Boolean(errors.dpeMin)}
              errorMsg={errors.dpeMin?.message as string}
              errorTooltip={errors.dpeMin}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>DPE max</div>
            <SelectFormCustom
              control={control}
              items={dpeGesItems}
              name="dpeMax"
              displayEmpty
              noValue="Pas de DPE"
              noChoiceDisabled={false}
              error={Boolean(errors.dpeMax)}
              errorMsg={errors.dpeMax?.message as string}
              errorTooltip={errors.dpeMax}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles.label}>GES min</div>
            <SelectFormCustom
              control={control}
              items={dpeGesItems}
              name="gesMin"
              displayEmpty
              noValue="Pas de GES"
              noChoiceDisabled={false}
              error={Boolean(errors.gesMin)}
              errorMsg={errors.gesMin?.message as string}
              errorTooltip={errors.gesMin}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>GES max</div>
            <SelectFormCustom
              control={control}
              items={dpeGesItems}
              name="gesMax"
              displayEmpty
              noValue="Pas de GES"
              noChoiceDisabled={false}
              error={Boolean(errors.gesMax)}
              errorMsg={errors.gesMax?.message as string}
              errorTooltip={errors.gesMax}
            />
          </div>
        </div>
        <SelectFormCustom
          control={control}
          items={buildingTypes}
          name="buildingType"
          displayEmpty
          noValue="Toutes Typologies"
          noChoiceDisabled={false}
          error={Boolean(errors.buildingType)}
          errorMsg={errors.buildingType?.message as string}
          errorTooltip={errors.buildingType}
        />
        <div className={styles.datesInputs}>
          <p>DPE réalisés entre</p>
          <div>
            <div className={styles.dateField}>
              <DatePickerCustom
                value={createdAtAfter}
                onChange={(e: Date | null) => handleDateChange('after', e)}
                setError={dateError.hasError}
                format="dd-MM-yyyy"
                minDate={new Date('2003-01-01')}
                maxDate={new Date()}
              />
            </div>{' '}
            et{' '}
            <div className={styles.dateField}>
              <DatePickerCustom
                value={createdAtBefore}
                onChange={(e: Date | null) => handleDateChange('before', e)}
                setError={dateError.hasError}
                format="dd-MM-yyyy"
                minDate={new Date('2003-01-01')}
                maxDate={new Date()}
              />
            </div>
          </div>
          <p
            className={`${styles.errorBetweenDates} ${
              dateError.hasError ? styles.visible : ''
            }`}
          >
            {dateError.message}
          </p>
        </div>
        <Button type="submit">Rechercher</Button>
      </form>
    </>
  );
}

export default DpeAdvancedSearch;
