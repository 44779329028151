import { fetchDatas, postEntity } from '../../services/axiosFiles/genericCrud';
import { contactApiToStoreParser, contactsApiToStoreParser } from './contactParser';

interface IfetchContactThunkParams {
  itemsPerPage?: number;
  page?: number;
  search?: string;
}

export const fetchContacts = async (
  params: IfetchContactThunkParams,
  companyIdIri: string | null
) => {
  try {
    if (companyIdIri) {
      const result = await fetchDatas(`${companyIdIri}/contacts`, params);
      const parsedContacts = contactsApiToStoreParser(result['hydra:member']);

      return parsedContacts;
    } else {
      return Promise.reject({ status: 400, message: 'companyIdIri missing' });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContact = async (params: ContactBase, companyIdIri: string) => {
  try {
    if (companyIdIri && params.firstName && params.lastName) {
      const result = await postEntity({
        endpoint: '/contacts',
        body: { ...params, company: companyIdIri },
      });

      const parsedResponse = contactApiToStoreParser(result);

      return parsedResponse;
    } else {
      return Promise.reject({
        status: 402,
        message: 'one or more params are missing',
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
