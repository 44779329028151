import { memo } from 'react';
import createCtx from '../../../../App/contexts/GenenricStateContext';
import { useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../../features/auth/authSlice';
import DeleteStudyButton from '../../../../features/study/components/DeleteStudyButton';
import StudyContactContent from '../../../../features/study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import { FeasabilityMatrix } from '../../../../features/study/components/studyMatrix/MatrixContainer';
import ProcedureContent from '../../../../features/study/components/studyProcedure/rightPanelProcedureBloc/ProcedureContent';
import { getStudyParamsState } from '../../../../features/study/slices/studyParamsSlice';
import { getStudyState } from '../../../../features/study/slices/studySlice';
import ConstructComment from './components/prospection/ConstructComment';
import ManagementContent from './components/prospection/Management/ManagementContent';
import ProspectionContainer from './components/prospection/ProspectionContainer';
import SectorMailBoxesContent from './components/prospection/SectorMailBoxesContent';
import SectorResponsableContent from './components/prospection/SectorResponsableContent';

export type ProspectionTabBlocsProps = {
  study: IStudy | null;
  updateAllowed: boolean;
};

const [ctx, StudyContactProvider] = createCtx<StudyContacts | null>(null);
export const studyContactContext = ctx;

function Prospection() {
  const { isManager, user } = useAppSelector(getAuthState);
  const studyParams = useAppSelector(getStudyParamsState);
  const studyState = useAppSelector(getStudyState);

  const study = studyState.study.result;
  const updateAllowed = study?.responsable?.idIri === user?.idIri || isManager;

  const handleScroll = (offset: number) => {
    window.scrollTo(0, offset);
  };

  return (
    <>
      {studyParams?.type !== 'sector' ? (
        <ProspectionContainer
          title={
            study
              ? 'Modifier le dossier de prospection'
              : 'Ajouter à un dossier de prospection'
          }
          isOpen
        >
          <ManagementContent
            study={study as IPlotFolderStudy}
            updateAllowed={updateAllowed}
          />
        </ProspectionContainer>
      ) : (
        <>
          <ProspectionContainer title="Responsable Secteur">
            <SectorResponsableContent
              study={study as ISectorStudy}
              updateAllowed={updateAllowed}
            />
          </ProspectionContainer>

          <ProspectionContainer title="Nombre de boites aux lettres">
            <SectorMailBoxesContent
              study={study as ISectorStudy}
              updateAllowed={updateAllowed}
            />
          </ProspectionContainer>
        </>
      )}

      <StudyContactProvider>
        {studyParams?.type !== 'sector' && study && (
          <ProspectionContainer title="Contacts">
            <StudyContactContent
              studyIdIri={study.idIri}
              onScroll={handleScroll}
              updateAllowed={updateAllowed}
            />
          </ProspectionContainer>
        )}

        {study && (
          <ProspectionContainer title="Démarches entreprises">
            <ProcedureContent
              studyIdIri={study.idIri}
              updateAllowed={updateAllowed}
            />
          </ProspectionContainer>
        )}
      </StudyContactProvider>

      {studyParams && studyParams.type !== 'sector' && study && (
        <ProspectionContainer title="Matrice de faisabilité">
          <FeasabilityMatrix
            matrixIdIri={(study as IPlotFolderStudy).feasibilityMatrix}
            studyParams={studyParams}
            updateAllowed={updateAllowed}
          />
        </ProspectionContainer>
      )}

      {study && (
        <ProspectionContainer
          title={`Commentaires${
            studyParams?.type !== 'sector' ? ' constructibilité' : ''
          }`}
        >
          <ConstructComment study={study} updateAllowed={updateAllowed} />
        </ProspectionContainer>
      )}

      {studyParams?.type === 'plotStudy' && Boolean(study) && updateAllowed && (
        <DeleteStudyButton />
      )}
    </>
  );
}

export default memo(Prospection);
