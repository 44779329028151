import * as Yup from 'yup';

export const dpeGesItems = [
  { value: 'A', display: 'A' },
  { value: 'B', display: 'B' },
  { value: 'C', display: 'C' },
  { value: 'D', display: 'D' },
  { value: 'E', display: 'E' },
  { value: 'F', display: 'F' },
  { value: 'G', display: 'G' },
];

export const buildingTypes = [
  { value: 'maison', display: 'Maison' },
  { value: 'appartement', display: 'Appartement' },
  { value: 'immeuble', display: 'Immeuble' },
  { value: 'logements collectifs', display: 'Logements collectifs' },
];

const validationMessagesDpeGes = (value: string) => {
  return {
    moreThan: `Le ${value} maximal doit être supérieur au ${value} minimal`,
    lessThan: `Le ${value} minimal doit être inférieur au ${value} maximal`,
  };
};

export const initialForm: IDpeAdvancedSearchForm = {
  builtAreaMin: 0,
  builtAreaMax: 0,
  dpeMin: '',
  dpeMax: '',
  gesMin: '',
  gesMax: '',
  buildingType: '',
};

export const validationSchemaPdfForm = Yup.object({
  builtAreaMin: Yup.number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0, 'Nombre négatif interdit')
    .nullable()
    .when('builtAreaMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: Yup.number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .min(0, 'Nombre négatif interdit')
        .nullable()
        .max(Yup.ref('builtAreaMax'), 'Min doit être inférieur à max'),
    }),
  builtAreaMax: Yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return Yup.number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(0, 'Nombre négatif interdit')
          .nullable();
      default:
        return Yup.number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(0, 'Nombre négatif interdit')
          .nullable()
          .min(0, 'Nombre négatif interdit')
          .when('builtAreaMin', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: Yup.number()
              .transform((value) => (isNaN(value) ? 0 : value))
              .min(0, 'Nombre négatif interdit')
              .nullable()
              .min(Yup.ref('builtAreaMin'), 'Max doit être supérieur à min'),
          });
    }
  }),
  dpeMin: Yup.string()
    .nullable()
    .test(
      'dpeMinGreaterThandpeMax',
      validationMessagesDpeGes('DPE').lessThan,
      (value, context) => {
        const { dpeMax } = context.parent;

        if (value && dpeMax) {
          return value >= dpeMax;
        } else {
          return true;
        }
      }
    ),
  dpeMax: Yup.string()
    .nullable()
    .test(
      'dpeMaxLesserThandpeMin',
      validationMessagesDpeGes('DPE').moreThan,
      (value, context) => {
        const { dpeMin } = context.parent;

        if (value && dpeMin) {
          return value <= dpeMin;
        } else {
          return true;
        }
      }
    ),
  gesMin: Yup.string()
    .nullable()
    .test(
      'gesMinGreaterThanGesMax',
      validationMessagesDpeGes('GES').lessThan,
      (value, context) => {
        const { gesMax } = context.parent;

        if (value && gesMax) {
          return value >= gesMax;
        } else {
          return true;
        }
      }
    ),
  gesMax: Yup.string()
    .nullable()
    .test(
      'gesMaxLesserThanGesMin',
      validationMessagesDpeGes('GES').moreThan,
      (value, context) => {
        const { gesMin } = context.parent;

        if (value && gesMin) {
          return value <= gesMin;
        } else {
          return true;
        }
      }
    ),
  buildingType: Yup.string(),
});
