import { GeoJSON } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import { getAdvancedPlotSearchState } from '../advancedPlotSearchSlice';

const jsonStyle = {
  fillColor: 'purple',
  weight: 2,
  color: 'purple',
  fillOpacity: 0.4,
};

function AdvancedSearchZone() {
  const { geoFromDraw, formData } = useAppSelector(getAdvancedPlotSearchState);

  const geo = geoFromDraw ?? formData?.polygon;
  return <>{geo && <GeoJSON style={jsonStyle} data={geo} />}</>;
}

export default AdvancedSearchZone;
