import { nanoid } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../App/store';
import PaginationCustomDashboard from '../../../components/Common/PaginationCustomDashboard';
import { genericEntitySort } from '../../../lib/sort/genericEntitySort';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import Folder from '../folder';
import { foldersActions } from '../foldersSlice';

interface IDashboardFolderContainer {
  folders: Folders | null;
  searchPattern: string;
  sortKey: string;
  order: Order;
}

function DashboardFolderContainer({
  folders,
  order,
  sortKey,
  searchPattern,
}: IDashboardFolderContainer) {
  const [indexPage, setIndexPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredFolders, setFilteredFolders] = useState<Folders>([]);
  const dispatch = useAppDispatch();

  const hasPagination =
    filteredFolders.length > itemsPerPage ||
    filteredFolders.length !=
      filteredFolders.filter(
        (f, i) => i >= indexPage && i < indexPage + itemsPerPage
      ).length;

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  useEffect(() => {
    // need filteredFolderState for update page count
    // (pattern changing count)
    const ff = genericEntitySort<IFolder>(
      folders ?? [],
      sortKey,
      order
    ).filter((f) =>
      searchPattern !== ''
        ? f.name.toLowerCase().includes(searchPattern.toLowerCase())
        : f
    );

    setFilteredFolders(ff);
  }, [folders, order, sortKey, searchPattern]);

  useEffect(() => {
    return () => {
      dispatch(foldersActions.deployedSubFolderReset());
      dispatch(foldersActions.folderForActionReset());
    };
  }, []);

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    setItemsPerPage(value);
  };

  return (
    <div className="tab-body">
      <div className="item-list">
        {!folders ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(folders) ? (
          <p className="no-element">Aucun dossier</p>
        ) : (
          filteredFolders
            .filter((f, i) => i >= indexPage && i < indexPage + itemsPerPage)
            .map((folder) => {
              return (
                <Folder
                  key={nanoid()}
                  folder={folder}
                  isSubfolder={false}
                  isDashboard={true}
                />
              );
            })
        )}
      </div>
      {hasPagination && (
        <PaginationCustomDashboard
          itemPerPageLabel="Dossiers par page"
          elementsQty={folders?.length || 0}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          onMinIndexTab={handleChangeIndex}
          forceDisplay={true}
        />
      )}
    </div>
  );
}

function propsAreEquals(
  p: Readonly<IDashboardFolderContainer>,
  n: Readonly<IDashboardFolderContainer>
) {
  let result = true;
  //if sort and search is updated
  if (
    p.order !== n.order ||
    p.searchPattern ||
    n.searchPattern ||
    p.sortKey !== n.sortKey
  ) {
    result = false;
  } else if (!isEqual(p.folders, n.folders)) {
    result = false;
  }

  return result;
}
export default memo(DashboardFolderContainer, propsAreEquals);
