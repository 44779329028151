import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../App/store';
import TextFieldFormCustom from '../../../../components/Common/FormComponents/TextFieldFormCustom';
import { forgotPasswordThunk } from '../../authThunks';
import {
  initialForgotPasswordForm,
  validationSchemaForgotPasswordForm,
} from './formValidation';

interface IForgotPasswordFormProps {
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

type FormType = { email: string };

export default function ForgotPasswordForm({
  setShowForgotPassword,
}: IForgotPasswordFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: initialForgotPasswordForm,
    resolver: yupResolver(validationSchemaForgotPasswordForm),
  });

  const submitForm = (data: { email: string }): void => {
    dispatch(forgotPasswordThunk(data));
    setShowForgotPassword(false);
  };

  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(submitForm)}
      autoComplete="off"
    >
      <TextFieldFormCustom
        type="email"
        control={control}
        name="email"
        placeholder="Email"
        required
        error={Boolean(errors.email)}
        errorMsg={errors.email?.message as string}
      />
      <button type="submit" className="submit-btn">
        ENVOYER
      </button>
    </form>
  );
}
