import { postEntity } from '../../../services/axiosFiles/genericCrud';

async function parcelsVerification(plots: string[]) {
  try {
    const response: any = await postEntity({
      endpoint: '/pdf_urbanease/parcels_verification/',
      body: plots,
    });
    const parsed: PlotVerification = {
      hasNoAdjacentPlot: !response.parcels_are_adjacent,
      plotIds:
        response.non_adjacent_parcels?.map((m: string[]) =>
          m.map((n) => n.substring(5))
        ) ?? null,
      errorMessage: response.error ?? null,
    };

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default parcelsVerification;
