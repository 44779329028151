import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import { DrawIconEnum } from '../drawPlugin/types/drawActionEnum';
import styles from './urbaToolbar.module.scss';
import getToolbarIcon from './utils/getToolbarIcon';

interface ICreateToolbarBtnProps {
  icon: IconType;
  onClick: (e: React.MouseEvent<HTMLDivElement>, type: IconType) => void;
  displayed?: IconTypes;
  isMeasureIcon?: boolean;
  isAltimetryIcon?: boolean;
  isMultiplotStart?: boolean;
  isMultiplotSave?: boolean;
  isLoading?: boolean;
  isFontAwesomeIcon?: boolean;
  isPrimaryBg?: boolean;
  tooltip: React.ReactNode;
  optionsContainer?: React.ReactNode;
  hasNotification?: boolean;
  notification?: React.ReactNode;
}

function CreateToolbarBtn({
  icon,
  onClick,
  isMeasureIcon,
  isAltimetryIcon,
  optionsContainer,
  isMultiplotStart,
  isMultiplotSave,
  isPrimaryBg,
  isLoading,
  isFontAwesomeIcon,
  tooltip,
  notification,
  hasNotification,
}: ICreateToolbarBtnProps) {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick(e, icon);
  };

  const ToolbarAwesomeIcon = () => {
    switch (icon) {
      case DrawIconEnum.DRAW_ICON_EDIT_SAVE:
        return <FontAwesomeIcon icon={faFloppyDisk} />;
      case 'ACCOUNT':
        return <FontAwesomeIcon icon={faUser} color="red" />;
      default:
        return null;
    }
  };

  let style = styles.iconContainer;
  if (isMeasureIcon) style += ' ' + styles.isMeasureIcon;
  if (isPrimaryBg) style += ' ' + styles.primaryBg;
  if (isAltimetryIcon) style += ' ' + styles.isAltimetryIcon;
  if (isMultiplotStart) style += ' ' + styles.isMultiplotStart;
  if (isMultiplotSave) style += ' ' + styles.isMultiplotSave;

  const iconUrl = getToolbarIcon(icon);

  return (
    <div className={styles.urbaToolbarBtnContainer}>
      <div className={style} onClick={(e) => handleClick(e)}>
        {isFontAwesomeIcon ? <ToolbarAwesomeIcon /> : <img src={iconUrl ?? ''} />}
        {isLoading && iconUrl && (
          <img
            src={iconUrl}
            className={styles.notReady}
            title="Indisponible pour le moment"
          />
        )}
        {tooltip}
      </div>
      {hasNotification && <div className={styles.notification}>{notification}</div>}
      {optionsContainer}
    </div>
  );
}

export default CreateToolbarBtn;
