import { nanoid } from '@reduxjs/toolkit';
import { LatLngExpression } from 'leaflet';
import { useEffect } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../App/store';
import MarkerBase from '../../../components/Map/Common/MarkerBase';
import MarkerNumber from '../../../components/Map/Common/MarkerNumber';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getExternalPdfErrialState } from '../../externalPdfErrial/externalPdfErrialSlice';
import { getMultiPlotSelectState } from '../multiPlotSelectFeature/multiPlotSelectSlice';
import MarkerPopup from './MarkerPopup';

const jsonPlotStyle = {
  weight: 1,
  fillOpacity: 0.2,
  fillColor: 'red',
  color: 'red',
};

const jsonBuildingStyle = {
  ...jsonPlotStyle,
  fillColor: 'red',
  color: 'red',
};

function MultiPlotSelectLayer() {
  const map = useMap();
  const { plots } = useAppSelector(getMultiPlotSelectState);
  const { multiPlotsPdf } = useAppSelector(getExternalPdfErrialState);

  const location = useLocation();
  const path = location.pathname;
  const pathExternalPdfErrial = path === '/external_pdf_errial';

  const plotCoordTab = pathExternalPdfErrial
    ? multiPlotsPdf.result?.map((p) => ({
        index: p.index,
        plotId: p.fullPlotId,
        centroid: [p.lat, p.lng],
        geometry: p.plotGeometry,
        buildings: p.buildingsGeometry,
      }))
    : plots.result?.map((p) => ({
        index: p.index,
        plotId: p.fullPlotId,
        centroid: [p.lat, p.lng],
        geometry: p.plotGeometry,
        buildings: p.buildingsGeometry,
      }));

  useEffect(() => {
    if (
      plots.apiStatus !== APIStatus.PENDING &&
      multiPlotsPdf.apiStatus !== APIStatus.PENDING
    ) {
      map.getContainer().classList.remove('loading-plot');
    }
  }, [plots.apiStatus, multiPlotsPdf.apiStatus]);

  return (
    <>
      {plotCoordTab?.map((pct, i) => (
        <div key={nanoid()}>
          {pathExternalPdfErrial ? (
            <MarkerNumber
              number={i + 1}
              markerLatLng={pct.centroid as LatLngExpression}
              markerColor="cyan"
              zIndexOffset={750}
            >
              <MarkerPopup index={pct.index} plotId={pct.plotId} externalPdfErrial />
            </MarkerNumber>
          ) : (
            <MarkerBase
              markerLatLng={pct.centroid as LatLngExpression}
              markerUrl="cyan"
              zIndexOffset={750}
            >
              <MarkerPopup index={pct.index} plotId={pct.plotId.substring(5)} />
            </MarkerBase>
          )}

          <GeoJSON style={jsonPlotStyle} data={pct.geometry} />

          <GeoJSON key={nanoid()} style={jsonBuildingStyle} data={pct.buildings} />
        </div>
      ))}
    </>
  );
}

export default MultiPlotSelectLayer;
