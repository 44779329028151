import DashboardPage from '../pages/DashboardPage';
import HomePage from '../pages/HomePage';
import MyAccountPage from '../pages/MyAccountPage';
import ExternalPdfErrialPage from '../pages/ExternalPdfErrialPage';

export const routes = [
  {
    path: '/',
    exact: true,
    isAuth: false,
    Component: HomePage,
  },
  {
    path: '/account',
    exact: true,
    isAuth: true,
    Component: MyAccountPage,
  },
  {
    path: '/dashboard',
    exact: true,
    isAuth: true,
    Component: DashboardPage,
  },
  {
    path: '/external_pdf_errial',
    exact: true,
    isAuth: true,
    Component: ExternalPdfErrialPage,
  },
];
