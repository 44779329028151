import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { ReactComponent as LogoIA } from '../../../../../assets/images/logo-ai-white.svg';
import editIcon from '../../../../../assets/images/toolbar-edit.png';
import Xicon from '../../../../../assets/images/xicon.png';
import { studyContactContext } from '../../../../../components/RightPanelModal/TabsContent/bodys/Prospection';
import { emailContact } from '../../../../../components/RightPanelModal/TabsContent/constant';
import { APIStatus } from '../../../../../services/axiosFiles/apiTypes';
import { getAuthState } from '../../../../auth/authSlice';
import useCompany from '../../../../company/useCompany';
import { getUrbaGptState, urbaGptActions } from '../../../../urbaGpt/urbaGptSlice';
import { urbaGptQueryThunk } from '../../../../urbaGpt/urbaGptThunk';
import deleteStudyContact from '../../../services/deleteStudyContact';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import { questions } from '../../../utils/urbaGPTQuestions';
import { ForceOpenContext } from './StudyContactContent';
interface IContactProps {
  studyContact: IStudyContact;
  parcelle: IPlot | null;
  address: string | null;
  city: string | null;
  onSelect: (contact: IStudyContact) => void;
  updateAllowed: boolean;
}

function StudyContactCard({
  studyContact,
  parcelle,
  address,
  city,
  updateAllowed,
  onSelect,
}: IContactProps) {
  const { update } = useContext(ForceOpenContext);
  const studyContactCtx = useContext(studyContactContext);
  const { user } = useAppSelector(getAuthState);
  const { type } = useAppSelector(getStudyParamsState);
  const { getCompanyName, company } = useCompany();
  const [openUrbaGptAction, setOpenUrbaGptAction] = useState(false);
  const [isSentAgent, setIsSentAgent] = useState(false);
  const [isSentProspector, setIsSentProspector] = useState(false);
  const { urbaGptIsVisible, conversation } = useAppSelector(getUrbaGptState);
  const dispatch = useAppDispatch();
  const contactFullName = `${studyContact.contact.firstName ?? ''} ${
    studyContact.contact.lastName
  }`;

  const ask = (type: 'agent' | 'prospector') =>
    questions(
      type,
      studyContact,
      (address = ''),
      parcelle as IPlot,
      user as User,
      company.result as Company,
      getCompanyName()?.name ?? ''
    );

  const handleUpdate = (): void => {
    update(true);
    onSelect(studyContact);
  };

  const handleDelete = async () => {
    try {
      const deleted = await deleteStudyContact(studyContact.idIri);
      studyContactCtx.update(
        studyContactCtx.state?.filter((f) => f.idIri !== deleted) ?? []
      );
    } catch (error) {
      console.log('impossible de supprimer contact study');
    }
  };

  const handleUrbaGpt = (type: 'agent' | 'prospector') => {
    const question = questions(
      type,
      studyContact,
      (address = ''),
      parcelle as IPlot,
      user as User,
      company.result as Company,
      getCompanyName()?.name ?? ''
    );
    if (!urbaGptIsVisible) {
      dispatch(
        urbaGptActions.setConversation({
          conversationUuid: '',
          datelog: new Date().toLocaleDateString(),
          id: 0,
          question,
          response: '',
          isAnim: false,
        })
      );
      dispatch(urbaGptQueryThunk({ question }));
    }
  };

  return (
    <div className="contact-card">
      <div className="left-block">
        <p>{contactFullName}</p>
        <p>{studyContact.contact.address}</p>
        <p>{studyContact.contact.phone}</p>
        <p>
          <a
            href={`mailto:${studyContact.contact.email}${emailContact(
              user,
              parcelle?.parcelleId || 'indéfini',
              city || 'indéfini',
              address || 'indéfini'
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {studyContact.contact.email}
          </a>
        </p>
        <p>{studyContact.role?.label}</p>
      </div>
      <p className="center-block">{studyContact.contact.description}</p>
      <div className="right-block">
        {updateAllowed && (
          <div className="button-contact-actions">
            <img src={editIcon} alt="edit" onClick={() => handleUpdate()} />

            <img src={Xicon} alt="close" onClick={() => handleDelete()} />
          </div>
        )}
        {type === 'plotStudy' && (
          <div className="urba-gpt">
            {openUrbaGptAction ? (
              <>
                <ArrowBackIcon
                  onClick={() => setOpenUrbaGptAction(false)}
                  className="arrow-back"
                />
                <button
                  onClick={() => {
                    if (!isSentAgent) handleUrbaGpt('agent');
                    dispatch(urbaGptActions.setUrbaGptIsVisible(!urbaGptIsVisible));
                    setIsSentAgent(true);
                  }}
                  className={`urba-gpt-btn ${
                    conversation.apiStatus === APIStatus.PENDING &&
                    'urba-gpt-btn-disabled'
                  }`}
                  disabled={conversation.apiStatus === APIStatus.PENDING}
                >
                  En tant qu&apos;agent immobilier
                </button>
                <button
                  onClick={() => {
                    if (!isSentProspector) handleUrbaGpt('prospector');
                    dispatch(urbaGptActions.setUrbaGptIsVisible(!urbaGptIsVisible));
                    setIsSentProspector(true);
                  }}
                  className={`urba-gpt-btn ${
                    conversation.apiStatus === APIStatus.PENDING &&
                    'urba-gpt-btn-disabled'
                  }`}
                  disabled={conversation.apiStatus === APIStatus.PENDING}
                >
                  En tant que prospecteur foncier
                </button>
              </>
            ) : (
              <button
                onClick={() => setOpenUrbaGptAction(true)}
                className="urba-gpt-btn-logo"
              >
                Générer un courrier de prospection
                <LogoIA className="urba-gpt-logo" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StudyContactCard;
