import { memo, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { modalsActions } from '../../../../modals/modalsSlice';
import { getMapPluginsMeasureState, mapPluginsActions } from '../../mapPluginSlice';
import styles from '../measureToolbar.module.scss';
import { MeasureActionEnum } from '../types/measureActionEnum';
import StartInterfaceSection from './StartInterfaceSection';
import AreaStart from './SurfaceDrawSection';

export interface IMeasureInterfaceProps {
  onClick: (type: MeasureActionEnum) => void;
}
function MeasurePluginInterface() {
  const div = useRef<HTMLDivElement>(null);
  const { displayInterface, action, lineActivated } = useAppSelector(
    getMapPluginsMeasureState
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (div.current) {
      const width = div.current.offsetWidth;
      const margin = -width / 2 + 30;
      console.log(width, margin);
      div.current.style.marginLeft = margin.toString() + 'px';
    }
  }, [div.current]);

  useEffect(() => {
    if (lineActivated) {
      dispatch(modalsActions.measureHelperModal(true));
    } else {
      dispatch(modalsActions.toolbarHelperModalReset());
    }
  }, [lineActivated]);

  const handleClick = (type: MeasureActionEnum) => {
    dispatch(mapPluginsActions.measureActionSet(type));
  };
  if (displayInterface) {
    return (
      <div ref={div} className={styles.measurePluginInterface}>
        {action !== MeasureActionEnum.START_DRAW_SURFACE && (
          <StartInterfaceSection onClick={handleClick} />
        )}
        {action === MeasureActionEnum.START_DRAW_SURFACE && (
          <AreaStart onClick={handleClick} />
        )}
      </div>
    );
  }
  return null;
}

export default memo(MeasurePluginInterface, () => false);
