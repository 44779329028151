import { isEmpty } from 'lodash';
import { dateFormat } from '../../lib/formats/dataFormat';
import { changeApiKeyForStoreKey } from '../../services/common/parserCommon';
import { imageDataParse } from '../../utils/jsFunctions';

export const catnatMapping = {
  cod_commune: 'city',
  lib_commune: 'postalCode',
  lib_risque_jo: 'libRisqueJo',
  catnat_datas: 'catnatDatas',
};
export const catnatDatasMapping = {
  code_nat_catnat: 'catnat',
  start: 'start',
  fin: 'end',
  stop: 'stop',
  jo: 'jo',
};

// const contaminatedAreasParser = (sis: any): ContaminatedAreasDatas => {
//   const unknownSis = sis.filter((f: any) => !f.libelle);
//   const contaminatedAreas: ContaminatedAreas = sis
//     .filter((f: any) => f.libelle)
//     .map((m: any, i: number) => {
//       return { name: 'Site ' + (i + 1), description: m.libelle };
//     });

//   if (contaminatedAreas.length > 0 && unknownSis.length > 0) {
//     contaminatedAreas.push({
//       name: 'Autres',
//       description: unknownSis.length + ' site(s) non nommé(s)',
//     });
//   }
//   const count =
//     unknownSis.length > 0
//       ? contaminatedAreas.length + unknownSis.length - 1
//       : contaminatedAreas.length;
//   return {
//     count,
//     sites: contaminatedAreas,
//   };
// };

const environmentRisksDetailsParser = (
  data: any[],
  codeType: string
): EnvironmentDataTypes => {
  if (data && !isEmpty(data)) {
    const parsed = data.map((m: any) => {
      return {
        code: m['cod_nat_' + codeType] ?? 'Inconnu',
        datePrescription: m.dat_prescription
          ? dateFormat(m.dat_prescription)
          : 'Inconnu',
        dateApprobation: m.dat_approbation
          ? dateFormat(m.dat_approbation)
          : 'Inconnu',
        label: m.lib_risque ?? 'Inconnu',
      } as EnvironmentDataType;
    });
    return parsed;
  } else {
    return [];
  }
};

export const externalPdfErrialApiToStoreParser = (
  data: any
): DatasForPdfGeneration => {
  try {
    const {
      customer,
      parcels,
      pictures,
      city_environment_datas,
      parcels_environment_datas,
      parcels_max_buildings_height,
      parcels_predominant_plu_zone,
    } = data;
    const { polluted_sites_around_parcels } = parcels_environment_datas;

    const parsedData: DatasForPdfGeneration = {
      customer: customer,
      parcels: parcels,
      pictures: {
        parcelsOnCadastrePicture: imageDataParse(
          pictures.parcels_on_cadastre_picture
        ),
        parcelsOnZonePicture: imageDataParse(pictures.parcels_on_zone_picture),
        homePagePicture: imageDataParse(pictures.home_page_picture),
      },
      parcelsMaxBuildingsHeight: parcels_max_buildings_height,
      zonePlu: parcels_predominant_plu_zone
        ? `${
            parcels_predominant_plu_zone.zone
              ? `${parcels_predominant_plu_zone.zone}`
              : ''
          }${
            parcels_predominant_plu_zone.zone &&
            parcels_predominant_plu_zone.zone_child
              ? `-${parcels_predominant_plu_zone.zone_child}`
              : ''
          }`
        : '',
      parcelsEnvironmentDatas: {
        seismic: parcels_environment_datas['Données sismiques'].score,
        clay: parcels_environment_datas['Données ARGILES'].score,
        radon: parcels_environment_datas['indice RADON'].score,
        coastalErosion:
          parcels_environment_datas['Trait littoral - Érosion côtière'],
        envConstraints: {
          isMonument: parcels_environment_datas['Secteurs Batiments Historiques'],
          isRemarkableHeritage:
            parcels_environment_datas['Site patrimonial remarquable'],
          isArchaeologicalPrescriptionArea:
            parcels_environment_datas['Prescriptions Archéologiques'],
          isPEBImpact:
            parcels_environment_datas["PEB - Plan d'Exposition au Bruit"].bool,
          PEBValue:
            parcels_environment_datas["PEB - Plan d'Exposition au Bruit"].score,
        },
      },
      // contaminatedAreas: contaminatedAreasParser(sis_around_parcels),
      contaminatedAreas: polluted_sites_around_parcels.map((m: any) => ({
        count: m.count,
        libelle: m.libelle,
      })),

      cityEnvironmentDatas: {
        pprm: environmentRisksDetailsParser(city_environment_datas.pprm, 'pprm'),
        pprn: environmentRisksDetailsParser(city_environment_datas.pprn, 'pprn'),
        pprt: environmentRisksDetailsParser(city_environment_datas.pprt, 'pprt'),
        azi: city_environment_datas.azi.map((m: any) => {
          return { code: m.cod_nat_azi, label: m.lib_azi };
        }),
        catnat: city_environment_datas.catnat.map((data: any) =>
          catnatApiToStoreParser(data)
        ),
      },
    };

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing external pdf errial - ' + error.message);
  }
};

export const catnatApiToStoreParser = (data: any) => {
  try {
    const parsedData = changeApiKeyForStoreKey<any>(data, catnatMapping);

    parsedData.catnatDatas = parsedData.catnatDatas.map((data: any) =>
      changeApiKeyForStoreKey(data, catnatDatasMapping)
    );

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing catnat - ' + error.message);
  }
};

export const sweepbrightDataApiToStoreParser = (
  data: IFetchSweepbrightPdfDataThunkResponse['data']
): ISweepbrightData => {
  try {
    const { description, property } = data;
    const {
      propertyType,
      livingArea,
      landArea,
      numberOfRooms,
      numberOfFloorsInBuilding,
    } = property;
    const { city, postCode, street, houseNumber } = property.location.address;

    const parsedData: ISweepbrightData = {
      description: description,
      addressCity: city || postCode ? `${city ?? ''} ${postCode ?? ''}` : '',
      addressStreet:
        street || houseNumber ? `${houseNumber ?? ''} ${street ?? ''}` : '',
      propertyType: propertyType.code ?? '',
      livingArea: livingArea ?? 0,
      landArea: landArea ?? 0,
      numberOfRooms: numberOfRooms ?? 0,
      floorNumber: numberOfFloorsInBuilding ?? 0,
    };

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing external pdf errial - ' + error.message);
  }
};
