import { delay } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useQuery from '../../../App/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../App/store';

import IndividualCircularLoader from '../../loaders/individualCircularLoader';

import LocalStorageService from '../../../services/LocalStorageService';
import { externalPdfErrialActions } from '../../externalPdfErrial/externalPdfErrialSlice';
import { modalsActions } from '../../modals/modalsSlice';
import useUrbaGpt from '../../urbaGpt/useUrbaGpt';
import { authActions, getAuthState } from '../authSlice';
import { logoutThunk } from '../authThunks';
import { decodeToken } from '../utils';
import AnnouncementProcess from './AnnouncementProcess';
import AuthError from './AuthError';
import CompanyProcess from './CompanyProcess';
import FetchPdfDataProcess from './FetchPdfDataProcess';
import FetchPlotProcess from './FetchPlotProcess';
import UserProcess from './UserProcess';
import styles from './connectionProcess.module.scss';

export enum ConnectionWorkFlowEnum {
  IDLE,
  INIT_CONNECTION,
  SET_TOKEN_IN_STORE,
  TOKEN_ERROR,
  START_USER_PROCESS,
  START_FETCH_ANNOUNCEMENT_PROCESS,
  START_COMPANY_PROCESS,
  START_FETCH_PLOT_PROCESS,
  START_FETCH_PDF_DATA_PROCESS,
  END_CONNECTION_PROCESS,
  REDIRECT_TO_HOME,
  CLOSE_CONNECTION_MODAL,
  ERROR_REFRESH_TOKEN_FAIL,
}

export const CONNEXION_PROCESS_DELAY = 300;

const processVisible = (
  step: ConnectionWorkFlowEnum,
  workflowState: ConnectionWorkFlowEnum
) => {
  const s = Object.values(ConnectionWorkFlowEnum).indexOf(step);
  const w = Object.values(ConnectionWorkFlowEnum).indexOf(workflowState);

  return s <= w;
};

function ConnectionProcessContent() {
  const [workflowState, setWorkflowState] = useState<ConnectionWorkFlowEnum>(
    ConnectionWorkFlowEnum.IDLE
  );

  const { queries } = useQuery();
  const { token, refreshTokenFail } = useAppSelector(getAuthState);
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const externalId = queries.sweepBright_external_id;
  useUrbaGpt();

  const handleErrorClick = (value?: string) => {
    switch (value) {
      case 'Rafraichir':
        history.push('/');
        window.location.reload();
        break;

      default:
        history.push('/');
        dispatch(logoutThunk());
        break;
    }
  };

  const handleChangeStep = (value: ConnectionWorkFlowEnum) => {
    setWorkflowState(value);
  };

  useEffect(() => {
    if (refreshTokenFail) {
      setWorkflowState(ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL);
    }
  }, [refreshTokenFail]);

  // set tokens in store if no exists
  useEffect(() => {
    try {
      switch (workflowState) {
        // if token into queries (external connexion)
        case ConnectionWorkFlowEnum.IDLE: {
          if (queries.token && queries.refresh_token) {
            dispatch(logoutThunk());
          }
          setWorkflowState(ConnectionWorkFlowEnum.INIT_CONNECTION);
          break;
        }
        case ConnectionWorkFlowEnum.INIT_CONNECTION: {
          // if external connection
          if (queries.token && queries.refresh_token) {
            dispatch(externalPdfErrialActions.setSweepBrightExternalId(externalId));
            delay(() => {
              LocalStorageService.setToken(queries.token);
              LocalStorageService.setRefreshToken(queries.refresh_token);
            }, 100);
            setWorkflowState(ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE);
          } else {
            // go to classic connexion
            if (token) {
              setDecodedToken(decodeToken(token));
              setWorkflowState(ConnectionWorkFlowEnum.START_USER_PROCESS);
            } else {
              setWorkflowState(ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE);
            }
          }

          break;
        }
        case ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE: {
          delay(() => {
            const lsToken = LocalStorageService.getToken();
            const lsRefreshToken = LocalStorageService.getRefreshToken();

            if (lsToken && lsRefreshToken) {
              setDecodedToken(decodeToken(lsToken));
              delay(
                () => {
                  dispatch(
                    authActions.setTokens({
                      token: lsToken,
                      refreshToken: lsRefreshToken,
                    })
                  );
                  setWorkflowState(ConnectionWorkFlowEnum.START_USER_PROCESS);
                },
                CONNEXION_PROCESS_DELAY,
                [lsToken, lsRefreshToken]
              );
            }
          }, 100);
          break;
        }
        case ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS: {
          break;
        }
        case ConnectionWorkFlowEnum.CLOSE_CONNECTION_MODAL: {
          dispatch(modalsActions.connectionProcess(false));
          break;
        }
        case ConnectionWorkFlowEnum.REDIRECT_TO_HOME: {
          if (externalId) {
            history.push('/external_pdf_errial');
          } else {
            history.push('/');
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }, [workflowState]);

  return (
    <div className={styles.connectionProcess}>
      {workflowState === ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE && (
        <>
          <h3>Initialisation de la connexion</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
      {workflowState === ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
        <AuthError
          title="Erreur de reconnexion automatique"
          lines={[
            'La reconnection automatique a échoué',
            'Vous devez vous reconnecter manuellement',
            "l'application va etre réinitialisée",
          ]}
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}
      {/* user process */}
      {processVisible(ConnectionWorkFlowEnum.START_USER_PROCESS, workflowState) &&
        workflowState !== ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
          <UserProcess
            decodedToken={decodedToken}
            updateProcessStep={handleChangeStep}
            onErrorClick={handleErrorClick}
          />
        )}
      {/* announcement process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_ANNOUNCEMENT_PROCESS,
        workflowState
      ) &&
        workflowState !== ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
          <AnnouncementProcess
            decodedToken={decodedToken}
            updateProcessStep={handleChangeStep}
          />
        )}
      {/* company process */}
      {processVisible(ConnectionWorkFlowEnum.START_COMPANY_PROCESS, workflowState) &&
        workflowState !== ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
          <CompanyProcess
            decodedToken={decodedToken}
            updateProcessStep={handleChangeStep}
            onErrorClick={handleErrorClick}
          />
        )}
      {/* fetch plot process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS,
        workflowState
      ) &&
        workflowState !== ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
          <FetchPlotProcess
            queries={queries}
            externalId={externalId}
            updateProcessStep={handleChangeStep}
          />
        )}
      {/* pdf errial process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_PDF_DATA_PROCESS,
        workflowState
      ) &&
        workflowState !== ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL && (
          <FetchPdfDataProcess
            externalId={externalId}
            updateProcessStep={handleChangeStep}
          />
        )}
    </div>
  );
}

export default memo(ConnectionProcessContent);
