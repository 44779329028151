import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { dateFormat } from '../../../../../lib/formats/dataFormat';

const TITLES = [
  'Code national CATNAT',
  'Début le',
  'Fin le',
  'Arrété du',
  'sur le JO du',
];

const styles = StyleSheet.create({
  cont: { marginLeft: 0, width: '100%', display: 'flex', justifyContent: 'center' },
  title: { fontWeight: 300 },
  tabTitles: {
    width: '100%',
    borderLeft: '1px solid #679CC6',
    borderTop: '1px solid #679CC6',
    borderBottom: '1px solid #679CC6',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabTitle: {
    borderRight: '1px solid #679CC6',
    width: '20%',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabRows: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    fontSize: '10px',
  },
  rowCase: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  endLine: {
    borderTop: '1px solid #679CC6',
    margin: '10px 0',
    width: '100%',
  },
});
interface IGenericRisksTabProps {
  risks: CatnatDatas[];
}

function GenericRisksTab(props: IGenericRisksTabProps) {
  return (
    <View>
      <View style={styles.cont}>
        <View style={styles.tabTitles}>
          {TITLES.map((m) => {
            return (
              <View key={nanoid()} style={styles.tabTitle}>
                <Text style={{ textAlign: 'center' }}>{m}</Text>
              </View>
            );
          })}
        </View>
        <View>
          {props.risks?.map((m) => (
            <View key={nanoid()} style={styles.tabRows}>
              <View style={styles.rowCase}>
                <Text>{m.catnat}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.start)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.end)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.stop)}</Text>
              </View>
              <View style={styles.rowCase}>
                <Text>{dateFormat(m.jo)}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.endLine}></View>
    </View>
  );
}

export default GenericRisksTab;
