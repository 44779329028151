import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { dateFormat } from '../../../lib/formats/dataFormat';

const demarcheAction = [
  { id: 0, action: '' },
  { id: 1, action: 'Envoi courrier/mail' },
  { id: 2, action: 'Appel téléphonique' },
  { id: 3, action: 'Visite du site' },
  { id: 4, action: 'Rendez-vous' },
  { id: 5, action: 'Remise d’offre' },
  { id: 6, action: 'Signature de documents' },
];

const styles = StyleSheet.create({
  section: {
    fontSize: '10px',
    width: '60%',
  },
  demarcheData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaceBloc: {
    backgroundColor: '#4E55A2',
    width: '10px',
    height: '1px',
  },
  viewLibelle: { marginBottom: '5px', textDecoration: 'underline' },
  viewValue: {
    marginBottom: '3px',
  },
});

interface IProspectionRightBlocProps {
  demarche: ILastProcedurePDF | null;
}
const ProspectionRightBloc = ({ demarche }: IProspectionRightBlocProps) => {
  return (
    <View style={styles.section}>
      {demarche ? (
        <View>
          <Text style={styles.viewLibelle}>Dernière démarche entreprise</Text>
          <Text style={styles.viewValue}>{demarche.responsable}</Text>
          <View style={styles.demarcheData}>
            <Text style={styles.viewValue}>{demarche.action}</Text>
            <View style={styles.spaceBloc} />
            <Text style={styles.viewValue}>{dateFormat(demarche.date)}</Text>
          </View>
          <Text style={styles.viewValue}>{demarche.description}</Text>
        </View>
      ) : (
        <View>
          <Text style={styles.viewValue}>Aucune démarche effectuée</Text>
        </View>
      )}
    </View>
  );
};

export default ProspectionRightBloc;
