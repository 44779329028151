import { StyleSheet, View } from '@react-pdf/renderer';
import CommonBlocTitle from './CommonBlocTitle';
import ProspectionLeftBloc from './ProspectionLeftBloc';
import ProspectionRightBloc from './ProspectionRightBloc';

const styles = StyleSheet.create({
  section: {
    color: '#4E55A2',
    width: '64%',
  },
  blocContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const Prospection = ({ prospection }: { prospection: IProspectionPDF | null }) => {
  if (!prospection) {
    return null;
  }

  return (
    <View style={styles.section}>
      <CommonBlocTitle text="Prospection" />

      <View style={styles.blocContainer}>
        <ProspectionLeftBloc
          status={prospection.status}
          responsable={prospection.responsable}
        />
        <ProspectionRightBloc demarche={prospection.lastProcedure} />
      </View>
    </View>
  );
};

export default Prospection;
