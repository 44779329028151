import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppSelector } from '../../App/store';
import crossIcon from '../../assets/images/crossicon.png';
import BuildPermitsTable from '../../features/buildPermits/buildPermisTable';

import { getPanelState } from '../../redux/Panels/reducer';
import './rightPanelModal.scss';
import TabContentContainer from './TabsContent/TabContentContainer';
import { useLocation } from 'react-router';

const RightPanelModal = () => {
  const {
    rightPanelDisplay,
    rightPanelContent: { builtPermisTable, prospectionContent },
  } = useAppSelector(getPanelState);
  const { closeRightPanel } = useRightPanel();
  const location = useLocation();
  const path = location.pathname;
  const pathExternalPdfErrial = path === '/external_pdf_errial';

  if (!rightPanelDisplay) {
    return null;
  }

  const handleClose = (): void => {
    closeRightPanel();
  };

  return (
    <>
      {!pathExternalPdfErrial && (
        <div className={`right-panel-modal ${rightPanelDisplay ? 'displayed' : ''}`}>
          <div className="arrow-wrapper " onClick={handleClose}>
            <img src={crossIcon} alt="close" />
          </div>

          {rightPanelDisplay && (
            <>
              {builtPermisTable && <BuildPermitsTable />}
              {prospectionContent && <TabContentContainer />}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RightPanelModal;
