import { memo } from 'react';
import FolderContainer from '../../../../features/folders/folderContainer';
import SectorContainer from '../../../../features/sectors/components/leftPanelProspection/SectorContainer';
import styles from './prospection.module.scss';

const Prospection = (): JSX.Element => {
  return (
    <div className={styles.prospection}>
      <SectorContainer />

      <FolderContainer />
    </div>
  );
};

export default memo(Prospection);
