import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../components/Modals/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import {
  getMultiPlotSelectState,
  multiPlotSelectActions,
} from '../multiPlotSelectSlice';
import MultiSelectPlotModalContent from './MultiSelectPlotModalContent';

export interface IMultiselectPlotModalProps {
  plots: Omit<ICreatePlotStudy, 'folder' | 'responsable'>[] | null;
}
function MultiselectPlotModal({ plots }: IMultiselectPlotModalProps) {
  const reducer = useAppSelector(getMultiPlotSelectState);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (isEmpty(reducer.plots.result)) {
      dispatch(multiPlotSelectActions.reset());
    }
    dispatch(modalsActions.multiPlotAdd({ status: false, plots: null }));
  };

  return (
    <GenericModal
      title="Enregistrement de parcelle(s)"
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      hasIcon={false}
      closeModal={handleClose}
    >
      <MultiSelectPlotModalContent
        onCancel={handleClose}
        onClose={handleClose}
        plots={plots}
      />
    </GenericModal>
  );
}

export default MultiselectPlotModal;
