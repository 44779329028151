import { TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../App/store';
import Houses from '../../../../assets/images/houses.png';
export interface ISignup {
  company?: string;
  firstName?: string;
  lastName: string;
  email: string;
  address?: string;
  city?: string;
  zipCode?: string;
  phone: string;
}
const signupFields: ISignup = {
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  city: '',
  zipCode: '',
  phone: '',
};

interface ISignupFormProps {
  setDisplayed: React.Dispatch<React.SetStateAction<SignupResponse>>;
}
export default function SignupForm({ setDisplayed }: ISignupFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: signupFields,
  });

  const submitResponse = (result: boolean): void => {
    setDisplayed(result ? 'success' : 'failure');
  };

  const submitForm = (data: ISignup): void => {
    if (isEmpty(errors)) {
      // dispatch(authActions.signup.request(data, submitResponse));
    }
  };

  return (
    <div className="login-form-wrapper">
      <p className="head-text">Être contacté pour ouvrir un compte</p>

      <form
        className="login-form"
        onSubmit={handleSubmit(submitForm)}
        autoComplete="off"
      >
        <div className="form-field">
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Société"
                value={field.value}
                onChange={field.onChange}
                type="text"
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth={true}
              />
            )}
          />
        </div>
        <div className="inline-form-fields">
          <div className="form-field">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Prénom"
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  fullWidth={true}
                />
              )}
            />
          </div>
          <div className="form-field">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Nom"
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  fullWidth={true}
                  required
                />
              )}
            />
          </div>
        </div>
        <div className="form-field">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Email"
                value={field.value}
                onChange={field.onChange}
                type="email"
                variant="outlined"
                autoComplete="off"
                size="small"
                fullWidth={true}
                required
              />
            )}
          />
        </div>
        <div className="form-field">
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Adresse complète"
                value={field.value}
                onChange={field.onChange}
                type="text"
                variant="outlined"
                autoComplete="off"
                size="small"
                fullWidth={true}
              />
            )}
          />
        </div>
        <div className="inline-form-fields">
          <div className="form-field">
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Code Postal"
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  fullWidth={true}
                />
              )}
            />
          </div>
          <div className="form-field">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Ville"
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                />
              )}
            />
          </div>
        </div>

        <div className="form-field">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Téléphone"
                value={field.value}
                onChange={field.onChange}
                type="text"
                variant="outlined"
                autoComplete="off"
                size="small"
                fullWidth={true}
                required
              />
            )}
          />
        </div>
        {/* </div> */}
        <div className="houses-img-wrapper">
          <img src={Houses} alt="img" />
        </div>
        <button type="submit" className="submit-btn">
          Envoyer ma demande
        </button>
      </form>
    </div>
  );
}
