import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { memo } from 'react';
import { Controller, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import styles from '../advancedPlotSearchContainer.module.scss';
import { radiobuttonsPlotType } from '../formSchema';
import DrawCheckbox from './DrawCheckbox';
import PlotCalculationInputGroup from './PlotCalculationInputGroup';

interface IPlotCalculationProps {
  control: any;
  setValue: UseFormSetValue<AdvancedPlotSearchForm>;
  trigger: UseFormTrigger<AdvancedPlotSearchForm>;
}

const PlotCalculation = ({
  control,
  trigger,
  setValue,
}: IPlotCalculationProps): React.ReactElement => {
  return (
    <div className={styles.plotCalculationContainer}>
      <div className={styles.plotCalculationContent}>
        <DrawCheckbox control={control} setValue={setValue} />

        <div className={styles.radioContainer}>
          <Controller
            name="plotType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} row value={value}>
                {radiobuttonsPlotType.map((elmt) => (
                  <FormControlLabel
                    className={styles.radioLabel}
                    key={elmt.value}
                    value={elmt.value}
                    control={<Radio />}
                    label={elmt.label}
                    labelPlacement="end"
                  />
                ))}
              </RadioGroup>
            )}
          />
        </div>

        <PlotCalculationInputGroup
          setValue={setValue}
          control={control}
          trigger={trigger}
          min={'minPlot'}
          max={'maxPlot'}
          placeHolder="Surface en m²"
        />

        <PlotCalculationInputGroup
          setValue={setValue}
          control={control}
          trigger={trigger}
          min={'minGround'}
          max={'maxGround'}
          placeHolder="Surface en m²"
        />
      </div>
    </div>
  );
};

export default memo(PlotCalculation);
