import React from 'react';

interface IFiscalZoneProps {
  zone: nullable<IFiscalLaw>;
}
export default function FiscalZone({ zone }: IFiscalZoneProps) {
  return (
    <div className="fiscal-zone">
      <p>
        Zone <span>{zone ? zone.zone : 'indéfinie'}</span>.
      </p>
    </div>
  );
}
