import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../../../App/store';
import { getMapState } from '../../../../../features/map/mapSlice';
import { getPlotState } from '../../../../../redux/plot/reducer';
import StudyContactCard from './StudyContactCard';

interface IStudyContactList {
  studyContactList: StudyContacts | null;
  onSelect: (contact: IStudyContact) => void;
  updateAllowed: boolean;
}

function StudyContactList({
  studyContactList,
  onSelect,
  updateAllowed,
}: IStudyContactList) {
  const { parcelle } = useAppSelector(getPlotState);
  const { geolocDatas } = useAppSelector(getMapState);

  return (
    <div className="contact-list">
      {studyContactList?.map((c) => (
        <StudyContactCard
          key={nanoid()}
          studyContact={c}
          parcelle={parcelle ?? null}
          address={geolocDatas?.address ?? null}
          city={geolocDatas?.city ?? null}
          onSelect={onSelect}
          updateAllowed={updateAllowed}
        />
      ))}
    </div>
  );
}

export default StudyContactList;
