import { usePDF } from '@react-pdf/renderer';
import { delay, isEmpty } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';

import { useHistory } from 'react-router';
import checkErrialPdfAllow from '../../auth/utils/checkErrialPdfAllow';
import { getCompanyState } from '../../company/companySlice';
import ParcelleInfoPDF from '../PDFgenerate/ParcelleInfoPDF';
import { generatePDFActions, getGeneratePDFState } from '../generatePDFSlice';
import RedDownloadButton from '../redDownloadButton';
import { fetchSinglePDFPlotThunk } from '../services/thunks/generatePDFThunks';

const PDFButton = () => {
  const [click, setClick] = useState(false);
  const link = useRef<HTMLAnchorElement>(null);
  const { data } = useAppSelector(getGeneratePDFState);
  const { company } = useAppSelector(getCompanyState);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [instance, updateInstance] = usePDF({
    document: <ParcelleInfoPDF datas={data.result} />,
  });

  useEffect(() => {
    if (data.apiStatus === APIStatus.REJECTED) {
      setClick(false);
    } else if (!isEmpty(data.result) && click) {
      updateInstance();
      delay(() => {
        link.current?.click();
        setClick(false);
        dispatch(generatePDFActions.reset());
      }, 500);
    }
  }, [data]);

  const handleClick = () => {
    if (checkErrialPdfAllow(company.result?.firm ?? '')) {
      history.push('/external_pdf_errial');
    } else {
      if (!click) {
        dispatch(fetchSinglePDFPlotThunk());
      }
      setClick(true);
    }
  };

  const getFileName = () => data.result?.plotId ?? 'parcelle';

  return (
    <>
      <RedDownloadButton handleClick={handleClick} alt="download data button">
        {data.apiStatus === APIStatus.REJECTED
          ? 'Recommencer'
          : click
          ? 'Génération...'
          : 'PDF synthétique'}
      </RedDownloadButton>

      <a
        ref={link}
        hidden={true}
        href={instance.url ?? undefined}
        download={`${getFileName()}.pdf`}
      >
        link
      </a>
    </>
  );
};

export default memo(PDFButton);
