import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { StudyMatrixFormValues } from '../components/studyMatrix/MatrixContainer';

export const valuesCalculator = (
  setValue: UseFormSetValue<StudyMatrixFormValues>,
  getValues: UseFormGetValues<StudyMatrixFormValues>
) => {
  const calcBuildingFootprint = (
    areaField: number,
    builtFootprint: number
  ): number => {
    if (areaField >= 0 && builtFootprint >= 0) {
      return parseFloat(((areaField * builtFootprint) / 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const calcFreeBuiltAreaMin = (
    areaField: number,
    builtFreeOnGround: number
  ): number => {
    if (areaField >= 0 && builtFreeOnGround >= 0) {
      return parseFloat(((areaField * builtFreeOnGround) / 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const calcFloorAreaMax = (buildingFootprint: number, floors: number): number => {
    if (buildingFootprint >= 0 && floors >= 0) {
      return Math.round(parseFloat((buildingFootprint * floors).toFixed(2)));
    } else {
      return 0;
    }
  };

  const calcHousingAreaMax = (floorAreaMax: number, spShab: number): number => {
    if (floorAreaMax >= 0 && spShab >= 0) {
      return parseFloat(((floorAreaMax * spShab) / 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const calcSocialHousingArea = (
    housingAreaMax: number,
    socialHousing: number
  ): number => {
    if (housingAreaMax >= 0 && socialHousing >= 0) {
      return parseFloat(((housingAreaMax * socialHousing) / 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const calcHousingQty = (floorAreaMax: number, avgAreaHousing: number): number => {
    if (floorAreaMax >= 0 && avgAreaHousing > 0) {
      const value = parseFloat((floorAreaMax / avgAreaHousing).toFixed(2));
      return isNaN(value) ? 0 : value;
    } else {
      return 0;
    }
  };

  const calcSocialHousingQty = (
    housingQty: number,
    socialHousing: number
  ): number => {
    if (housingQty >= 0 && socialHousing >= 0) {
      return parseFloat(((housingQty * socialHousing) / 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const calcEstimatedParkingArea = (parkingQty: number): number => {
    if (parkingQty) {
      return parseFloat((parkingQty * 20).toFixed(0));
    } else {
      return 0;
    }
  };

  const calcEquivalentUnderGroundArea = (
    estimatedParkingArea: number,
    buildingFootprint: number
  ): number => {
    if (estimatedParkingArea >= 0 && buildingFootprint > 0) {
      const value = Math.ceil(estimatedParkingArea / buildingFootprint);
      return isNaN(value) ? 0 : value;
    } else {
      return 0;
    }
  };

  // ******************
  // launch calculs
  // ******************

  // Surface terrain * Emprise bâti
  // => areaField * builtFootprint
  setValue(
    'buildingFootprint',
    calcBuildingFootprint(getValues().areaField, getValues().builtFootprint)
  );

  // Surface terrain * Emprise libre au sol
  // => areaField * builtFreeOnGround
  setValue(
    'freeBuiltAreaMin',
    calcFreeBuiltAreaMin(getValues().areaField, getValues().builtFreeOnGround)
  );

  // Surface terrain * Emprise bâti * Nombre d'étages
  // => buildingFootprint * floors
  setValue(
    'floorAreaMax',
    calcFloorAreaMax(getValues().buildingFootprint, getValues().floors)
  );

  // Surface Plancher Max * Ratio SP/SHAB
  // => floorAreaMax * spShab
  setValue(
    'housingAreaMax',
    calcHousingAreaMax(getValues().floorAreaMax, getValues().spShab)
  );

  // Surface Habitable Max * logement social
  // => housingAreaMax * socialHousing
  setValue(
    'socialHousingAreaMax',
    calcSocialHousingArea(getValues().housingAreaMax, getValues().socialHousing)
  );

  // (Surface terrain * Emprise bâti * Nombre d'étages )/ surface moyenne logement
  // => floorAreaMax / avgAreaHousing
  setValue(
    'housingQty',
    calcHousingQty(getValues().floorAreaMax, getValues().avgAreaHousing)
  );

  // Nombre de logements * logement social
  // => housingQty / socialHousing
  setValue(
    'socialHousingQty',
    calcSocialHousingQty(getValues().housingQty, getValues().socialHousing)
  );

  // Nombre de stationnements * 20
  // => parkingQty * 20
  setValue('estimatedParkingArea', calcEstimatedParkingArea(getValues().parkingQty));

  // surface estimées stationnements + circulation / (surface terrain * Emprise bâti)
  // --> always equal to the greater integer; for example, if its "0,33" --W then it should show "1"
  // ceil(estimatedParkingArea / buildingFootprint)
  setValue(
    'equivalentUnderGroundArea',
    calcEquivalentUnderGroundArea(
      getValues().estimatedParkingArea,
      getValues().buildingFootprint
    )
  );
};
