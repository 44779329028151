import { delay } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { getAuthState } from '../authSlice';
import { fetchAuthUserThunk } from '../authThunks';
import AuthError from './AuthError';
import {
  CONNEXION_PROCESS_DELAY,
  ConnectionWorkFlowEnum,
} from './ConnectionProcessContent';
import styles from './connectionProcess.module.scss';

enum ProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  DATAS_REJECTED,
  END_PROCESS,
  ERROR_TOKEN,
  ERROR_INACTIVE_USER,
  ERROR_NOT_FOUND,
  ERROR_NOT_AUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_SERVER,
}
// add if user don't have company
interface IUserProcessProps {
  decodedToken: DecodedToken | null;
  updateProcessStep: (value: ConnectionWorkFlowEnum) => void;
  onErrorClick: (value?: string) => void;
}

const displayLoader = (step: ProcessStepEnum) => {
  let text = '';
  switch (step) {
    case ProcessStepEnum.PROCESS_INIT:
      text = "Initialisation de l'utilisateur";
      break;
    case ProcessStepEnum.DATAS_PENDING:
      text = "Chargement de l'utilisateur";
      break;
    default:
      break;
  }

  return (
    <>
      {text !== '' && (
        <>
          <h3>{text}</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
    </>
  );
};

function UserProcess({
  decodedToken,
  updateProcessStep,
  onErrorClick,
}: IUserProcessProps) {
  const { apiStatus, user, error } = useAppSelector(getAuthState);
  const [internalStep, setInternalStep] = useState<ProcessStepEnum>(
    ProcessStepEnum.IDLE
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      setInternalStep(ProcessStepEnum.DATAS_FULFILLED);
    } else {
      switch (apiStatus) {
        case APIStatus.PENDING:
          setInternalStep(ProcessStepEnum.DATAS_PENDING);
          break;
        case APIStatus.REJECTED:
          setInternalStep(ProcessStepEnum.DATAS_REJECTED);
          break;
        default:
          break;
      }
    }
  }, [apiStatus, user]);

  useEffect(() => {
    switch (internalStep) {
      case ProcessStepEnum.IDLE: {
        if (decodedToken) {
          setInternalStep(ProcessStepEnum.PROCESS_INIT);
        } else {
          setInternalStep(ProcessStepEnum.ERROR_TOKEN);
        }
        break;
      }
      case ProcessStepEnum.PROCESS_INIT: {
        delay(() => {
          dispatch(fetchAuthUserThunk({ userIdIri: decodedToken?.userIdIri }));
        }, CONNEXION_PROCESS_DELAY);

        break;
      }

      case ProcessStepEnum.DATAS_PENDING: {
        break;
      }
      case ProcessStepEnum.DATAS_FULFILLED: {
        if (!user?.isActive) {
          setInternalStep(ProcessStepEnum.ERROR_INACTIVE_USER);
        } else {
          setInternalStep(ProcessStepEnum.DATAS_FULFILLED);
          setInternalStep(ProcessStepEnum.END_PROCESS);
        }

        break;
      }
      case ProcessStepEnum.DATAS_REJECTED: {
        switch (error?.status) {
          case 401:
            setInternalStep(ProcessStepEnum.ERROR_NOT_AUTHORIZED);
            break;
          case 403:
            setInternalStep(ProcessStepEnum.ERROR_FORBIDDEN);
            break;
          case 404:
            setInternalStep(ProcessStepEnum.ERROR_NOT_FOUND);
            break;
          default:
            setInternalStep(ProcessStepEnum.ERROR_SERVER);
            break;
        }

        break;
      }
      case ProcessStepEnum.END_PROCESS: {
        updateProcessStep(ConnectionWorkFlowEnum.START_FETCH_ANNOUNCEMENT_PROCESS);
        break;
      }
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div className={styles.userProcess}>
      {displayLoader(internalStep)}
      {(internalStep === ProcessStepEnum.DATAS_FULFILLED ||
        internalStep === ProcessStepEnum.END_PROCESS) && (
        <p className={styles.hiUser}>
          Bonjour <span>{user?.fullName}</span>
        </p>
      )}

      {internalStep === ProcessStepEnum.ERROR_TOKEN && (
        <AuthError
          title="Token de connexion defectueux"
          lines={[
            "L'application va être réinitialisée.",
            'Vous devrez vous reconnecter',
          ]}
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}
      {internalStep === ProcessStepEnum.ERROR_INACTIVE_USER && (
        <AuthError
          title="Utilisateur désactivé"
          lines={[
            'Cet utilisateur est désactivé',
            'Contactez le manager de la companie.',
          ]}
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}

      {internalStep === ProcessStepEnum.ERROR_NOT_FOUND && (
        <AuthError
          title="Utilisateur non trouvé"
          lines={[
            "L'utilisateur n'a pas été trouvé.",
            'Vous devez vous reconnecter.',
            'Si le problème persiste, veuillez contacter urbanease.',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}

      {internalStep === ProcessStepEnum.ERROR_NOT_AUTHORIZED && (
        <AuthError
          title="Accès non autorisé"
          lines={[
            "Vous n'avez pas l'autorisation de charger les données de cet utilisateur.",
            'Ou celui-ci est désactivé.',
            'Pour toutes questions :',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}

      {internalStep === ProcessStepEnum.ERROR_FORBIDDEN && (
        <AuthError
          title="Accès interdit"
          lines={["Vous n'avez pas accès aux données de cet utilisateur."]}
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}

      {internalStep === ProcessStepEnum.ERROR_SERVER && (
        <AuthError
          title="Erreur serveur"
          lines={[
            'Une erreur technique est survenue.',
            'Veuillez contacter urbanease.',
            `status : ${error?.status} message : ${error?.message}.`,
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}
    </div>
  );
}

export default memo(UserProcess);
