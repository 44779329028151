import { StyleSheet, View } from '@react-pdf/renderer';
import UrbanismeInfoBloc from './UrbanismeInfoBloc';

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    color: '#4E55A2',
    backgroundColor: '#fff',
  },
});

interface IUrbanismeInfoBlocContainerProps {
  seismic: IEnvPDFDisplay;
  clay: IEnvPDFDisplay;
  peb: IEnvPDFDisplay;
}
// const UrbanismeInfoBlocContainer = ({ seismic, argile, peb, zones }) => {
const UrbanismeInfoBlocContainer = ({
  peb,
  seismic,
  clay,
}: IUrbanismeInfoBlocContainerProps) => {
  // let env = zones.env;

  // const envs = (cat) => {
  //   const value = env.find((elt) => elt.cat === cat);
  //   return value ? value.libelle : '';
  // };

  return (
    <View style={styles.section} break>
      {/* {envs('Données sismiques') && ( */}
      <UrbanismeInfoBloc text="Niveau de sismicité" data={seismic} />
      {/* )} */}
      <UrbanismeInfoBloc text="Niveau aléa Argile" data={clay} />
      {/* update width bloc when activate 4 blocs */}
      <UrbanismeInfoBloc
        text="Plan d'Exposition au bruit"
        // data={envs("PEB - Plan d'exposition au bruit")}
        data={peb}
        color="red"
      />
      {/* <UrbanismeInfoBloc
        text="PPRI"
        // data={envs("PPRI - Zones inondables")}
        data="Prochainement"
        color="red"
      /> */}
    </View>
  );
};

export default UrbanismeInfoBlocContainer;
