import FormResponseIcon from './FormResponseIcon';

interface ISignupResponseProps {
  failure?: boolean;
  children: React.ReactNode;
}

function SignupResponse({ failure, children }: ISignupResponseProps) {
  return (
    <div className="signup-demand-response">
      <h2>Demande d&#039; inscription</h2>

      <FormResponseIcon failure={failure} />

      {children}
    </div>
  );
}

export default SignupResponse;
