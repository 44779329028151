import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../../App/store';
import CreateIcon from '../../../../assets/images/toolbar/polygon.png';
import XCross from '../../../../assets/images/xicon-white.png';
import MapPluginHelperModal from '../mapPluginHelperModal/MapPluginHelperModal';
import styles from '../mapPluginHelperModal/mapPluginHelperModal.module.scss';
import { getMapPluginsDrawState } from '../mapPluginSlice';
import { DrawActionEnum } from './types/drawActionEnum';

function DrawHelperModal() {
  const { action } = useAppSelector(getMapPluginsDrawState);

  return (
    <MapPluginHelperModal>
      <ul>
        {action === DrawActionEnum.DRAW_SECTOR && (
          <>
            <li>
              Tracer votre sectorisation sur la carte en réalisant des clics
              successifs pour dessiner le contours du périmètre souhaité.
            </li>
            <li>
              Revenez sur votre point initial pour finaliser la forme et nommer votre
              sectorisation.
            </li>
            <li>
              Si vous souhaitez annuler la création d’un secteur, cliquez sur
              <img src={XCross} className={styles.cancelIcon} />
            </li>
          </>
        )}
        {action === DrawActionEnum.EDIT && (
          <>
            <li className={styles.edit}>
              Modifiez votre secteur en déplacant les points modélisés par des carrés
              blancs.
            </li>
            <li className={styles.edit}>
              Clickez sur <FontAwesomeIcon icon={faFloppyDisk} /> pour sauvegarder
              vos modifications.
            </li>
            <li className={styles.edit}>
              Clickez sur
              <img src={XCross} className={styles.cancelIcon} />
              pour stopper le processus et revenir au secteur initial.
            </li>
          </>
        )}
        {(action === DrawActionEnum.DRAW_ADVANCED_SEARCH ||
          action === DrawActionEnum.DRAW_ADVANCED_SEARCH_START) && (
          <>
            <li>
              Cliquez sur le bouton
              <img src={CreateIcon} className={styles.iconBase} />
              pour commencer votre tracé.
            </li>
            <li>
              Revenez sur votre point initial pour finaliser la forme. La
              mémorisation est automatique.
            </li>
            <li>
              Si vous souhaitez annuler la création de votre zone de recherche,
              cliquez sur
              <img src={XCross} className={styles.cancelIcon} />
              ou décochez la case depuis le formulaire de recherche.
            </li>
          </>
        )}
      </ul>
    </MapPluginHelperModal>
  );
}

export default DrawHelperModal;
