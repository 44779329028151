import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { useAppSelector } from '../../App/store';
import { getNationalServitudesState } from './nationalServitudesSlice';

function NationalServitudesLayer() {
  const { servitudesDisplayed } = useAppSelector(getNationalServitudesState);

  return (
    <>
      {servitudesDisplayed.map((servitude) => (
        <WMSTileLayer
          key={nanoid()}
          url="https://wxs-gpu.mongeoportail.ign.fr/externe/i9ytmrb6tgtq5yfek781ntqi/wms/v"
          params={{
            layers: servitude,

            format: 'image/png',
            transparent: true,
            // height: 256,
            // width: 256,
          }}
          zIndex={100}
        />
      ))}
    </>
  );
}

export default memo(NationalServitudesLayer);
