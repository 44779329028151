import { Image, Text, View } from '@react-pdf/renderer';
import HeaderBackground from '../../../../../assets/images/orpiPdf/headerForm.png';
import orpiLogo from '../../../../../assets/images/orpiPdf/orpiLogo.png';
import { pdfStyles } from '../pdfStyle';

interface IHeaderFormProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  fixed?: boolean;
}

function PageHeader(props: IHeaderFormProps) {
  return (
    <View style={pdfStyles.pageHeader} fixed={props.fixed}>
      <Image src={HeaderBackground} style={pdfStyles.pageHeaderBackground} />
      <View style={pdfStyles.pageHeaderText}>
        <View style={pdfStyles.pageHeaderOrpiLogoBloc}>
          <Image src={orpiLogo} style={pdfStyles.pageHeaderOrpiLogo} />
        </View>
        <View style={pdfStyles.pageHeaderInfos}>
          <Text style={pdfStyles.date}>{props.date}</Text>
          <Text>{props.address.addressLine1}</Text>
          <Text>{props.address.addressLine2}</Text>
        </View>
      </View>
    </View>
  );
}

export default PageHeader;
