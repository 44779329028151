import { TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { contactContext } from '../../study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import { fetchContacts } from '../contactRequests';
import styles from './contactSearchModal.module.scss';

function ContactInputSearch() {
  const { companyIdIri } = useAppSelector(getCompanyState);
  const [pattern, setPattern] = useState<string>('');
  const { setContacts } = useContext(contactContext);

  const handleSearchChange = async (e: ChangeEventCustom) => {
    const value = e.target.value;
    setPattern(value);

    if (value.length >= 3) {
      try {
        const list = await fetchContacts({ search: value }, companyIdIri);
        setContacts(list);
      } catch (error: any) {
        console.log('error fetch contact', error.status, error.message);
      }
    }
  };

  return (
    <TextField
      className={styles.patternField}
      placeholder="Rechercher"
      type="text"
      variant="outlined"
      fullWidth={true}
      value={pattern ?? ''}
      onChange={handleSearchChange}
      required
    />
  );
}

export default ContactInputSearch;
