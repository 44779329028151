import * as Yup from 'yup';
import { validationMessages } from '../../../../../constants/validations';

export const initialContactForm: StudyContactForm = {
  lastName: '',
  firstName: '',
  phone: '',
  email: '',
  roleIdIri: '',
  address: '',
  description: '',
};

export const validationSchema = Yup.object({
  lastName: Yup.string().required(validationMessages.contactLastName.requis),
  firstName: Yup.string().required(validationMessages.contactFirstName.requis),
  phone: Yup.string(),
  email: Yup.string().email(validationMessages.email.error),
  roleIdIri: Yup.string().required(validationMessages.contactRole.requis),
  address: Yup.string(),
  description: Yup.string(),
});
