import { useAppDispatch } from '../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import { modalsActions } from '../../modals/modalsSlice';
import ConnectionProcessContent from './ConnectionProcessContent';

function ConnexionProcessModal() {
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(modalsActions.connectionProcess(false));
  };

  return (
    <GenericModal
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      title="Connexion"
      closeModal={handleCloseModal}
      hasIcon={false}
      hasLogo
    >
      <ConnectionProcessContent />
    </GenericModal>
  );
}

export default ConnexionProcessModal;
