import { usePDF } from '@react-pdf/renderer';
import { delay } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getFoldersState } from '../../../features/folders/foldersSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import ParcelleInfoPDF from '../PDFgenerate/ParcelleInfoPDF';
import { getGeneratePDFState } from '../generatePDFSlice';
import RedDownloadButton from '../redDownloadButton';
import { fetchMultiPDFPlotThunk } from '../services/thunks/generatePDFThunks';
import styles from './pdfButtons.module.scss';

const PDFButtonMulti = () => {
  const [click, setClick] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const link = useRef<HTMLAnchorElement>(null);
  const { data, next } = useAppSelector(getGeneratePDFState);
  const { folderRightPanelDatas } = useAppSelector(getFoldersState);

  const plotCount = folderRightPanelDatas.result?.plotCount ?? 0;
  const dispatch = useAppDispatch();

  const [instance, updateInstance] = usePDF({
    document: <ParcelleInfoPDF datas={data.result} />,
  });

  const dataRequest = () => {
    dispatch(
      fetchMultiPDFPlotThunk({
        count,
      })
    );
    setCount(count + 1);
  };

  useEffect(() => {
    if (next) {
      if (folderRightPanelDatas.result?.plotCount && count < plotCount) {
        setCount(count + 1);
        delay(() => {
          dataRequest();
        }, 1);
      }
    }
  }, [next]);

  useEffect(() => {
    if (data.apiStatus === APIStatus.REJECTED && count === plotCount) {
      setClick(false);
    }

    if (data.result && click) {
      updateInstance();

      delay(() => {
        link.current?.click();
        if (count === folderRightPanelDatas.result?.plotCount) {
          setClick(false);
          setCount(0);
        } else {
          dataRequest();
        }
      }, 500);
    }
  }, [data]);

  const handleClick = () => {
    if (!click && folderRightPanelDatas) {
      dataRequest();
    }
    setClick(true);
  };

  const getFileName = () => data.result?.plotId ?? 'parcelle';

  const generatingDisplayMsg = () => {
    return (
      <div className={styles.multiPDFContent}>
        <p>
          Génération {count}/{folderRightPanelDatas.result?.plotCount}
        </p>

        <IndividualCircularLoader size={20} className={styles.loader} />
      </div>
    );
  };

  return (
    <>
      <RedDownloadButton
        handleClick={handleClick}
        alt="download data button"
        disabled={!folderRightPanelDatas.result}
      >
        {click ? generatingDisplayMsg() : 'Télécharger PDF'}
      </RedDownloadButton>

      <a
        ref={link}
        hidden={true}
        href={instance.url ?? undefined}
        download={`${getFileName()}.pdf`}
      >
        link
      </a>
    </>
  );
};

export default memo(PDFButtonMulti);
