import { delay, isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import useQuery from '../../../App/hooks/useQuery';
import { useAppDispatch } from '../../../App/store';
import SessionStorageService from '../../../services/SessionStorageService';
import ChooseCompanyContent from '../../company/ChooseCompanyModal/ChooseCompanyContent';
import { companyActions } from '../../company/companySlice';
import { setAuthenticatedCompanyThunk } from '../../company/companyThunks';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { authActions } from '../authSlice';
import AuthError from './AuthError';
import {
  CONNEXION_PROCESS_DELAY,
  ConnectionWorkFlowEnum,
} from './ConnectionProcessContent';
import styles from './connectionProcess.module.scss';

export enum ProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  COMPANY_CHOICE,
  COMPANY_CHOSEN,
  // DATAS_PENDING,
  // DATAS_FULFILLED,
  END_PROCESS,
  ERROR_TOKEN,
  ERROR_NO_COMPANY,
  ERROR_NO_COMPANY_IN_STORE,
  ERROR_NOT_FOUND,
  ERROR_NOT_AUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_SERVER,
}
interface ICompanyProcessProps {
  decodedToken: DecodedToken | null;
  updateProcessStep: (value: ConnectionWorkFlowEnum) => void;
  onErrorClick: (value?: string) => void;
}

const displayLoader = (step: ProcessStepEnum) => {
  let text = '';
  switch (step) {
    case ProcessStepEnum.PROCESS_INIT:
      text = 'Initialisation de la companie';
      break;
    // case ProcessStepEnum.COMPANY_CHOSEN:
    //   text = 'Parametrage de la companie';
    //   break;
    default:
      break;
  }

  return (
    <>
      {text !== '' && (
        <>
          <h3>{text}</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
    </>
  );
};

function CompanyProcess({
  decodedToken,
  updateProcessStep,
  onErrorClick,
}: ICompanyProcessProps) {
  const { queries } = useQuery();
  const [internalStep, setInternalStep] = useState<ProcessStepEnum>(
    ProcessStepEnum.IDLE
  );
  const [selectedCompany, setSelectedCompany] = useState<TokenCompany | null>(null);
  const dispatch = useAppDispatch();

  // company init process
  const handleCompanyChoice = (company: TokenCompany, step?: ProcessStepEnum) => {
    setSelectedCompany(company);
    dispatch(
      companyActions.companyInit({
        companyId: company.id,
        companyIdIri: company.idIri,
      })
    );
    dispatch(setAuthenticatedCompanyThunk({ companyId: company.id }));
    dispatch(authActions.setIsLogged(true));

    if (step) {
      setInternalStep(step);
    }
  };

  useEffect(() => {
    switch (internalStep) {
      case ProcessStepEnum.IDLE: {
        if (decodedToken) {
          setInternalStep(ProcessStepEnum.PROCESS_INIT);
        } else {
          setInternalStep(ProcessStepEnum.ERROR_TOKEN);
        }
        break;
      }
      case ProcessStepEnum.PROCESS_INIT: {
        const companies = decodedToken?.companies ?? [];
        if (isEmpty(companies)) {
          setInternalStep(ProcessStepEnum.ERROR_NO_COMPANY);
        } else {
          const ssCompanyIdIri = SessionStorageService.getSelectedCompanyIdIri();
          dispatch(companyActions.setCompanies(companies));

          delay(
            () => {
              if (ssCompanyIdIri) {
                const cc = companies.find((f) => f.idIri === ssCompanyIdIri);
                if (cc) {
                  handleCompanyChoice(cc, ProcessStepEnum.COMPANY_CHOSEN);
                } else {
                  SessionStorageService.removeSelectedCompanyIdIri();
                  setInternalStep(ProcessStepEnum.COMPANY_CHOICE);
                }
              } else if (companies.length === 1) {
                handleCompanyChoice(companies[0], ProcessStepEnum.COMPANY_CHOSEN);
              } else {
                setInternalStep(ProcessStepEnum.COMPANY_CHOICE);
              }
            },
            CONNEXION_PROCESS_DELAY,
            decodedToken
          );
        }
        break;
      }
      case ProcessStepEnum.COMPANY_CHOICE: {
        break;
      }
      case ProcessStepEnum.COMPANY_CHOSEN: {
        delay(
          () => {
            setInternalStep(ProcessStepEnum.END_PROCESS);
          },
          CONNEXION_PROCESS_DELAY,
          decodedToken
        );
        break;
      }
      case ProcessStepEnum.END_PROCESS: {
        if (queries.token) {
          updateProcessStep(ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS);
        } else {
          updateProcessStep(ConnectionWorkFlowEnum.CLOSE_CONNECTION_MODAL);
        }
        break;
      }
      default:
        break;
    }
  }, [decodedToken, internalStep]);

  return (
    <div className={styles.companyProcess}>
      {internalStep === ProcessStepEnum.COMPANY_CHOICE && (
        <ChooseCompanyContent
          companies={decodedToken?.companies ?? []}
          onCompanyChoice={handleCompanyChoice}
        />
      )}
      {(internalStep === ProcessStepEnum.COMPANY_CHOSEN ||
        internalStep === ProcessStepEnum.END_PROCESS) && (
        <>
          <p>
            Compagnie <span>{selectedCompany?.name}</span>
          </p>
        </>
      )}

      {internalStep === ProcessStepEnum.ERROR_NO_COMPANY && (
        <AuthError
          title="Aucune compagnie trouvée"
          lines={[
            "l'utilisateur chargé n'appartient à aucune compagnie.",
            'Veuillez contacter urbanease.',
          ]}
          hasSupportLink
          buttonContent="OK"
          onClick={onErrorClick}
        />
      )}

      {displayLoader(internalStep)}
    </div>
  );
}

export default memo(CompanyProcess);
