import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getContactState } from '../../../../redux/contacts/reducer';
import { modalsActions } from '../../../modals/modalsSlice';

import CloseCross from '../../../../components/Modals/components/CloseCross';
import contactActions from '../../../../redux/contacts/action';
import { getCompanyState } from '../../companySlice';
import ListContainer from './ListContainer';
import './ReadMoreModal.scss';

interface IReadMoreModalProps {
  type: string | null;
}

const ReadMoreModal = ({ type }: IReadMoreModalProps) => {
  const dispatch = useAppDispatch();
  const { subscription } = useAppSelector(getCompanyState);
  const { contactForAction } = useAppSelector(getContactState);

  const onCloseModal = () => {
    dispatch(modalsActions.readMore({ status: false, type: null }));
    dispatch(contactActions.contactForAction.reset());
  };

  const titleDisplay = () => {
    switch (type) {
      case 'departments':
        return 'Liste des départements ci-dessous :';
      case 'cities':
        return 'Liste des villes ci-dessous :';
      case 'description':
        return `Infos de ${
          contactForAction?.lastName && contactForAction?.firstName
            ? `${contactForAction?.lastName ?? ''} ${
                contactForAction?.firstName ?? ''
              }`
            : contactForAction?.name
        }:`;
      default:
        return null;
    }
  };

  const contentDisplay = () => {
    switch (type) {
      case 'departments':
        return (
          <ListContainer
            subscriptionIdIri={subscription?.idIri ?? null}
            rows={
              subscription?.departments.result?.map(
                (d) => d.departmentCode + ' - ' + d.name
              ) ?? []
            }
            hasPagination={false}
          />
        );
      case 'cities':
        return (
          <ListContainer
            subscriptionIdIri={subscription?.idIri ?? null}
            totalItems={subscription?.cityCount}
            rows={
              subscription?.cities.result?.map(
                (c) => `${c.name} (${c.postalCode})`
              ) ?? []
            }
            itemPerPageLabel="Communes par page"
            hasPagination={true}
          />
        );

      case 'description':
      default:
        return <p>{contactForAction?.description}</p>;
    }
  };

  return (
    <Dialog
      className="read-more-modal"
      fullWidth={true}
      maxWidth="xs"
      open={Boolean(type)}
      onClose={onCloseModal}
      fullScreen={false}
      scroll="body"
    >
      <DialogContent className="read-more-modal-content">
        <CloseCross onClick={onCloseModal} />

        <div className="title">{titleDisplay()}</div>
        <div className="content-wrapper">{contentDisplay()}</div>
      </DialogContent>
    </Dialog>
  );
};

export default ReadMoreModal;
