import { useState } from 'react';
import Houses from '../../../../assets/images/houses.png';
import DemandFailure from './DemandFailure';
import DemandSuccess from './DemandSuccess';
import SignupForm from './SignupForm';
import SignupResponse from './SignupResponse';

export default function SignupContent(): JSX.Element {
  const [displayed, setDisplayed] = useState<SignupResponse>('hubspot');

  const handleHubspotClick = () => {
    window.open('http://26053065.hs-sites-eu1.com/contact');
  };

  return (
    <div>
      {displayed === 'form' && <SignupForm setDisplayed={setDisplayed} />}
      {displayed === 'success' && (
        <SignupResponse>
          <DemandSuccess />
        </SignupResponse>
      )}
      {displayed === 'failure' && (
        <SignupResponse failure={true}>
          <DemandFailure />
        </SignupResponse>
      )}
      {displayed === 'hubspot' && (
        <div className="hubspot">
          <h2>Pas encore client ?</h2>
          <div>
            <div className="houses-img-wrapper">
              <img src={Houses} alt="img" />
            </div>
            <button
              type="submit"
              className="submit-btn"
              onClick={handleHubspotClick}
            >
              Nous contacter
            </button>
          </div>
          <p>
            Nous aurons le plaisir de vous présenter nos solutions de prospection qui
            ont su convaincre plus de 10000 professionnels du secteur immobilier en
            France.
          </p>
        </div>
      )}
    </div>
  );
}
