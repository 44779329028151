import { useEffect, useState } from 'react';
import UrbaGptForm from './UrbaGptForm';
import styles from './urbaGpt.module.scss';
import UrbaChatBox from './UrbaChatBox';
import UrbaGptTutorial from './UrbaGptTutorial';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getUrbaGptState, urbaGptActions } from './urbaGptSlice';
import { isEmpty } from 'lodash';
import panelsActions from '../../redux/Panels/actions';
import { getPanelState } from '../../redux/Panels/reducer';
import { ReactComponent as Cross } from '../../assets/images/cross.svg';

function UrbaGpt(): JSX.Element {
  const dispatch = useAppDispatch();
  const { conversation, showTutorial, urbaGptIsVisible } = useAppSelector(
    getUrbaGptState
  );
  const { rightPanelDisplay, leftPanelDisplay } = useAppSelector(getPanelState);
  const [open, setOpen] = useState(urbaGptIsVisible);

  const handleUrbaGpt = () => {
    if (!open) {
      if (rightPanelDisplay) dispatch(panelsActions.rightPanel.close());
      if (leftPanelDisplay) dispatch(panelsActions.leftPanel.collapse());
    }
    dispatch(urbaGptActions.setUrbaGptIsVisible(!open));
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(urbaGptActions.setShowTutorial(isEmpty(conversation.result)));
  }, [conversation]);

  useEffect(() => {
    dispatch(urbaGptActions.setConversationIsAnimFalse());
    setOpen(urbaGptIsVisible);
  }, [urbaGptIsVisible]);

  return (
    <div className={styles.urbaGpt}>
      <div
        onClick={() => handleUrbaGpt()}
        className={`${styles.urbaGptBackdrop} ${!open && styles.invisible}`}
      />
      <div
        className={`${styles.urbaGptContainer} ${
          open ? styles.visible : styles.invisible
        }`}
      >
        {open && (
          <>
            <div className={styles.urbaGptHeader}>
              <h4>
                UrbaGPT: l&apos;intelligence artificielle au service de votre
                prospection
              </h4>
              <div onClick={() => handleUrbaGpt()}>
                <Cross />
              </div>
            </div>
            <div className={styles.urbaGptContent}>
              {showTutorial ? (
                <UrbaGptTutorial />
              ) : (
                <UrbaChatBox conversation={conversation.result} />
              )}
              <UrbaGptForm />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UrbaGpt;
