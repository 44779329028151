import L from 'leaflet';
import Trash from '../../../../../assets/images/toolbar/trash.svg';
import Zoom from '../../../../../assets/images/toolbar/zoom.svg';

const popupAction = (action: () => void, content: string, icon: string) => {
  const p = L.DomUtil.create('p', 'area-measure-popup-action');
  const img = L.DomUtil.create('img', 'area-measure-popup-icon');
  img.src = icon;
  p.textContent = content;
  p.insertBefore(img, p.firstChild);
  p.addEventListener('click', action);

  return p;
};

const popupActions = (onCenter: () => void, onDelete: () => void) => {
  const popupActions = L.DomUtil.create('div', 'area-measure-popup-action-section');
  const zAction = popupAction(onCenter, 'Centrer sur cette zone', Zoom);
  const tAction = popupAction(onDelete, 'Supprimer', Trash);

  popupActions.appendChild(zAction);
  popupActions.appendChild(tAction);

  return popupActions;
};

export const popupContentParser = (
  calced: MeasureData,
  pointCount: number,
  onCenter: () => void,
  onDelete: () => void
) => {
  const popupContainer = L.DomUtil.create('div', 'area-measure-popup');

  // popup title
  const title = L.DomUtil.create('h3');

  // result content
  const result = L.DomUtil.create('div', 'area-measure-popup-result');
  const distance = () => {
    const d = L.DomUtil.create('p');
    const l = calced.length.toFixed(0);
    const textContent = pointCount === 2 ? 'Distance: ' : 'Périmètre: ';
    d.textContent =
      textContent + l + ' m (' + (calced.length / 1000).toFixed(3) + ' km)';

    return d;
  };
  if (pointCount === 1) {
    title.innerHTML = 'Placement du point';

    const lc = calced.lastCoord;
    const gps = L.DomUtil.create('p');
    gps.textContent =
      'gps: ' +
      lc.dms.y.replace('&deg;', '°') +
      ' / ' +
      lc.dms.x.replace('&deg;', '°');
    const latLng = L.DomUtil.create('p');
    latLng.textContent =
      'lat/lon: ' + lc.dd.y.toFixed(5) + ' / ' + lc.dd.x.toFixed(5);

    result.appendChild(gps);
    result.appendChild(latLng);
  } else if (pointCount === 2) {
    title.innerHTML = 'Mesure linéaire';
    const p = distance();
    result.appendChild(p);
  } else {
    title.innerHTML = 'Mesure de superficie';
    const area = L.DomUtil.create('p');
    area.textContent =
      'Surface: ' +
      calced.area.toFixed(0) +
      ' m² (' +
      (calced.area / 1000000).toFixed(4) +
      ' km²)';
    const p = distance();

    result.appendChild(area);
    result.appendChild(p);
  }

  // actions
  const actions = popupActions(onCenter, onDelete);

  popupContainer.appendChild(title);
  popupContainer.appendChild(L.DomUtil.create('hr'));
  popupContainer.appendChild(result);
  popupContainer.appendChild(L.DomUtil.create('hr'));
  popupContainer.appendChild(actions);

  return popupContainer;
};
