export function createDashboardEngineParams(criterias: FetchCriterias) {
  let query = '';
  let order = undefined;
  let sortKey = undefined;

  for (const [key, value] of Object.entries(criterias || {})) {
    if (value) {
      switch (key) {
        case 'order':
          order = value;
          break;
        case 'sortKey':
          sortKey = value;
          break;
        case 'fullPlotDatas':
          break;
        case 'plotStudies':
          break;
        default:
          query += `&${key}=${value}`;
      }
    }
  }
  if (sortKey) {
    let value = '';
    switch (sortKey) {
      case 'city':
        value = 'cityName';
        break;

      default:
        value = sortKey.toString();
        break;
    }
    query += `&order[${value}]=${order}`;
  }

  query = query !== '' ? `?${query.substring(1)}` : '';

  return query;
}
