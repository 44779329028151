import AccountMapBtn from '../../../../components/Account/AccountMapBtn';
import styles from './urbaToolbar.module.scss';

function RightToolbar() {
  return (
    <div className={styles.rightToolbar}>
      <AccountMapBtn />
    </div>
  );
}

export default RightToolbar;
