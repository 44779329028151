import { Button } from '@mui/material';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import AutocompleteInputCities from '../company/subscription/AutocompleteInputCities';

import { modalsActions } from '../modals/modalsSlice';
import {
  dashboardOwnerDatasActions,
  getDashboardOwnerDatasState,
  OwnerDatasSearchTypeEnum,
} from './dashboardOwnerDatasSlice';
import {
  fetchOwnerPlotFromSirenThunk,
  fetchOwnersFromInseeCodeThunk,
} from './dashboardOwnerDatasThunk';

import { companyPlotsSortKeys, companySortKeys } from './data';
import FilterSortBlocOwnerData from './FilterSortBlocOwnerData';
import SortComponent from './FilterSortBlocOwnerData/SortComponent';

function DataOwnerSearchEngine() {
  const { city, searchType, ownersFromInseeCode, plotsFromSiren, siren } =
    useAppSelector(getDashboardOwnerDatasState);

  const dispatch = useAppDispatch();

  const handleChangeCity = (city: AutocompleteCity | null) => {
    dispatch(dashboardOwnerDatasActions.setCity(city));
    dispatch(
      fetchOwnersFromInseeCodeThunk({
        insee: city?.inseeCode ?? null,
        page: 1,
        itemsPerPage: 15,
        sort: 'parcels_count_per_city',
        order: 'desc',
      })
    );
  };

  const handleSortChange = (value: SortComponentState) => {
    switch (searchType) {
      case OwnerDatasSearchTypeEnum.CITY:
        dispatch(
          fetchOwnersFromInseeCodeThunk({
            insee: city?.inseeCode ?? null,
            page: ownersFromInseeCode.result?.page ?? null,
            itemsPerPage: ownersFromInseeCode.result?.itemsPerPage ?? null,
            sort: value.sort,
            order: value.order,
          })
        );
        break;
      case OwnerDatasSearchTypeEnum.PERSON:
        dispatch(
          fetchOwnerPlotFromSirenThunk({
            siren: siren ?? null,
            page: plotsFromSiren.result?.page ?? null,
            itemsPerPage: plotsFromSiren.result?.itemsPerPage ?? null,
            sort: value.sort,
            order: value.order,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleCompanySearchClick = () => {
    dispatch(modalsActions.companySearch(true));
  };

  const handleSearchModeClick = (value: OwnerDatasSearchTypeEnum): void => {
    dispatch(dashboardOwnerDatasActions.setSearchType(value));
  };

  return (
    <div className="search-engine">
      <div className="search-mode">
        <p>Je cherche par</p>
        <Button
          className={searchType === OwnerDatasSearchTypeEnum.CITY ? 'selected' : ''}
          onClick={() => handleSearchModeClick(OwnerDatasSearchTypeEnum.CITY)}
        >
          Commune
        </Button>
        <Button
          className={
            searchType === OwnerDatasSearchTypeEnum.PERSON ? 'selected' : ''
          }
          onClick={() => handleSearchModeClick(OwnerDatasSearchTypeEnum.PERSON)}
        >
          Personne morale
        </Button>
      </div>

      {searchType === OwnerDatasSearchTypeEnum.PERSON && (
        <>
          <Button
            onClick={handleCompanySearchClick}
            className="search-by-name-button"
          >
            Rechercher une personne morale
          </Button>
          <div>
            <FilterSortBlocOwnerData
              inputPlaceHolder="SIREN"
              selectItems={companyPlotsSortKeys}
              selectPlaceHolder="Trier parcelles par"
              sortValue={plotsFromSiren.result?.sort ?? null}
              orderValue={plotsFromSiren.result?.order ?? null}
              onSortChange={handleSortChange}
            />
          </div>
        </>
      )}

      {searchType === OwnerDatasSearchTypeEnum.CITY && (
        <div className="commune-engine-componants">
          <AutocompleteInputCities
            className="autocomplete-city"
            classNameInput="autocomplete-city-input"
            selectedCity={city}
            onChange={handleChangeCity}
          />
          <SortComponent
            orderValue={ownersFromInseeCode.result?.order ?? null}
            sortValue={ownersFromInseeCode.result?.sort ?? null}
            sortItems={companySortKeys}
            placeHolder="Trier propriétaires par"
            onSortChange={handleSortChange}
          />
        </div>
      )}
    </div>
  );
}

export default memo(DataOwnerSearchEngine);
