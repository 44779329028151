import { Checkbox, FormControlLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../../features/displayManager/displayManagerSlice';
import { plotStudiesToEntitiesDisplay } from '../../../../features/displayManager/utils/entitiesDisplayParsers';
import {
  getStudyState,
  studyActions,
} from '../../../../features/study/slices/studySlice';

export default function FolderHeader() {
  const { study, studyPlotStudies, studyPlotStudiesDisplay } =
    useAppSelector(getStudyState);
  const { entities, entitiesBackup } = useAppSelector(getDisplayManagerState);

  const dispatch = useAppDispatch();
  const hasNoPlots = !studyPlotStudies.result || isEmpty(studyPlotStudies.result);

  const handlePlotDisplay = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.checked;
    if (value) {
      if (studyPlotStudies.result && study.result) {
        const currentStudy = study.result as IFolderStudy;
        const entities = plotStudiesToEntitiesDisplay(
          studyPlotStudies.result,
          currentStudy.markerColor
        );
        dispatch(displayManagerActions.entitiesBackupAndReplace(entities));
      }
    } else {
      dispatch(displayManagerActions.backupReplace());
    }
    dispatch(studyActions.studyPlotStudiesDisplay(value));
  };

  return (
    <div className="header-central-bloc-plot">
      <p className="title">{study.result && (study.result as IFolderStudy).name}</p>

      <FormControlLabel
        value="Afficher les parcelles sur la carte"
        control={
          <Checkbox
            className="display-plot"
            onChange={handlePlotDisplay}
            checked={studyPlotStudiesDisplay}
            disabled={hasNoPlots}
          />
        }
        label={
          hasNoPlots
            ? 'Aucune parcelle à afficher dans ce dossier (dossier vide)'
            : !isEmpty(entities.result) &&
              (!studyPlotStudiesDisplay ||
                (studyPlotStudiesDisplay && !isEmpty(entitiesBackup)))
            ? 'Afficher que les parcelles du sous dossier sur la carte'
            : 'Afficher les parcelles sur la carte'
        }
        labelPlacement="end"
      />
    </div>
  );
}
