import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100px',
    fontWeight: 700,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
}));

const AddButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: MouseEventHandler;
}) => {
  const { classes } = useStyles();
  return (
    <Button className={classes.root} onClick={onClick}>
      {children}
    </Button>
  );
};

AddButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default AddButton;
