import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { userPasswordUpdateThunk } from '../../../features/users/usersThunks';
import TextFieldFormCustom from '../../Common/FormComponents/TextFieldFormCustom';
// Import style
import './PasswordTab.scss';
import { initialForm, validationSchema } from './passwordTabValidation';

export default function PasswordTab(): JSX.Element {
  const dispatch = useAppDispatch();

  const { userIdIri } = useAppSelector(getAuthState);
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: initialForm,
    resolver: yupResolver(validationSchema),
  });

  const handleFormStateSubmit = () => {
    if (isEmpty(errors) && userIdIri) {
      const body: Partial<User> = {
        plainPassword: getValues('verifyPassword'),
      };
      dispatch(userPasswordUpdateThunk({ userIdIri, user: body }));
      reset();
      dispatch(modalsActions.passwordChangeResult(true));
    }
  };
  return (
    <div className="tab-password">
      <div className="tab-inner">
        <h1>Modifier mon mot de passe</h1>
      </div>
      <div className="tab-body">
        <form onSubmit={handleSubmit(handleFormStateSubmit)}>
          <div className="field">
            <TextFieldFormCustom
              type="password"
              control={control}
              name="password"
              label="Nouveau mot de passe"
              required
              error={Boolean(errors.password)}
              errorMsg={errors.password?.message as string}
            />
          </div>
          <div className="field">
            <TextFieldFormCustom
              type="password"
              control={control}
              name="verifyPassword"
              label="Retapez le nouveau mot de passe"
              required
              error={Boolean(errors.verifyPassword)}
              errorMsg={errors.verifyPassword?.message as string}
            />
          </div>
          <button type="submit">VALIDER</button>
        </form>
      </div>
    </div>
  );
}
