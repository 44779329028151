import { makeStyles } from 'tss-react/mui';
import {
  prospectionIconDangerHovered,
  prospectionIconPrimaryHovered,
  white,
} from '../../theme/muiTheme';

export function useStylesFunc({ isPrimary }: { isPrimary: boolean }) {
  const useStyles = makeStyles()({
    tooltip: {
      background: isPrimary
        ? prospectionIconPrimaryHovered
        : prospectionIconDangerHovered,
      radius: '4px',
      padding: '4px 8px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
      color: white,
    },
    arrow: {
      '&::before': {
        backgroundColor: '#2B2F59',
      },
    },
  });

  return useStyles;
}
