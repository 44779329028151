import SelectFormCustom from '../../../../../components/Common/FormComponents/SelectFormCustom';
import useUsers from '../../../../users/useUsers';

interface IContactSelectProps {
  name: string;
  control: any;
  className?: string;
  label?: nullable<string>;
  disabled?: boolean;

  error?: boolean;
  errorMsg?: string;
}

export default function UserSelect({
  control,
  name,
  className,
  label,
  disabled,
  error,
  errorMsg,
}: IContactSelectProps) {
  const { usersForSelect } = useUsers({});
  return (
    <SelectFormCustom
      control={control}
      name={name}
      className={className}
      displayEmpty
      label={label}
      noValue="Choisir un responsable"
      items={usersForSelect()}
      error={error}
      errorMsg={errorMsg}
      disabled={disabled}
    />
  );
}
