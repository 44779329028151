import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './App/store';

import './theme/base.scss';
import muiTheme from './theme/muiTheme';

const app = document.getElementById('root');

// create a root
const root = createRoot(app as HTMLElement);

//render app to root
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </BrowserRouter>
);
