import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import useSector from '../../../../App/hooks/useSector';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import SectionHeader from '../../../../components/LeftPanelModal/MenuTabs/Prospection/sectionHeader';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';

import { getAuthState } from '../../../auth/authSlice';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../displayManager/displayManagerSlice';
import { sectorsToEntitiesDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';
import { DrawActionEnum } from '../../../map/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsDrawState,
  mapPluginsActions,
} from '../../../map/plugins/mapPluginSlice';
import Sector from './Sector';
import styles from './sectorContainer.module.scss';

const SectorContainer = () => {
  const { allSectorsDisplayed } = useAppSelector(getDisplayManagerState);
  const { action } = useAppSelector(getMapPluginsDrawState);
  const { userIdIri } = useAppSelector(getAuthState);
  const { sectors } = useSector(true);
  const dispatch = useAppDispatch();

  const handleDisplayAllSectors = (e: ChangeEvent<HTMLInputElement>) => {
    if (sectors) {
      if (e.target.checked) {
        dispatch(
          displayManagerActions.allSectorsAdd(
            sectorsToEntitiesDisplay(sectors, userIdIri)
          )
        );
      } else {
        dispatch(displayManagerActions.entitiesRemoveByType('sector'));
      }
    }
  };

  const handleCreateSector = (): void => {
    if (!action) {
      dispatch(mapPluginsActions.drawStart(DrawActionEnum.DRAW_SECTOR));
    }
  };

  return (
    <div className={styles.sectorSection}>
      <SectionHeader
        checkAll={allSectorsDisplayed}
        handleCheckAllChange={handleDisplayAllSectors}
        handleAdd={handleCreateSector}
        type="secteur"
      />

      <div className={styles.sectorContainer}>
        {!sectors ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(sectors) ? (
          <p>Aucun secteur</p>
        ) : (
          sectors.map((sector) => (
            <Sector key={nanoid()} sector={sector} isSubsector={false} />
          ))
        )}
      </div>
    </div>
  );
};

export default SectorContainer;
