import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../App/store';
import { getMapPluginsMeasureState } from '../../mapPluginSlice';

function SurfaceDrawSectionText() {
  const [lastData, setLastData] = useState<MeasureData | null>(null);
  const { datas } = useAppSelector(getMapPluginsMeasureState);

  useEffect(() => {
    if (datas.length > 0) {
      setLastData(datas[datas.length - 1]);
    }
  }, [datas]);

  const count = datas.length;

  const LastDistance = () => {
    if (!lastData) {
      return <p>Distance : 0 m</p>;
    }
    if (lastData && count > 1) {
      if (lastData.length < 10000) {
        return (
          <p>
            Distance: {Math.round(lastData.length)} m (
            {(lastData.length / 1000).toFixed(3)} km)
          </p>
        );
      } else {
        return <p>Distance: {(lastData.length / 1000).toFixed(3)} km</p>;
      }
    } else {
      return null;
    }
  };

  const Area = () => {
    if (!lastData || datas.length < 3) {
      return null;
    }
    if (lastData && datas.length >= 3) {
      return (
        <p>
          Superficie: {Math.round(lastData.area)} m² (
          {(lastData.area / 1000000).toFixed(4)} km²)
        </p>
      );
    } else {
      return null;
    }
  };

  if (count === 0) {
    return (
      <p>
        Débuter la création d&#39;une nouvelle mesure en ajoutant des points sur la
        carte
      </p>
    );
  }

  return (
    <>
      <LastDistance />
      <Area />
    </>
  );
}

export default SurfaceDrawSectionText;
