import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import CreateToolbarBtn from '../../../../map/plugins/urbaToolbar/CreateToolbarBtn';
import styles from '../../../../map/plugins/urbaToolbar/urbaToolbar.module.scss';
import { modalsActions } from '../../../../modals/modalsSlice';
import { getMultiPlotSelectState } from '../../multiPlotSelectSlice';

function AddPlotsToolbarBtn() {
  const { isMultiPlotSelector, plotCount, plots } = useAppSelector(
    getMultiPlotSelectState
  );
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (plotCount > 0) {
      dispatch(
        modalsActions.multiPlotAdd({
          status: true,
          plots: plots.result as ICreatePlotStudies,
        })
      );
    }
  };

  const Tooltip = () => (
    <p className={styles.btnTooltip}>Creation multiple d&#39;études</p>
  );

  return (
    <>
      {isMultiPlotSelector && (
        <CreateToolbarBtn
          icon="SAVE_MULTIPLOT"
          onClick={handleClick}
          isPrimaryBg
          isMultiplotSave
          hasNotification
          tooltip={<Tooltip />}
          notification={plotCount >= 100 ? '99+' : plotCount}
        />
      )}
    </>
  );
}

export default AddPlotsToolbarBtn;
