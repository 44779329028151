import * as Yup from 'yup';
import { StudyMatrixFormValues } from '.';
export const matrixInitialValues: StudyMatrixFormValues = {
  areaField: 0,
  builtFootprint: 0,
  builtFreeOnGround: 0,
  floors: 0,
  avgAreaHousing: 0,
  socialHousing: 0,
  spShab: 0,
  buildingFootprint: 0,
  freeBuiltAreaMin: 0,
  floorAreaMax: 0,
  housingAreaMax: 0,
  socialHousingAreaMax: 0,
  housingQty: 0,
  socialHousingQty: 0,
  parkingQty: 0,
  estimatedParkingArea: 0,
  equivalentUnderGroundArea: 0,
};

export const matriceValidationSchema = Yup.object({
  areaField: Yup.number(),
  builtFootprint: Yup.number(),
  builtFreeOnGround: Yup.number(),
  floors: Yup.number(),
  avgAreaHousing: Yup.number(),
  socialHousing: Yup.number(),
  spShab: Yup.number(),
  buildingFootprint: Yup.number(),
  freeBuiltAreaMin: Yup.number(),
  floorAreaMax: Yup.number(),
  housingAreaMax: Yup.number(),
  socialHousingArea: Yup.number(),
  housingQty: Yup.number(),
  socialHousingQty: Yup.number(),
  parkingQty: Yup.number(),
  estimatedParkingArea: Yup.number(),
  equivalentUnderGroundArea: Yup.number(),
});
