import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../App/store';
import { updateStudyThunk } from '../../../../../../features/study/services/thunks/studyThunks';
import '../../prospection.scss';

interface ISectorMailBoxesContentProps {
  study: ISectorStudy;
  updateAllowed: boolean;
}
export default function SectorMailBoxesContent({
  study,
  updateAllowed,
}: ISectorMailBoxesContentProps) {
  const [mailBoxQtyValue, setMailBoxQtyValue] = useState<number>(
    study?.mailBoxes ?? 0
  );

  useEffect(() => {
    if (study) {
      setMailBoxQtyValue(study.mailBoxes ?? 0);
    }
  }, [study]);

  const dispatch = useAppDispatch();

  const handleUpdate = (): void => {
    dispatch(updateStudyThunk({ body: { ...study, mailBoxes: mailBoxQtyValue } }));
  };

  return (
    <div className="mailbox-content">
      <TextField
        type="number"
        name="mailBoxQty"
        className="mailbox-qty"
        value={mailBoxQtyValue}
        variant="outlined"
        onChange={(e) => setMailBoxQtyValue(parseFloat(e.target.value))}
        disabled={!updateAllowed}
      />
      <Button
        type="button"
        className="drawer-button"
        onClick={handleUpdate}
        disabled={!updateAllowed}
      >
        Enregistrer
      </Button>
    </div>
  );
}
