import { TextField } from '@mui/material';
import {
  Controller,
  UseFormSetValue,
  useFormState,
  UseFormTrigger,
} from 'react-hook-form';
import styles from '../advancedPlotSearchContainer.module.scss';
import TooltipCustom from '../TooltipCustom';

interface IInputGroupElementProps {
  control: any;
  name: keyof AdvancedPlotSearchForm;
  placeHolder?: string;
  errorName: AdvancedSearchFieldToTrigger;
  setValue: UseFormSetValue<AdvancedPlotSearchForm>;
  trigger: UseFormTrigger<AdvancedPlotSearchForm>;
  keysToTrigger: AdvancedSearchFieldsToTrigger;
}

function InputGroupElement({
  control,
  name,
  errorName,
  placeHolder,
  trigger,
  keysToTrigger,
}: IInputGroupElementProps): React.ReactElement {
  const { errors } = useFormState({ control });
  const maxError = errors[errorName];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TooltipCustom error={maxError}>
          <TextField
            value={value ?? ''}
            className={`${styles.inputField} ${maxError ? styles.hasError : ''}`}
            variant="outlined"
            onChange={onChange}
            onFocus={(e) => e.target.select()}
            onBlur={async () => await trigger(keysToTrigger)}
            placeholder={placeHolder}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            error={Boolean(maxError)}
          />
        </TooltipCustom>
      )}
    />
  );
}

export default InputGroupElement;
