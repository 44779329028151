export const userPlotsHeaderCell = [
  { title: 'Commune' },
  { title: 'Parcelle' },
  { title: 'Dossier' },
  { title: 'Zonage PLU' },
  { title: 'Surface parcelle' },
  { title: 'Statut de l’opportunité' },
];

export const userPlotTableSortItems = [
  { value: 'city', display: 'Commune' },
  { value: 'plotId', display: 'Parcelle' },
  { value: 'folderName', display: 'Dossier' },
  { value: 'zone', display: 'Zonage PLU' },
  { value: 'area', display: 'Surface parcelle' },
  { value: 'status', display: 'Statut de l’opportunité' },
];
