import { useEffect } from 'react';
import useQuery from '../App/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../App/store';
import useApp from '../App/useApp';
import ButtonsOnMap from '../components/ButtonsOnMap';
import LeftPanelModal from '../components/LeftPanelModal';
import MapComponent from '../components/Map/Map';
import { getAuthState } from '../features/auth/authSlice';
import useCompany from '../features/company/useCompany';
import FavAndHistoric from '../features/favoritePlaces/favAndHistoric';
import { mapActions } from '../features/map/mapSlice';
import RightToolbar from '../features/map/plugins/urbaToolbar/RightToolbar';
import UrbaToolbar from '../features/map/plugins/urbaToolbar/UrbaToolbar';
import AuthModalContainer from '../features/modals/AuthModalContainer';
import './styles/home.scss';

const HomePage = (): JSX.Element => {
  const { queries } = useQuery();
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector(getAuthState);
  useApp();
  useCompany({ loadCompany: true });

  useEffect(() => {
    if (isLogged && queries.lat && queries.lon) {
      // if gps position in query string
      dispatch(
        mapActions.viewSet({
          zoom: 17,
          mapCenter: [parseFloat(queries.lat), parseFloat(queries.lon)],
        })
      );
    }
  }, [isLogged]);

  return (
    <div className="home-container">
      <LeftPanelModal />
      <FavAndHistoric />
      <ButtonsOnMap />
      <MapComponent />
      <UrbaToolbar />
      <RightToolbar />
      <AuthModalContainer />
    </div>
  );
};

export default HomePage;
