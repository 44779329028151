import { useAppSelector } from '../../App/store';
import Alert from '../../components/Modals/Alert';
import AnnouncementModal from '../../components/Modals/AnnouncementModal/AnnouncementModal';
import DeleteStudyModal from '../../components/Modals/DeleteModal/DeleteStudyModal';
import RecursiveFolderStatusModal from '../../components/Modals/RecursiveSubFolderModal';
import AdvancedSearchModal from '../advancedPlotSearch/advancedPlotSearchModal';
import ConnexionProcessModal from '../auth/connectionProcess/ConnexionProcessModal';
import ChooseCompanyModal from '../company/ChooseCompanyModal';
import ReadMoreModal from '../company/subscription/ReadMoreModal/ReadMoreModal';
import SearchCompanyNameModal from '../dashboardOwnerDatas/searchCompanyNameModal';
import EditFolderModal from '../folders/EditFolderModal';
import DeleteFolderModal from '../folders/components/DeleteFolderModal';
import AltimetryHelperModal from '../map/plugins/altimetryPlugin/AltimetryHelperModal';
import DrawHelperModal from '../map/plugins/drawPlugin/DrawHelperModal';
import MeasureHelperModal from '../map/plugins/measurePlugin/components/MeasureHelperModal';
import MultiselectPlotModal from '../plot/multiPlotSelectFeature/multiselectPlotModal';
import AskPLUModal from '../plu/askPluModal';
import DeleteProcedureModal from '../procedures/DeleteProcedureModal';
import DeleteSectorModal from '../sectors/components/DeleteSectorModal';
import EditSectorModal from '../sectors/components/editSectorModal/EditSectorModal';
import DeleteUrabGptModal from '../urbaGpt/DeleteUrabGptModal/DeleteUrabGptModal';
import UrbaGpt from '../urbaGpt/UrbaGpt';
import { getModalsState } from './modalsSlice';

export interface IModalProps {
  open: boolean;
  value?: string;
  callback?: nullable<(sien: string) => void>;
}

const ModalContainer = (): JSX.Element => {
  const {
    sectorEdit,
    sectorDelete,
    folderDelete,
    folderEdit,
    companyChange,
    procedureDelete,
    connectionProcess,
    advancedSearchResult,
    companySearch,
    multiPlotAdd,
    recusiveAssignForSubfolder,
    pluDemand,
    plotDelete,
    readMore,
    alert,
    measureHelperModal,
    announcement,
    urbaGptDelete,
    drawHelperModal,
    altimetryHelperModal,
  } = useAppSelector(getModalsState);

  return (
    <>
      {alert.status && <Alert context={alert.context} modalType={alert.modalType} />}
      {procedureDelete && <DeleteProcedureModal open={procedureDelete} />}
      {folderDelete && <DeleteFolderModal />}
      {sectorDelete && <DeleteSectorModal />}
      {folderEdit && <EditFolderModal open={folderEdit} />}
      {sectorEdit && <EditSectorModal />}
      {plotDelete && <DeleteStudyModal open={plotDelete} />}
      {advancedSearchResult.status && (
        <AdvancedSearchModal {...advancedSearchResult} />
      )}
      {companySearch && <SearchCompanyNameModal open={companySearch} />}
      {multiPlotAdd.status && <MultiselectPlotModal plots={multiPlotAdd.plots} />}
      {readMore.status && <ReadMoreModal type={readMore.type} />}
      {companyChange && <ChooseCompanyModal />}
      {recusiveAssignForSubfolder && <RecursiveFolderStatusModal />}
      {pluDemand && <AskPLUModal />}
      {connectionProcess && <ConnexionProcessModal />}
      {announcement && <AnnouncementModal open={announcement} />}
      {urbaGptDelete && <DeleteUrabGptModal open={urbaGptDelete} />}
      {drawHelperModal && <DrawHelperModal />}
      {measureHelperModal && <MeasureHelperModal />}
      {altimetryHelperModal && <AltimetryHelperModal />}
      <UrbaGpt />
    </>
  );
};

export default ModalContainer;
