import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { getAppState } from '../../App/appSlice';
import {
  predictionOriginEnum,
  searchTypeEnum,
} from '../../App/contexts/InputTabsContext';
import useHistoricCookie from '../../App/hooks/useHistoricCookie';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { InputTabsContext } from '../../layout';
import fetchPlotRequestBuildParams from '../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../redux/plot/actions';
import GoogleService from '../../shared/services/GoogleService';
import genericSort from '../../utils/genericSort';
import { plotServitudesActions } from '../plotServitudes/plotServitudesSlice';

import { nanoid } from '@reduxjs/toolkit';
import { GenericModalEnum } from '../../components/Modals/GenericModal';
import { addrServProcessAsync } from '../../utils/addrServProcessAsync';
import { loadersActions } from '../loaders/loaderSlice';
import { mapActions } from '../map/mapSlice';
import { modalsActions } from '../modals/modalsSlice';
import { pluActions } from '../plu/pluSlice';
import PredictionRow from './PredictionRow';
import styles from './autocomplete.module.scss';

interface IPredictionContainerProps {
  resetSessionToken: () => void;
  sessionToken: string | null;
}

function PredictionsContainer({
  resetSessionToken,
  sessionToken,
}: IPredictionContainerProps) {
  const { setCookie } = useHistoricCookie();
  const { activateFavorite } = useAppSelector(getAppState);
  const {
    inputValue,
    predictionsDisplay,
    predictions,
    setInputValue,
    setPredictions,
    setPredictionsDisplay,
    isEnterClick,
    setIsEnterClick,
    inputTab,
  } = useContext(InputTabsContext);

  const sorted: Prediction[] = genericSort(predictions.data, 'asc', 'type');

  const dispatch = useAppDispatch();

  const handlePredictionClick = async (prediction?: Prediction) => {
    try {
      let tempPrediction: Prediction | undefined = prediction;

      // if enter with keyboard
      if (!prediction && !isEmpty(predictions.data)) {
        tempPrediction = predictions.data[0];
      }
      const resultTraitment = (response: GeolocPointInfo | null) => {
        if (response) {
          // check insee for new PLU load
          response?.inseeCode &&
            dispatch(pluActions.checkLocationInsee(response?.inseeCode));

          // favorite places (not used)
          if (activateFavorite) {
            setCookie(
              response,
              tempPrediction as Prediction,
              Boolean(inputTab === searchTypeEnum.Parcelle)
            );
          }

          setInputValue(response.address);
          const dataRequest = fetchPlotRequestBuildParams(response, null);

          dispatch(plotServitudesActions.reset());
          if (response.inseeCode) {
            dispatch(mapActions.geolocSet(response));
          }

          if (dataRequest) {
            dispatch(plotActions.fetchPlot.request(dataRequest));
          } else {
            console.log('error data');
          }
        } else {
          dispatch(
            modalsActions.alert({
              status: true,
              context: 'noPlotFounded',
              modalType: GenericModalEnum.INFO,
            })
          );
        }
      };

      if (tempPrediction) {
        setPredictionsDisplay(false);
        setIsEnterClick(false);
        setInputValue(tempPrediction.label);
        const predictionDetails = await GoogleService.getPredictionDetails(
          tempPrediction.id,
          sessionToken
        );

        if (predictionDetails) {
          dispatch(
            mapActions.mapCenterSet({
              latLng: [predictionDetails.geometry[0], predictionDetails.geometry[1]],
              zoom: 17,
            })
          );

          resetSessionToken();

          // add input loader before fetch address
          dispatch(loadersActions.addressLoaderSet(true));

          // fetch address server OR googlefor address plot
          const result = await addrServProcessAsync(
            predictionDetails.geometry[0],
            predictionDetails.geometry[1]
          );

          // remove input loader address is fetched
          dispatch(loadersActions.addressLoaderSet(false));

          if (result) {
            resultTraitment(result);
          }
        }
        setPredictions({
          origin: predictionOriginEnum.ADDRESS_SERVER,
          data: [],
        });
      }
    } catch (error) {
      // remove input loader if error
      dispatch(loadersActions.addressLoaderSet(false));

      // open error modal alert
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'noPlotFounded',
          modalType: GenericModalEnum.INFO,
        })
      );
    }
  };

  useEffect(() => {
    if (isEnterClick) {
      handlePredictionClick();
    }
  }, [isEnterClick]);

  return (
    <div
      className={`${styles.predictionsContainer} ${
        predictionsDisplay ? styles.visible : ''
      }`}
    >
      <ul>
        {isEmpty(predictions) && inputValue ? (
          <li>Aucune correspondance</li>
        ) : (
          <li className={styles.predictionList}>
            {sorted?.map((row, index) => (
              <PredictionRow
                key={nanoid()}
                prediction={row}
                lastType={index === 0 ? null : sorted[index - 1].type}
                index={index}
                onPredictionClick={handlePredictionClick}
              />
            ))}
          </li>
        )}
      </ul>
    </div>
  );
}

export default PredictionsContainer;
