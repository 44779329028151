import { nanoid } from '@reduxjs/toolkit';
import { FC, memo } from 'react';
import { TileLayer } from 'react-leaflet';
import { useAppSelector } from '../../App/store';
import { getPluState } from '../../features/plu/pluSlice';

const PluLayer: FC = memo(() => {
  const { pluZone, pluOthers } = useAppSelector(getPluState);

  return (
    <>
      {pluZone.displayed && (
        <TileLayer maxZoom={22} opacity={0.6} url={pluZone.layer || ''} zIndex={2} />
      )}

      {pluOthers
        ?.filter((e) => e.displayed === true)
        .map((plu) => {
          return (
            <TileLayer
              key={nanoid()}
              maxZoom={22}
              opacity={0.6}
              zIndex={2}
              url={plu.mapboxUrl ?? ''}
            />
          );
        })}
    </>
  );
});

PluLayer.displayName = 'PluLayer';
export default PluLayer;
