import { memo } from 'react';
import { useAppSelector } from '../../../../App/store';
import MultiSelectToolbar from '../../../plot/multiPlotSelectFeature/components/MultiSelectToolbar';
import AltimetryToolbar from '../altimetryPlugin/AltimetryToolbar';
import DrawToolbar from '../drawPlugin/DrawToolbar';
import { getMapPluginsUrbaToolbarState } from '../mapPluginSlice';
import MeasureToolbar from '../measurePlugin/MeasureToolbar';
import { ToolbarTypeEnum } from './types/ToolbarBtnEnums';
import styles from './urbaToolbar.module.scss';

function UrbaToolbar() {
  const { displayedToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);

  const displayed = (toolbar: ToolbarTypeEnum) => {
    return displayedToolbar.includes(toolbar);
  };
  return (
    <div className={styles.urbaToolbar}>
      {displayed(ToolbarTypeEnum.DRAW) && <DrawToolbar />}
      {displayed(ToolbarTypeEnum.MEASURE) && <MeasureToolbar />}
      {displayed(ToolbarTypeEnum.ALTIMETRY) && <AltimetryToolbar />}
      {displayed(ToolbarTypeEnum.MULTISELECT) && <MultiSelectToolbar />}
    </div>
  );
}

export default memo(UrbaToolbar);
