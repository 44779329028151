import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  section: {
    height: '24px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
  },
  redBloc: { width: '6px', marginRight: '2px', backgroundColor: '#f00' },
  blueBloc: {
    paddingLeft: '8px',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: '#4E55A2',
    color: '#fff',
    fontWeight: 500,
    fontSize: '11px',
  },
});
interface ICommonBlocTitleProps {
  text: string;
}
const CommonBlocTitle = ({ text }: ICommonBlocTitleProps) => {
  return (
    <View style={styles.section}>
      <View style={styles.redBloc} />
      <View style={styles.blueBloc}>
        <Text>{text}</Text>
      </View>
    </View>
  );
};

export default CommonBlocTitle;
