import * as Yup from 'yup';
import { validationMessages } from '../../../../../constants/validations';

export const initalProcedureValues: ProcedureFormValues = {
  responsable: '',
  type: '',
  studyContactIdIri: '',
  eventDate: new Date().toISOString(),
  comment: '',
  studyIdIri: '',
};

export const procedureValidationSchema = () => {
  return Yup.object({
    responsable: Yup.string().required(validationMessages.interlocutor.requis),
    type: Yup.string().required(validationMessages.procedureType.requis),
    contact: Yup.string().nullable(true),
    eventDate: Yup.string(),
    comment: Yup.string(),
  });
};
