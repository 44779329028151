import { nanoid } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import PanelContainer from '../components/PanelContainer';
import { getAuthState } from '../features/auth/authSlice';
import Loader from '../features/loaders/fullScreenLoader';
import ModalContainer from '../features/modals/ModalContainer';
import { modalsActions } from '../features/modals/modalsSlice';
import useUsers from '../features/users/useUsers';
import LocalStorageService from '../services/LocalStorageService';
import { routes } from './routes';
import { useAppDispatch, useAppSelector } from './store';

const routesFilter = (isAuth: boolean) => {
  return routes.filter((f) => (isAuth ? true : f.isAuth === false ? true : false));
};

function RouteContainer(): React.ReactElement {
  const { isLogged, token } = useAppSelector(getAuthState);
  useUsers({ load: true });
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isLogged) {
      const lsToken = LocalStorageService.getToken();

      delay(
        () => {
          if (lsToken || token) {
            dispatch(modalsActions.urbaneaseIntro(false));
            dispatch(modalsActions.connectionProcess(true));
          } else if (!isLogged) {
            history.push('/');
          }
        },
        50,
        lsToken
      );
    }
  }, [isLogged]);

  const routeMapping = useMemo(() => {
    return (
      <>
        {routesFilter(isLogged).map((route) => (
          <Route
            key={nanoid()}
            path={route.path}
            exact={route.exact}
            component={route.Component}
          />
        ))}
      </>
    );
  }, [isLogged]);

  return (
    <>
      <Loader />
      <ModalContainer />
      <PanelContainer />
      <Switch>{routeMapping}</Switch>
    </>
  );
}

export default RouteContainer;
