import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import {
  foldersActions,
  getFoldersState,
} from '../../features/folders/foldersSlice';
import { getMapPluginsUrbaToolbarState } from '../../features/map/plugins/mapPluginSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { sectorActions } from '../../features/sectors/sectorSlice';
import { IIconActionProps } from './ItemActions';
import TooltipIcon from './TooltipIcon';
import styles from './itemActions.module.scss';

function GarbageIcon<T>({ type, item, updateAllowed }: IIconActionProps<T>) {
  const [action, setAction] = useState<string>('');
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { folders } = useAppSelector(getFoldersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === 'folder') {
      if (item.parent) {
        setAction('Supprimer sous-dossier');
      } else {
        setAction('Supprimer dossier');
      }
    } else {
      if (item.parent) {
        setAction('Supprimer sous-secteur');
      } else {
        setAction('Supprimer secteur');
      }
    }
  }, []);

  const handleDeleteModal = () => {
    if (!activeToolbar) {
      if (type === 'folder' && folders) {
        dispatch(foldersActions.folderForActionSet(item as IFolder));
        dispatch(modalsActions.folderDelete(true));
      } else {
        dispatch(sectorActions.sectorForActionSet(item as ISector));
        dispatch(modalsActions.sectorDelete(true));
      }
    }
  };
  return (
    <>
      {updateAllowed && (
        <TooltipIcon action={action}>
          <DeleteOutlineOutlinedIcon
            className={`${styles.genericIcon} ${styles.delete}`}
            onClick={handleDeleteModal}
          />
        </TooltipIcon>
      )}
    </>
  );
}

export default GarbageIcon;
