import { TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller, useFormState, UseFormTrigger } from 'react-hook-form';
import styles from '../advancedPlotSearchContainer.module.scss';
import TooltipCustom from '../TooltipCustom';

interface IBuildingTextFieldProps {
  control: any;
  name: keyof AdvancedPlotSearchForm;
  trigger: UseFormTrigger<AdvancedPlotSearchForm>;
  label: string;
  placeHolder?: string;
  isLabelOther?: boolean;
  keysToTrigger: AdvancedSearchFieldsToTrigger;
}

function BuildingTextField({
  control,
  name,
  trigger,
  label,
  placeHolder,
  isLabelOther,
  keysToTrigger,
}: IBuildingTextFieldProps): React.ReactElement {
  const { errors } = useFormState({ control });
  const error = errors[name];

  return (
    <div className={styles.field}>
      <div className={`${styles.label} ${isLabelOther ? styles.isLabelHover : ''}`}>
        {label}
        {isLabelOther && (
          <InfoOutlinedIcon titleAccess="Basé sur le bâtiment le plus haut de la parcelle" />
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TooltipCustom error={error}>
            <TextField
              value={value ?? ''}
              className={error && styles.hasError}
              variant="outlined"
              onChange={onChange}
              onFocus={(e) => e.target.select()}
              onBlur={async () => await trigger(keysToTrigger)}
              placeholder={placeHolder}
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              error={Boolean(error)}
            />
          </TooltipCustom>
        )}
      />
    </div>
  );
}

export default BuildingTextField;
