import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import Xion from '../../../assets/images/xicon-grey.png';
import { getCompanyState } from '../../../features/company/companySlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { getUsersState, userActions } from '../../../features/users/usersSlice';
import {
  userCreateThunk,
  userUpdateThunk,
} from '../../../features/users/usersThunks';
import TextFieldFormCustom from '../../Common/FormComponents/TextFieldFormCustom';
import { Button } from '@mui/material';
import './AddUserModal.scss';
import {
  initialForm,
  validationAddUserSchema,
  validationEditUserSchema,
} from './addUserModalValidation';

export default function AddUserModal({ open }: { open: boolean }): JSX.Element {
  const dispatch = useAppDispatch();
  const { selectedUser, isEditingUser } = useAppSelector(getUsersState);
  const { companyIdIri } = useAppSelector(getCompanyState);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues:
      isEditingUser && selectedUser
        ? {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
          }
        : initialForm,
    resolver: yupResolver(
      selectedUser ? validationEditUserSchema : validationAddUserSchema
    ),
  });

  useEffect(() => {
    if (selectedUser) {
      setValue('firstName', selectedUser.firstName);
      setValue('lastName', selectedUser.lastName);
      setValue('email', selectedUser.email);
    }
  }, [selectedUser]);

  const onCloseModal = () => {
    reset();
    dispatch(modalsActions.addUser(false));
    dispatch(userActions.resetSelectedUser());
    dispatch(userActions.setEditingUser(false));
  };

  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      if (selectedUser) {
        dispatch(
          userUpdateThunk({
            userIdIri: selectedUser.idIri,
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
            },
          })
        );
      } else {
        dispatch(
          userCreateThunk({
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
              plainPassword: getValues('password'),
              roles: ['ROLE_USER'],
            },
            companyIdIri,
          })
        );
      }
      onCloseModal();
    }
  };

  return (
    <div>
      <Dialog
        className="add-user-modal"
        open={open}
        onClose={onCloseModal}
        fullScreen={false}
      >
        <DialogContent className="add-user-modal-container">
          <div className="xicon-wrapper" onClick={onCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <div className="title">
            {isEditingUser ? "Modifier l'utilisateur" : 'Ajouter un utilisateur'}
          </div>
          <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="text-field">
              <TextFieldFormCustom
                type="text"
                control={control}
                name="lastName"
                label="Nom"
                required
                error={Boolean(errors.lastName)}
                errorMsg={errors.lastName?.message as string}
              />
            </div>
            <div className="text-field">
              <TextFieldFormCustom
                type="text"
                control={control}
                name="firstName"
                label="Prénom"
                required
                error={Boolean(errors.firstName)}
                errorMsg={errors.firstName?.message as string}
              />
            </div>
            <div className="text-field">
              <TextFieldFormCustom
                type="email"
                control={control}
                name="email"
                label="Email"
                required
                error={Boolean(errors.email)}
                errorMsg={errors.email?.message as string}
              />
            </div>
            {!isEditingUser && (
              <div className="text-field">
                <TextFieldFormCustom
                  type="password"
                  control={control}
                  name="password"
                  label="Mot de passe"
                  error={Boolean(errors.password)}
                  errorMsg={errors.password?.message as string}
                />
              </div>
            )}
            <Button type="submit" className="btn">
              {selectedUser ? "Modifier l'utilisateur" : 'Créer l’utilisateur'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
