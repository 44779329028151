import usePlotStudyStatus from '../../../App/hooks/usePlotStudyStatus';
import './statusElement.scss';

interface IPlotStateBadgeProps {
  status: nullable<StudyStatus> | string;
}
const PlotStateBadge = ({ status }: IPlotStateBadgeProps): JSX.Element => {
  const { findPlotStudyStatus } = usePlotStudyStatus();

  if (typeof status === 'string') {
    const plotStatus = findPlotStudyStatus(status);

    return (
      <div className={`plot-state-badge ${plotStatus?.class}`}>
        {plotStatus?.label}
      </div>
    );
  }

  return <div className={`plot-state-badge ${status?.class}`}>{status?.label}</div>;
};

export default PlotStateBadge;
