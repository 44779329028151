import StatusElement from '../../components/Common/StatusElement';
import { getAppState } from '../appSlice';
import { useAppSelector } from '../store';

export default function usePlotStudyStatus() {
  const { plotStudyStatuses } = useAppSelector(getAppState);

  const findPlotStudyStatus = (idIri: string) => {
    return plotStudyStatuses.result?.find((elt: StudyStatus) => elt.idIri === idIri);
  };

  const filterStatusesFromPattern = (statusIdIri: string | null) => {
    return plotStudyStatuses.result?.find(
      (elt: StudyStatus) => elt?.idIri === statusIdIri
    );
  };

  const statusForSelect = () => {
    if (plotStudyStatuses.result) {
      return (
        plotStudyStatuses.result?.map((ps) => ({
          value: ps.idIri,
          display: <StatusElement status={ps} />,
        })) ?? []
      );
    } else {
      return [];
    }
  };

  return {
    plotStudyStatuses: plotStudyStatuses.result,
    findPlotStudyStatus,
    statusForSelect,
    filterStatusesFromPattern,
  };
}
