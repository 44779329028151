import { TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { getAppState } from '../../../App/appSlice';
import { searchTypeEnum } from '../../../App/contexts/InputTabsContext';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import Autocomplete from '../../../features/autocomplete';
import { autocompleteActions } from '../../../features/autocomplete/autocompleteSlice';
import FavoriteBtn from '../../../features/favoritePlaces/FavoriteBtn';
import { getMapState } from '../../../features/map/mapSlice';
import { InputTabsContext } from '../../../layout/';
import plotActions from '../../../redux/plot/actions';
import { Button } from '@mui/material';
import Tabs from './Tabs';
import styles from './inputTabs.module.scss';

function InputTabs() {
  const { inputTab, plotId, setPlotId, setInputValue } =
    useContext(InputTabsContext);
  const { isLogged } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { activateFavorite } = useAppSelector(getAppState);

  const location = useLocation();
  const path = location.pathname;
  const [valid, setValid] = useState<boolean>(true);
  const [dirty, setDirty] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (geolocDatas?.address) {
      setInputValue(geolocDatas?.address);
      dispatch(autocompleteActions.setInputValue(geolocDatas?.address));
    }
  }, [geolocDatas?.address]);

  const handleSubmit = () => {
    if (plotId && geolocDatas?.inseeCode && geolocDatas?.postalCode && valid) {
      const payload = {
        plotId: geolocDatas.inseeCodeDistrict + plotId,
        postalCode: geolocDatas.postalCode,
        pdf: false,
      };
      dispatch(plotActions.fetchPlotByPlotId.request(payload));
    }
  };

  return (
    <>
      {isLogged && (
        <div
          className={`${styles.inputTabsWrapper} ${
            inputTab === 0 ? '' : styles.inputPlotVisible
          }`}
        >
          {path === '/external_pdf_errial' ? (
            <h2>Modifier l’adresse recherché</h2>
          ) : (
            <h2>Que recherchez-vous ?</h2>
          )}

          <Tabs />

          <div className={styles.tabsContent}>
            <div className={styles.fieldContainer}>
              <Autocomplete
                isCities={Boolean(inputTab === searchTypeEnum.Parcelle)}
              />

              <div
                className={`${styles.plotInputContainer} ${
                  inputTab === 0 ? '' : styles.visible
                }`}
              >
                <TextField
                  className={`${styles.plotInput} ${
                    !valid && dirty && Boolean(plotId) ? styles.hasError : ''
                  }`}
                  name="postal"
                  value={plotId}
                  placeholder="000BZXXXX"
                  type="text"
                  variant="outlined"
                  size="small"
                  disabled={!isLogged}
                  fullWidth={true}
                  onChange={(e) => {
                    const reg = /^\d{3}[A-Z0]{2}\d{4}$/i;
                    setValid(reg.test(e.target.value));
                    setDirty(true);

                    setPlotId(e.target.value.toUpperCase().replace(/ /g, ''));
                  }}
                  error={!valid && dirty && Boolean(plotId)}
                  helperText={
                    !valid && plotId.length < 9 && plotId
                      ? "L'identifiant de la parcelle est trop court"
                      : !valid && plotId.length > 9 && plotId
                      ? "L'identifiant de la parcelle est trop long"
                      : !valid && plotId.length === 9 && plotId
                      ? 'Le format de la parcelle est incorrect'
                      : ''
                  }
                />
                <Button className={styles.submitBtn} onClick={handleSubmit}>
                  Rechercher &amp; Afficher sur la carte
                </Button>
              </div>
            </div>

            {activateFavorite && <FavoriteBtn className={styles.iconContainer} />}
          </div>
        </div>
      )}
    </>
  );
}

export default InputTabs;
