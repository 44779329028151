import { Checkbox } from '@mui/material';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import DateFilter from '../../../components/dateFilter';
import {
  buildPermitsActions,
  DisplayedFiltersEnum,
  DisplayedTypesEnum,
  getBuildPermitsState,
} from '../buildPermitsSlice';
import styles from './buildPermitsLegend.module.scss';
import HousingFilter from './HousingFilter';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    padding: '2px',
  },
});
function MarkerLegend(): JSX.Element {
  const { classes } = useStyles();
  const {
    displayedTypes,
    displayedFilters,
    dateFilter,
    housingFilter,
  } = useAppSelector(getBuildPermitsState);
  const dispatch = useAppDispatch();

  const handleDateChange = (dates: DateFilterType) => {
    dispatch(buildPermitsActions.setDateFilter(dates));
  };
  const handleChangeCheckbox = (value: DisplayedTypesEnum) => {
    dispatch(buildPermitsActions.setDisplayedType(value));
  };

  const handleChangeCheckboxFilter = (value: DisplayedFiltersEnum) => {
    dispatch(buildPermitsActions.setDisplayedFilter(value));
  };

  const handleHousingFilterChange = (value: HousingFilterType) => {
    dispatch(buildPermitsActions.setHouseFilter(value));
  };
  return (
    <div className={styles.markerLegend}>
      <ul>
        <li>
          <Checkbox
            className={classes.root}
            color="primary"
            name="pa"
            size={'small'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => handleChangeCheckbox(DisplayedTypesEnum.PA)}
            checked={displayedTypes.includes(DisplayedTypesEnum.PA)}
          />
          <div className={`${styles.buildPermis} ${styles.paMarker}`} />
          <p>{`PA : Permis d'Aménager`}</p>
        </li>
        <li>
          <Checkbox
            className={classes.root}
            color="primary"
            name="dp"
            size={'small'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => handleChangeCheckbox(DisplayedTypesEnum.DP)}
            checked={displayedTypes.includes(DisplayedTypesEnum.DP)}
          />
          <div className={`${styles.buildPermis} ${styles.dpMarker}`} />
          <p>DP : Déclaration Préalable</p>
        </li>
        <li>
          <Checkbox
            className={classes.root}
            color="primary"
            name="pc"
            size={'small'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => handleChangeCheckbox(DisplayedTypesEnum.PC)}
            checked={displayedTypes.includes(DisplayedTypesEnum.PC)}
          />
          <div className={`${styles.buildPermis} ${styles.pcMarker}`} />
          <p>PC : Permis de Construire</p>
        </li>

        <hr />

        <p className={styles.filtersTitle}>Filtrer les permis par :</p>
        <li>
          <Checkbox
            className={classes.root}
            color="primary"
            name="recent"
            size={'small'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => handleChangeCheckboxFilter(DisplayedFiltersEnum.RECENT)}
            checked={displayedFilters.includes(DisplayedFiltersEnum.RECENT)}
          />
          <div className={`${styles.buildPermis} ${styles.recentLegend}`} />
          <p>Autorisations inférieures à 3 mois</p>
        </li>
        <li>
          <Checkbox
            className={classes.root}
            color="primary"
            name="cancelled"
            size={'small'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() =>
              handleChangeCheckboxFilter(DisplayedFiltersEnum.CANCELLED)
            }
            checked={displayedFilters.includes(DisplayedFiltersEnum.CANCELLED)}
          />
          <div className={`${styles.buildPermis} ${styles.cancelled}`}>x</div>
          <p>Autorisations annulées</p>
        </li>
        <li>
          <HousingFilter
            values={housingFilter}
            onChange={handleHousingFilterChange}
          />
        </li>
        <li>
          <DateFilter
            label="Autorisations délivrées entre"
            dates={dateFilter}
            onChange={handleDateChange}
            minDate="2013-01-01"
          />
        </li>
      </ul>
    </div>
  );
}

export default memo(MarkerLegend);
