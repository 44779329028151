import React, { memo, useState } from 'react';
import SelectCustom from '../../components/Common/FormComponents/SelectCustom';

interface IGenericSelectSortProps {
  setSortKey: React.Dispatch<React.SetStateAction<string>>;
  items: ISelectItem[];
  noValue?: string;
}
function GenericSelectSort({ setSortKey, items, noValue }: IGenericSelectSortProps) {
  const [selectedValue, setselectedValue] = useState<string>('');

  const handleChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    setselectedValue(value);

    setSortKey(value);
  };
  return (
    <div className="sort-select">
      <SelectCustom
        items={items}
        value={selectedValue}
        name="sort"
        noValue={noValue ?? 'Trier par'}
        displayEmpty
        onChange={handleChange}
      />
    </div>
  );
}

export default memo(GenericSelectSort);
