import { nanoid } from '@reduxjs/toolkit';
import { useContext, useEffect, useState } from 'react';
import { contactContext } from '../../study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import styles from './contactForm.module.scss';

function ContactChoiceContainer() {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { contacts, setActiveContact, setContacts } = useContext(contactContext);

  useEffect(() => {
    setIsVisible(contacts && contacts.length > 0 ? true : false);
  }, [contacts]);

  const handleClick = (contact: IContact) => {
    setActiveContact(contact);
    setContacts(null);
  };
  return (
    <>
      {isVisible && (
        <div className={styles.lastNameChoiceContainer}>
          <ul>
            {contacts?.map((m) => (
              <li key={nanoid()} onClick={() => handleClick(m)}>{`${m.name}`}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default ContactChoiceContainer;
