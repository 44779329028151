import { createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import { resetAppAction } from '../../App/appSlice';
import axiosInstance from '../../services/AxiosPublicInstance';
import LocalStorageService from '../../services/LocalStorageService';
import { postEntityNoToken } from '../../services/axiosFiles/genericCrud';
import { modalsActions } from '../modals/modalsSlice';
import { fetchUserById } from '../users/usersRequest';
import { clearStorages } from './utils';

export const classicLoginThunk = createAsyncThunk(
  'auth/classicLoginThunk',
  async (params: ILoginParams, { dispatch, rejectWithValue }) => {
    return axiosInstance()
      .post('/authentication_token', params)
      .then(
        (response) => {
          const r = response.data;

          // set localStorage with token and refresh token
          LocalStorageService.setToken(r.token);
          LocalStorageService.setRefreshToken(r.refresh_token);

          dispatch(modalsActions.closeAllAuthModal());
          dispatch(modalsActions.connectionProcess(true));

          return { token: r.token, refreshToken: r.refresh_token };
        },
        (err) => {
          return rejectWithValue({
            ...err,
            message: 'login ou mot de passe incorrect',
          });
        }
      )
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const externalConnectLoginThunk = createAsyncThunk(
  'auth/externalConnectLoginThunk',
  async (params: ILoginResponse, { rejectWithValue, dispatch }) => {
    try {
      clearStorages();

      dispatch(resetAppAction());

      delay(() => {
        dispatch(modalsActions.signin(true));
        dispatch(modalsActions.signup(false));
      }, 1);

      delay(() => {
        LocalStorageService.setToken(params.token);
        LocalStorageService.setRefreshToken(params.refreshToken);
      }, 100);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutThunk = createAsyncThunk(
  'auth/logoutThunk',
  async (params: void, { rejectWithValue, dispatch }) => {
    try {
      clearStorages();

      // little timer before dispatch because clear storage is async
      dispatch(resetAppAction());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAuthUserThunk = createAsyncThunk(
  'auth/fetchAuthUserTunk',
  async (params: { userIdIri?: string }, { rejectWithValue }) => {
    if (params.userIdIri) {
      const result = fetchUserById(params.userIdIri);

      return result.then(
        (response) => {
          return response;
        },
        (err) => {
          return rejectWithValue(err);
        }
      );
    } else {
      return rejectWithValue({ status: 422, message: 'no userIdIri found' });
    }
  }
);

// request for receive reinit email
export const forgotPasswordThunk = createAsyncThunk(
  'auth/forgotPasswordThunk',
  async (params: { email: string | null }, { rejectWithValue }) => {
    try {
      if (params.email) {
        const promise = postEntityNoToken({
          endpoint: '/reset_password_request',
          body: { email: params.email },
        });

        promise.then(
          (response: any) => {
            return response;
          },
          (err) => {
            rejectWithValue(err);
          }
        );
      } else {
        rejectWithValue(new Error('email param not found'));
      }
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  'auth/resetPasswordThunk',
  async (
    params: {
      email: string | null;
      password: string | null;
      token: string | null;
      history: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { email, password, token, history } = params;
    try {
      if (email && password && token) {
        const promise = postEntityNoToken({
          endpoint: '/reset_password',
          body: { email, token, password },
        });

        return promise.then(
          (response: any) => {
            dispatch(classicLoginThunk({ email, password }));
            history.push('/');
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue(new Error('One param is missing'));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const firstPasswordThunk = createAsyncThunk(
  'auth/firstPasswordThunk',
  async (
    params: {
      email: string;
      password: string;
      token: string;
      history: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { email, password, token, history } = params;

    try {
      if (email && password && token) {
        const promise = postEntityNoToken({
          endpoint: '/set_first_password',
          body: { email, token, password },
        });

        promise.then(
          (response: any) => {
            dispatch(classicLoginThunk({ email, password }));
            history.push('/');
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue(new Error('One param is missing'));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
