import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../../features/auth/authSlice';
import { modalsActions } from '../../../../features/modals/modalsSlice';
import { userActions } from '../../../../features/users/usersSlice';
import { userUpdateThunk } from '../../../../features/users/usersThunks';
import TextFieldFormCustom from '../../../Common/FormComponents/TextFieldFormCustom';
import Footer from './Footer';
import { initialForm, validationSchema } from './teamCardValidation';

interface IUserCardProps {
  user: User;
  canUpdate?: boolean;
  authIdIri?: string;
}

function UserCard({ user }: IUserCardProps) {
  const authState = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: initialForm,
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      dispatch(
        userUpdateThunk({
          userIdIri: user.idIri,
          user: { plainPassword: getValues('password') },
        })
      );
      if (user.id !== authState.user?.id) {
        // display message without logout
        dispatch(userActions.setEditingUser(true));
      } else {
        dispatch(modalsActions.passwordChangeResult(true));
      }

      reset();
    }
  };

  return (
    <div key={user.idIri} className="card">
      <div className="card-header">
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <p className="header-email">{user.email}</p>
        <p>
          {user.isAdmin
            ? 'Administrateur'
            : user?.isManager
            ? 'Manager'
            : 'Prospecteur'}
        </p>
      </div>
      <p>
        <span>{user.plotStudyCount}</span> parcelles étudiées
        <br />
        <span>{user.procedureCount}</span> actions de prospection
      </p>
      <form className="card-body" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="field">
          <TextFieldFormCustom
            type="password"
            control={control}
            name="password"
            label="Nouveau mot de passe"
            disabled={!authState.isManager}
            required
            error={Boolean(errors.password)}
            errorMsg={errors.password?.message as string}
          />
        </div>
        <div className="field">
          <TextFieldFormCustom
            type="password"
            control={control}
            name="verifyPassword"
            label="Retapez le nouveau mot de passe"
            disabled={!authState.isManager}
            required
            error={Boolean(errors.verifyPassword)}
            errorMsg={errors.verifyPassword?.message as string}
          />
        </div>
        <button type="submit" disabled={!authState.isManager}>
          VALIDER
        </button>
      </form>

      {authState.isManager && <Footer user={user} />}
    </div>
  );
}

export default UserCard;
