import { FocusEvent } from 'react';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ReactComponent as TickSquare } from '../../../assets/images/tick-square.svg';
import styles from './rightPanelModalExternalPdfErrial.module.scss';
import { TextField } from '@mui/material';

interface ITextInputFormPdfErrialCustom {
  name: string;
  control: any;
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  type?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}
export default function TextInputFormPdfErrialCustom({
  name,
  control,
  label,
  disabled,
  error,
  errorMsg,
  type = 'text',
  className,
  readOnly = false,
  onChange,
  onFocus,
  required,
  placeholder,
  InputProps,
}: ITextInputFormPdfErrialCustom) {
  const handleChange = (
    e: ChangeEventCustom,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    switch (type) {
      case 'number':
        field.onChange(parseFloat(e.target.value));
        break;
      case 'text':
      default:
        field.onChange(e.target.value);
        break;
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value } }) => (
        <div className={styles.inputForm}>
          <TextField
            {...field}
            type={type}
            variant="outlined"
            disabled={disabled}
            label={label}
            error={error}
            value={value ?? ''}
            helperText={errorMsg}
            placeholder={placeholder}
            className={className}
            onFocus={handleFocus}
            InputProps={{ readOnly, ...InputProps }}
            onChange={(e) => handleChange(e, field)}
            required={required}
          />
          {value ? (
            <TickSquare className={styles.tickGreen} />
          ) : (
            <TickSquare className={styles.tickGray} />
          )}
        </div>
      )}
    />
  );
}
