import { Font, StyleSheet } from '@react-pdf/renderer';
import Montserrat500 from '../fonts/Montserrat/static/Montserrat-Bold.ttf';
import Montserrat700 from '../fonts/Montserrat/static/Montserrat-ExtraBold.ttf';
import MontserratLight from '../fonts/Montserrat/static/Montserrat-Light.ttf';
import MontserratRegular from '../fonts/Montserrat/static/Montserrat-Medium.ttf';
import OrpiRegular from '../fonts/orpi/Orpi-Regular.ttf';

export const ORPI_PDF_COLOR_PRIMARY = '#514E9A';

Font.register({
  family: 'MontserratFamily',
  fonts: [
    {
      src: MontserratLight,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: MontserratRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: Montserrat500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: Montserrat700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'OrpiFamily',
  fonts: [
    {
      src: OrpiRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    // {
    //   src: OrpiMedium,
    //   fontStyle: 'normal',
    //   fontWeight: 400,
    // },
  ],
});
const hyphenationCallback = (word: string) => {
  return [word];
};

Font.registerHyphenationCallback(hyphenationCallback);

export const pdfStyles = StyleSheet.create({
  pageHome: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
  },
  page: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
    paddingBottom: '65px',
  },
  logoImage: {
    width: '20%',
  },
  letterO: {
    position: 'absolute',
    right: -15,
    top: 0,
    zIndex: 0,
  },
  homeGenericInfos: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    color: ORPI_PDF_COLOR_PRIMARY,
    fontSize: '14px',
    height: '135px',
  },
  homeLogoAndTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  homeTopTitleSection: {
    paddingTop: '40px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'center',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  homeTopTitle: { fontSize: '24px', fontWeight: 700 },
  homeTopSubTitle: { fontSize: '14px', fontWeight: 700 },
  date: { fontSize: '12px' },
  homePlotIdsSection: { marginTop: '120px', fontSize: '12px' },
  homePlotIdsTitle: { fontWeight: 700, color: '#FE0000' },
  homePlotIdsText: { maxWidth: '250px', color: ORPI_PDF_COLOR_PRIMARY },
  homeMapImage: {
    position: 'absolute',
    left: 15,
    top: '280px',
    width: '100%',
    height: '320px',
    zIndex: -1,
    borderRadius: '15px',
  },
  homeOwnerAndAddressBloc: {
    marginTop: '340px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  homeOwnerSection: {},
  homeOwnerLabel: {},
  homeOwnerData: { fontSize: '14px', fontWeight: 700 },
  homeAddressSection: { fontWeight: 700, color: '#FE0000', fontSize: '14px' },
  homeTitleBloc: { marginTop: '45px', color: ORPI_PDF_COLOR_PRIMARY },
  homeTitle: { marginTopfontSize: '20px', fontWeight: 700 },
  byUrbanease: { fontSize: '12px' },

  pageHeader: { position: 'relative' },
  pageHeaderBackground: { width: '448px' },
  pageHeaderText: {
    position: 'absolute',
    top: '10px',
    left: '40px',
    display: 'flex',

    alignItems: 'center',
    flexDirection: 'row',
  },
  pageHeaderOrpiLogoBloc: {
    width: '100px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  pageHeaderOrpiLogo: { width: '80%' },
  pageHeaderInfos: {
    marginLeft: '10px',
    color: '#ffffff',
    fontFamily: 'MontserratFamily',
    fontSize: '14px',
  },

  pageFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '6px',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  pageFooterTextContainer: {
    paddingLeft: '15px',
    paddingBottom: '10px',
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageFooterRedZone: {
    position: 'relative',
    width: '183px',
  },
  pageFooterLogoContainer: {
    position: 'absolute',
    width: '183px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '5px',
    fontSize: '10px',
    color: '#FFFFFF',
  },
  pageFooterLogoUrba: { width: '50px', height: '31px' },
  Container: {
    position: 'absolute',
    width: '183px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '5px',
    fontSize: '10px',
    color: '#FFFFFF',
  },
  plotDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plotDetailsInfos: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  plotInfosImage: {
    width: '215px',
    height: '185px',
  },
  genericBloc: {
    margin: '20px 0 0 0',
    fontFamily: 'MontserratFamily',
    width: '100%',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  genericBlocTitle: { fontSize: '16px', marginBottom: '10px' },
});
