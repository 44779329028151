import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { displayManagerActions } from '../../features/displayManager/displayManagerSlice';
import { sectorToEntityDisplay } from '../../features/displayManager/utils/entitiesDisplayParsers';
import { setStudyParamsThunk } from '../../features/study/services/thunks/studyParamsThunks';
import panelsActions from '../../redux/Panels/actions';
import { IIconActionProps } from './ItemActions';
import TooltipIcon from './TooltipIcon';
import styles from './itemActions.module.scss';

function EyeIcon<T>({ type, item }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();
  const { userIdIri } = useAppSelector(getAuthState);

  const handleDetails = () => {
    const studyParams: StudyParamsState = {
      type,
      idIri: item.idIri,
      noCheckExisting: true,
      managmentLoader: true,
    };

    if (type === 'sector') {
      dispatch(
        displayManagerActions.entitiesBackupAndReplace([
          sectorToEntityDisplay(item as ISector, userIdIri),
        ])
      );
    }

    dispatch(setStudyParamsThunk(studyParams));
    dispatch(panelsActions.studyContent.show('prospection'));
  };

  return (
    <TooltipIcon
      action={
        type === 'folder'
          ? 'Détails sous-dossier'
          : type === 'sector' && !item.parent
          ? 'Détails secteur'
          : 'Détails sous-secteur'
      }
    >
      <VisibilityOutlinedIcon
        className={styles.genericIcon}
        onClick={handleDetails}
      />
    </TooltipIcon>
  );
}

export default EyeIcon;
