import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';
import { useAppDispatch } from '../App/store';
import Logo from '../assets/images/logo.png';
import TextFieldFormCustom from '../components/Common/FormComponents/TextFieldFormCustom';
import { validationMessages } from '../constants/validations';
import { firstPasswordThunk, resetPasswordThunk } from '../features/auth/authThunks';
import './styles/passwordReset.scss';

const validationSchemaForm = Yup.object({
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
  password: Yup.string().required('Mot de passe requis'),
  token: Yup.string().required(),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
type FormType = {
  email: string;
  password: string;
  token: string;
};
export default function PasswordResetPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const query = useQuery();

  useEffect(() => {
    if (query && !query.get('token_password')) {
      history.push('/');
    }
  }, [query]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      email: query.get('email') as string,
      password: '',
      token: query.get('token_password') as string,
    },
    resolver: yupResolver(validationSchemaForm),
  });

  const submitForm = (data: FormType): void => {
    switch (path) {
      case '/init_first_password':
        dispatch(firstPasswordThunk({ ...data, history }));
        break;
      case '/reset_password':
        dispatch(resetPasswordThunk({ ...data, history }));
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    history.push('/');
  };

  return (
    <div className="main-wrapper-password-reset">
      <div className="header-wrapper">
        <div className="header-col-left">
          <img src={Logo} alt="logo" />
        </div>
        <button onClick={handleClick}>Retour sur Urbanease</button>
      </div>
      <div className="body-wrapper">
        <div className="password-header">
          <h2>
            {path === '/premier-mot-de-passe'
              ? 'Création de mot de passe'
              : 'Changement de mot de passe'}
          </h2>
          <span>
            {path === '/premier-mot-de-passe'
              ? 'Je souhaite créer mon mot de passe'
              : 'Je souhaite changer mon mot de passe'}
          </span>
        </div>
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
          <TextFieldFormCustom
            type="email"
            control={control}
            name="email"
            placeholder="Email"
            required
            error={Boolean(errors.email)}
            errorMsg={errors.email?.message as string}
          />
          <TextFieldFormCustom
            type="password"
            control={control}
            name="password"
            placeholder="Mot de passe"
            required
            error={Boolean(errors.password)}
            errorMsg={errors.password?.message as string}
          />
          <button type="submit" className="submit-btn">
            ENVOYER
          </button>
        </form>
      </div>
    </div>
  );
}
