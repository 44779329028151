import { useAppDispatch } from '../../../../App/store';
import Xion from '../../../../assets/images/xicon.png';
import { modalsActions } from '../../../modals/modalsSlice';
import styles from './mapPluginHelperModal.module.scss';

const MapPluginHelperModal = ({ children }: IChildren): JSX.Element => {
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(modalsActions.toolbarHelperModalReset());
  };

  return (
    <div className={styles.mapPluginHelperModal}>
      <div className={styles.xIconContainer} onClick={onCloseModal}>
        <img src={Xion} alt="close button" />
      </div>
      <div className={styles.para}>{children}</div>
    </div>
  );
};

export default MapPluginHelperModal;
