import { usePDF } from '@react-pdf/renderer';
import { Polygon } from 'geojson';
import { delay, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as RightArrowSmall } from '../../../../assets/images/right-arrow-small.svg';
import { getMapState } from '../../../../features/map/mapSlice';
import { getPlotState } from '../../../../redux/plot/reducer';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import genericObjectSort from '../../../../utils/genericObjectSort';
import { loadersActions } from '../../../loaders/loaderSlice';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../externalPdfErrialSlice';
import {
  fetchDatasForPdfGenerationThunk,
  fetchPlotServitudesThunk,
} from '../../externalPdfErrialThunks';
import parcelsVerification from '../../services/parcelsVerification';
import OrpiPdfContainer from './../pdf/OrpiPdfContainer';
import styles from './orpiButton.module.scss';

/* 
steps
  - launch all requests
  - servitudes loaded
  - plot datas loaded
  - parse datas
  - generate pdf
*/

const STEP_DELAY = 300;

const OrpiButton = ({ disabled }: { disabled: boolean }) => {
  const [datas, setDatas] = useState<PDFDatasType | null>(null);

  const dispatch = useAppDispatch();
  const [click, setClick] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const link = useRef<HTMLAnchorElement>(null);
  const {
    isMultiPlotSelectorPdf,
    externalPdfErrialFormData,
    multiPlotsPdf,
    datasForPdfGeneration,
    servitudes,
    forceGenerate,
  } = useAppSelector(getExternalPdfErrialState);
  const { parcelle } = useAppSelector(getPlotState);
  const { plotDatas } = useAppSelector(getMapState);
  const [instance, updateInstance] = usePDF({
    document: <OrpiPdfContainer datas={datas ?? null} />,
  });

  const getFullPlotsIdArray = () => {
    let plots: string[] = [];
    if (isMultiPlotSelectorPdf) {
      if (multiPlotsPdf.result && !isEmpty(multiPlotsPdf.result)) {
        plots = multiPlotsPdf.result.map((m) => m.fullPlotId);
      }
    } else if (parcelle) {
      plots = [parcelle.parcelleId];
    }

    return plots;
  };

  function orpipdfDatasReduce(key: keyof IMultiPlotsPdf) {
    const value = multiPlotsPdf.result?.reduce(
      (accumulator, elt) => accumulator + (elt[key] as number),
      0
    );
    return Math.round(value as number);
  }

  // start
  const performStep1 = () => {
    setClick(true);
    dispatch(loadersActions.currentStepSet('Chargement des données'));
    performStep2();
  };
  // launch all requests
  const performStep2 = async () => {
    if (!isMultiPlotSelectorPdf) {
      if (plotDatas?.parcelleLayer && parcelle) {
        const geom: unknown = plotDatas?.parcelleLayer?.parcelle;
        dispatch(
          fetchPlotServitudesThunk({
            plotGeometry: geom as Polygon,
            coordinates: [parcelle.lat, parcelle.lon],
          })
        );

        dispatch(
          fetchDatasForPdfGenerationThunk({
            plots: [parcelle.parcelleId],
          })
        );
      }
    } else {
      if (multiPlotsPdf.result && !isEmpty(multiPlotsPdf.result)) {
        const sortedPlots = genericObjectSort(
          multiPlotsPdf.result,
          'theoricCapacity',
          'desc'
        );

        const plot = sortedPlots[0];
        dispatch(
          fetchPlotServitudesThunk({
            plotGeometry: plot.plotGeometry,
            coordinates: [plot.lat, plot.lng],
          })
        );
        dispatch(
          fetchDatasForPdfGenerationThunk({
            plots: multiPlotsPdf.result?.map(
              (item: IMultiPlotsPdf) => item.fullPlotId
            ) as string[],
          })
        );
      }
    }
  };
  // parse datas
  const performStep3 = async () => {
    try {
      if (
        externalPdfErrialFormData &&
        parcelle &&
        datasForPdfGeneration.result &&
        servitudes.result
      ) {
        const form = externalPdfErrialFormData;
        const pdfGen = datasForPdfGeneration.result;
        const temp = {
          address: {
            addressLine1: form.addressCity,
            addressLine2: form.addressStreet,
          },
          date: new Date().toLocaleDateString(),
          ownerName: form.IdentityOwnersOnDocument,
          plotIds: [parcelle.parcelleId.substring(5)],
          homePagePicture: pdfGen.pictures.homePagePicture,
          user: {
            lastName: form.lastName,
            firstName: form.firstName,
            phone: form.phone,
            email: form.email,
          },
          companyName: form.companyName,
          goodDetails: {
            roomCount: form.numberOfRooms,
            floorCount: form.floorNumber,
            goodTypology: form.propertyType,
            homeArea: form.livingArea,
            landArea: form.landArea,
            comment: form.description,
          },
          plotDatas: {
            parcelsOnCadastrePicture:
              datasForPdfGeneration.result?.pictures?.parcelsOnCadastrePicture,
            parcelsOnZonePicture:
              datasForPdfGeneration.result?.pictures?.parcelsOnZonePicture,
            area: parcelle?.theoricCapacity as any,
            freeGroundArea: parcelle?.areaFree as any,
            builtArea: parcelle?.areaBuilt as any,
            recalculatedArea: parcelle?.realCapacity as any,
            builtHeight: pdfGen.parcelsMaxBuildingsHeight,
          },
          catnat: pdfGen?.cityEnvironmentDatas.catnat,
          contaminatedAreas: pdfGen?.contaminatedAreas,
          environmentRisksDetails: {
            pprm: pdfGen.cityEnvironmentDatas.pprm,
            pprn: pdfGen.cityEnvironmentDatas.pprn,
            pprt: pdfGen.cityEnvironmentDatas.pprt,
            azi: pdfGen.cityEnvironmentDatas.azi,
          },
          seismic: pdfGen.parcelsEnvironmentDatas.seismic,
          clay: pdfGen.parcelsEnvironmentDatas.clay,
          radon: pdfGen.parcelsEnvironmentDatas.radon,
          coastalErosion: pdfGen.parcelsEnvironmentDatas.coastalErosion,
          envConstraints: pdfGen.parcelsEnvironmentDatas.envConstraints,
          zonePlu: pdfGen.zonePlu,
          localServitudes: servitudes.result,
        };
        if (multiPlotsPdf.result) {
          temp.plotIds = multiPlotsPdf.result?.map((item) => item.plotId);
          temp.plotDatas.area = orpipdfDatasReduce('theoricCapacity');
          temp.plotDatas.freeGroundArea = orpipdfDatasReduce('areaFree');
          temp.plotDatas.builtArea = orpipdfDatasReduce('areaBuilt');
          temp.plotDatas.recalculatedArea = orpipdfDatasReduce('realCapacity');
        }

        setDatas(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (forceGenerate) {
      dispatch(externalPdfErrialActions.resetInfoModalDisplay());
      // start process
      performStep1();
    }
  }, [forceGenerate]);

  useEffect(() => {
    // wait for request datas before launch step 2
    if (
      servitudes.result &&
      datasForPdfGeneration.result &&
      ((!isMultiPlotSelectorPdf && plotDatas?.parcelleLayer?.parcelle) ||
        (isMultiPlotSelectorPdf && multiPlotsPdf.result))
    ) {
      delay(() => {
        // parse datas
        dispatch(loadersActions.currentStepSet('Traitement des données'));
        performStep3();
      }, STEP_DELAY);
    }

    if (datasForPdfGeneration.apiStatus === APIStatus.REJECTED) {
      setClick(false);
    }
  }, [
    servitudes,
    plotDatas?.parcelleLayer?.parcelle,
    multiPlotsPdf,
    datasForPdfGeneration,
  ]);

  useEffect(() => {
    // if datas and clisk are ok, launch react-pdf updateInstance
    if (datas && click) {
      // generate PDF
      delay(() => {
        dispatch(loadersActions.currentStepSet('Génération du PDF'));
        updateInstance();
      }, STEP_DELAY);
    }
  }, [datas]);

  useEffect(() => {
    // if updateInstance ended, launch real click for edit pdf
    // need delay with 1ms for nex js process step
    if (!instance.loading && click && datas)
      delay(() => {
        link.current?.click();
        setClick(false);
        dispatch(loadersActions.currentStepReset());
        dispatch(externalPdfErrialActions.pdfGenerationReset());
      }, 1);
  }, [instance.loading]);

  useEffect(() => {
    // disable button for generation pdf
    setDisabledBtn(
      disabled ||
        (isEmpty(multiPlotsPdf.result) && isEmpty(parcelle)) ||
        (isEmpty(multiPlotsPdf.result) && parcelle && isMultiPlotSelectorPdf) ||
        multiPlotsPdf.apiStatus === APIStatus.PENDING
    );
  }, [isMultiPlotSelectorPdf, multiPlotsPdf, parcelle, disabled]);

  const handlePdf = async (): Promise<void> => {
    try {
      const response: PlotVerification = await parcelsVerification(
        getFullPlotsIdArray()
      );
      if (!response.hasNoAdjacentPlot) {
        performStep1();
      } else {
        dispatch(
          externalPdfErrialActions.setInfoModalDisplay({
            display: true,
            type: 'forceGenerate',
            plotIds: response.plotIds,
            errorMessage: response.errorMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        externalPdfErrialActions.setInfoModalDisplay({
          display: true,
          type: 'errorAdjacent',
          plotIds: null,
          errorMessage: null,
        })
      );
    }
  };

  return (
    <>
      <button
        className={`${styles.btnPdf} ${disabledBtn && styles.disabled} ${
          click && styles.hidden
        }`}
        onClick={() => handlePdf()}
        disabled={disabledBtn}
      >
        <>
          Générer mon PDF
          <RightArrowSmall />
        </>
      </button>
      <a
        ref={link}
        hidden={true}
        href={instance.url ?? undefined}
        download={`${'ORPI-errial'}.pdf`}
      >
        link
      </a>
    </>
  );
};

export default OrpiButton;
