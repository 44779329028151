/* eslint-disable @typescript-eslint/no-empty-function */
import { Polygon } from 'geojson';
import L, { LatLngBoundsExpression } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { advancedPlotSearchActions } from '../../../advancedPlotSearch/advancedPlotSearchSlice';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../displayManager/displayManagerSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { getSectorState, sectorActions } from '../../../sectors/sectorSlice';
import { mapActions } from '../../mapSlice';
import {
  leafletDrawDrawShapeOptions,
  leafletDrawEditShapeOptions,
} from '../lib/leafletDraw/options';
import { getMapPluginsState, mapPluginsActions } from '../mapPluginSlice';
import { ToolbarTypeEnum } from '../urbaToolbar/types/ToolbarBtnEnums';
import './toolbarTranslation';
import { DrawActionEnum } from './types/drawActionEnum';
const lf = L as any;

function DrawPlugin() {
  const fgRef = useRef<any | null>(null);
  const [drawHandler, setDrawHandler] = useState<typeof lf.Draw.Polygon | null>(
    null
  );
  const [editHandler, setEditHandler] = useState<any | null>(null);
  const [displayedSector, setDisplayedSector] = useState<EntityDisplay | null>(null);
  const map = useMap();
  const { currentSector } = useAppSelector(getSectorState);
  const { entities } = useAppSelector(getDisplayManagerState);
  const { urbaToolbar, draw } = useAppSelector(getMapPluginsState);
  const dispatch = useAppDispatch();

  // save created or edited géometry
  const saveNewGeometry = () => {
    dispatch(mapPluginsActions.drawGeometrySet(fgRef.current.toGeoJSON()));
  };

  // remove all layers from featureGroup
  const clearLayers = () => {
    if (urbaToolbar.activeToolbar === ToolbarTypeEnum.DRAW) {
      fgRef.current.clearLayers();
    }
  };

  // add polygon to FeatureGroup
  const featureGroupSet = (s: ISector) => {
    clearLayers();
    L.geoJSON(s.wkb, {
      onEachFeature: (feature, layer) => fgRef.current.addLayer(layer),
    });

    const bounds = (s.wkb.features[0].geometry as Polygon).coordinates
      .flat(1)
      .map((m: any) => [m[1], m[0]]) as LatLngBoundsExpression;

    map.fitBounds(bounds);
  };

  useEffect(() => {
    // create drawHandler
    const p = new lf.Draw.Polygon(map, leafletDrawDrawShapeOptions);
    setDrawHandler(p);

    // create edit handler
    const t = new lf.EditToolbar({
      featureGroup: fgRef.current,
      edit: {
        selectedPathOptions: leafletDrawEditShapeOptions,
      },
    });
    const e = t.getModeHandlers(map)[0].handler;
    setEditHandler(e);
  }, []);

  useEffect(() => {
    switch (draw.action) {
      //user click on LeftPanel +sector button
      case DrawActionEnum.DRAW_SECTOR:
        dispatch(mapActions.franceLayerDisplaySet(false));
        dispatch(modalsActions.drawHelperModal(true));
        drawHandler.enable();
        break;
      case DrawActionEnum.DRAW_SAVE:
        drawHandler.disable();
        dispatch(modalsActions.sectorEdit(true));
        clearLayers();
        break;
      case DrawActionEnum.DRAW_STOP:
      case DrawActionEnum.DRAW_ADVANCED_SEARCH_CANCEL:
        drawHandler.disable();
        clearLayers();
        dispatch(modalsActions.drawHelperModal(false));
        dispatch(mapPluginsActions.resetDraw());
        break;
      case DrawActionEnum.DRAW_ADVANCED_SEARCH:
        dispatch(modalsActions.drawHelperModal(true));
        break;
      case DrawActionEnum.DRAW_ADVANCED_SEARCH_START:
        drawHandler.enable();
        break;

      case DrawActionEnum.DRAW_ADVANCED_SEARCH_SAVE_GEO:
        dispatch(
          advancedPlotSearchActions.setGeoFromDraw(fgRef.current.toGeoJSON())
        );
        dispatch(mapPluginsActions.drawStop());
        break;
      //user click on LeftPanel editIcon
      case DrawActionEnum.EDIT:
        if (currentSector) {
          const sectorDisplayed =
            entities.result.find((f) => f.idIri === currentSector.idIri) ?? null;
          if (sectorDisplayed) {
            dispatch(
              displayManagerActions.entitiesRemoveByIdIri([currentSector.idIri])
            );
            setDisplayedSector(sectorDisplayed);
          }

          dispatch(modalsActions.drawHelperModal(true));
          featureGroupSet(currentSector);
          dispatch(mapActions.franceLayerDisplaySet(false));
          editHandler.enable();
        }
        break;
      // saved by the user
      case DrawActionEnum.EDIT_SAVE:
        editHandler.save();
        editHandler.disable();

        dispatch(modalsActions.sectorEdit(true));
        clearLayers();

        break;
      // canceled by the user
      case DrawActionEnum.EDIT_CANCEL:
        if (currentSector) {
          editHandler.disable();
          clearLayers();
          dispatch(mapPluginsActions.resetDraw());
          dispatch(sectorActions.currentSectorReset());
          dispatch(modalsActions.toolbarHelperModalReset());
          displayedSector &&
            dispatch(displayManagerActions.entitiesAdd([displayedSector]));
        }
        break;
      case null:
      default:
        if (drawHandler) drawHandler.disable();
        break;
    }
  }, [draw.action]);

  const handleCreated = () => {
    console.log('created');
    // drawStop is launched too
    saveNewGeometry();
    dispatch(modalsActions.toolbarHelperModalReset());
  };
  const handleEditStart = () => dispatch(modalsActions.drawHelperModal(true));
  const handleEditStop = () => {
    clearLayers();
  };
  const handleEdited = () => {
    // editedStop lanched too
    saveNewGeometry();
    dispatch(modalsActions.toolbarHelperModalReset());
    setDisplayedSector(null);
  };

  return (
    <FeatureGroup ref={fgRef}>
      <EditControl
        position="topright"
        onEdited={handleEdited}
        onCreated={handleCreated}
        onEditStart={handleEditStart}
        onEditStop={handleEditStop}
        draw={{}}
      />
    </FeatureGroup>
  );
}

export default DrawPlugin;
