import { useAppSelector } from '../../../../App/store';
import RedDownloadButton from '../../../../features/generatePDF/redDownloadButton';
import { getMapState } from '../../../../features/map/mapSlice';

function ERRIALButton() {
  const { geolocDatas } = useAppSelector(getMapState);
  const handleClick = () => {
    window.open(
      `https://errial.georisques.gouv.fr/#/?adresse=${geolocDatas?.address}#/`,
      '_blank'
    );
  };
  return (
    <RedDownloadButton handleClick={handleClick} alt="ERRIAL link" link>
      Générer ERRIAL
    </RedDownloadButton>
  );
}

export default ERRIALButton;
