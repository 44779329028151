const SVGIconComponent = (num: number, color: string) => {
  const number = num || 0;
  let xPosition = '13';
  if (number > 9) {
    xPosition = '6';
  }
  return (
    <svg width="40" height="60" viewBox="0 0 40 60" fill="none">
      <g>
        <path
          d="M19.5 36C16.8188 36 14.625 33.75 14.625 31C14.625 28.25 16.8188 26 19.5 26C22.1812 26 24.375 28.25 24.375 31C24.375 33.75 22.1812 36 19.5 36ZM19.5 11C9.2625 11 0 19.05 0 31.5C0 39.8 6.50813 49.625 19.5 61C32.4919 49.625 39 39.8 39 31.5C39 19.05 29.7375 11 19.5 11Z"
          fill={color}
        />
        <circle cx="19.5" cy="29.5" r="16.5" fill="white"></circle>
        <text
          x={xPosition}
          y="38"
          fontFamily="Helvetica, Arial, sans-serif"
          fontSize="22"
          fill={color}
        >
          {number}
        </text>
      </g>
    </svg>
  );
};

export default SVGIconComponent;
