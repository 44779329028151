import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import usePlotStudyStatus from '../../../App/hooks/usePlotStudyStatus';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import SectionHeader from '../../../components/LeftPanelModal/MenuTabs/Prospection/sectionHeader';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import useFolder from '../useFolder';

import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../displayManager/displayManagerSlice';
import { fetchAllFolderPlotStudiesForDisplayThunk } from '../../displayManager/services/thunks/fetchAllFolderAndSubsPlotStudiesForDisplayThunk';
import { modalsActions } from '../../modals/modalsSlice';
import Folder from '../folder';
import { foldersActions } from '../foldersSlice';
import styles from './folderContainer.module.scss';

const FolderContainer = () => {
  usePlotStudyStatus();
  const { allFoldersDisplayed } = useAppSelector(getDisplayManagerState);
  const { folders, isLoading } = useFolder({ forceLoad: true });
  const { entities } = useAppSelector(getDisplayManagerState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      //close all deployerd folders on close panel
      dispatch(foldersActions.allDeployedFoldersReset());
    };
  }, []);

  const handleDisplayAllFolders = (e: ChangeEventCustom) => {
    if (e.target.checked) {
      dispatch(fetchAllFolderPlotStudiesForDisplayThunk({ folders }));
    } else {
      dispatch(displayManagerActions.entitiesRemoveByType('folder'));
    }
  };

  const handleFolderModal = () => {
    dispatch(modalsActions.folderEdit(true));
  };

  return (
    <div className={styles.folderSection}>
      <SectionHeader
        checkAll={allFoldersDisplayed}
        handleCheckAllChange={handleDisplayAllFolders}
        handleAdd={handleFolderModal}
        disabled={entities.apiStatus === APIStatus.PENDING}
        type="dossier"
      />

      <div className={styles.folderContainer}>
        {!folders || isLoading ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(folders) ? (
          <p className={styles.noElement}>Aucun dossier</p>
        ) : (
          folders
            ?.filter((f) => !f.parent)
            .map((folder) => (
              <Folder key={nanoid()} folder={folder} isSubfolder={false} />
            ))
        )}
      </div>
    </div>
  );
};

export default FolderContainer;
