import { StyleSheet, Text, View } from '@react-pdf/renderer';
import SelectedEnvSvg from './svg/SelectedEnvSvg';
interface IEnvironmentGradualRowProps {
  isValue: boolean;
  label: string;
  value: number;
  color: string;
  width: number;
}

function EnvironmentGradualRow(props: IEnvironmentGradualRowProps) {
  const styles = StyleSheet.create({
    row: {
      fontSize: '9px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    polygonContainer: {
      height: '16px',
    },
    polygon: {
      marginTop: '1px',
      backgroundColor: props.color,
      width: `${props.width}px`,
      height: '14px',
    },
    imageContainer: {
      position: 'absolute',
      width: `${props.width}px`,
      display: 'flex',
      alignItems: 'center',
    },
    image: { width: '16px', heigth: '16px' },
    label: {
      color: props.color,
      textAlign: 'center',
    },
  });
  return (
    <View style={styles.row}>
      <View style={styles.polygonContainer}>
        <View style={styles.polygon}></View>
        {props.isValue && (
          <View style={styles.imageContainer}>
            <SelectedEnvSvg />
          </View>
        )}
      </View>
      <View style={styles.label}>
        <Text>{props.label}</Text>
      </View>
    </View>
  );
}

export default EnvironmentGradualRow;
