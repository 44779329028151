import { IErrialPDFFormSectionProps } from './RightPanelModalExternalPdfErrial';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';

import styles from './rightPanelModalExternalPdfErrial.module.scss';

function OwnerAndAddressSection({ errors, control }: IErrialPDFFormSectionProps) {
  return (
    <>
      <div className={styles.columnInput}>
        <p>Identité des propriétaires à indiquer sur le document</p>
        <TextInputFormPdfErrialCustom
          control={control}
          name="IdentityOwnersOnDocument"
          error={Boolean(errors.IdentityOwnersOnDocument)}
          errorMsg={errors.IdentityOwnersOnDocument?.message}
        />
      </div>
      <div className={styles.columnInput}>
        <p>Adresse du bien à indiquer sur le document</p>
        <TextInputFormPdfErrialCustom
          control={control}
          name="addressCity"
          error={Boolean(errors.addressCity)}
          errorMsg={errors.addressCity?.message}
          className={styles.spacing}
          placeholder="Ville, code postal"
        />
        <TextInputFormPdfErrialCustom
          control={control}
          name="addressStreet"
          error={Boolean(errors.addressStreet)}
          errorMsg={errors.addressStreet?.message}
          placeholder="Rue, numéro d'adresse"
        />
      </div>
    </>
  );
}

export default OwnerAndAddressSection;
