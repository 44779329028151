import { DateView } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  frFR,
  PickersLocaleText,
  PickersActionBarAction,
} from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';
import './datePickerCustom.scss';

interface IDatePickerCustom {
  value: Date | null | undefined;
  onChange: (e: Date | null) => void;
  views?: readonly DateView[] | undefined;
  format: string;
  setError?: boolean;
  minDate?: Date;
  maxDate?: Date;
  actions?: PickersActionBarAction[];
}

const customFrFRLocaleText: Partial<PickersLocaleText<Date>> = {
  ...frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  okButtonLabel: 'OK',
  cancelButtonLabel: 'Annuler',
  clearButtonLabel: 'Effacer',
};

function DatePickerCustom({
  value,
  onChange,
  views,
  format,
  setError,
  minDate,
  maxDate,
  actions = ['accept'],
}: IDatePickerCustom): JSX.Element {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={frLocale}
      localeText={customFrFRLocaleText}
    >
      <MobileDatePicker
        value={value}
        views={views}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date: Date | null) => onChange(date)}
        format={format}
        slotProps={{
          toolbar: {
            toolbarFormat: format,
            hidden: false,
            toolbarPlaceholder: 'date',
          },
          actionBar: { actions: actions },
          textField: {
            error: setError,
            placeholder: 'date',
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerCustom;
