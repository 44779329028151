import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../App/store';
import TextAreaFormCustom from '../../../../../components/Common/FormComponents/TextAreaFormCustom';
import TextFieldFormCustom from '../../../../../components/Common/FormComponents/TextFieldFormCustom';
import { studyContactContext } from '../../../../../components/RightPanelModal/TabsContent/bodys/Prospection';
import SubmitButton from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/SubmitButton';
import contactActions from '../../../../../redux/contacts/action';
import LastNameAutocomplete from '../../../../contacts/contactFormAutocomplete/LastNameAutocomplete';
import { modalsActions } from '../../../../modals/modalsSlice';
import createStudyContact from '../../../services/createStudyContact';
import updateStudyContact from '../../../services/updateStudyContact';
import ContactRoleSelect from './ContactRoleSelect';
import { contactContext } from './StudyContactContent';
import { initialContactForm, validationSchema } from './contactValidation';

interface IProspectionFormProps {
  closeForm?: () => void;
  studyContactToUpdate: IStudyContact | null;
}

function StudyContactForm({
  closeForm,
  studyContactToUpdate,
}: IProspectionFormProps) {
  const { state, update } = useContext(studyContactContext);
  const { activeContact, setContacts, setActiveContact } =
    useContext(contactContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<StudyContactForm>({
    defaultValues: initialContactForm,
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (studyContactToUpdate) {
      const ac = studyContactToUpdate.contact;
      setValue('firstName', ac.firstName);
      setValue('lastName', ac.lastName);
      setValue('phone', ac.phone ?? '');
      setValue('description', ac.description ?? '');
      setValue('address', ac.address ?? '');
      setValue('email', ac.email ?? '');
      //can be undefined if studyContact is broken
      setValue('roleIdIri', studyContactToUpdate.role?.idIri ?? '');
    }
  }, [studyContactToUpdate]);

  useEffect(() => {
    if (activeContact) {
      const ac = activeContact;
      setValue('firstName', ac.firstName);
      setValue('lastName', ac.lastName);
      setValue('phone', ac.phone ?? '');
      setValue('description', ac.description ?? ' ');
      setValue('address', ac.address ?? '');
      setValue('email', ac.email ?? '');
    }
  }, [activeContact]);

  const handleCloseForm = () => {
    reset();
    setActiveContact(null);
    if (closeForm) {
      closeForm();
    }
  };
  const studyContactRequest = async (
    body: StudyContactForm,
    type: 'update' | 'create'
  ) => {
    try {
      if (type === 'update') {
        const sc = await updateStudyContact(body, studyContactToUpdate);
        update(state?.map((m) => (m.idIri === sc.idIri ? sc : m)) ?? []);
      } else {
        const sc = await createStudyContact(body, activeContact);
        update(state?.concat(sc) ?? [sc]);
      }
      handleCloseForm();
    } catch (error) {
      console.log('error updating or creating studyContact', error);
    }
  };

  useEffect(() => {
    return () => {
      handleCloseForm();
    };
  }, []);

  const handleResearchContactClick = () => {
    reset();
    dispatch(contactActions.fetchAll.reset());
    dispatch(modalsActions.contactSearch(true));
  };

  const handleFocus = () => {
    setContacts(null);
  };

  const handleFormSubmit = (values: StudyContactForm) => {
    if (isEmpty(errors)) {
      if (studyContactToUpdate) {
        studyContactRequest(values, 'update');
      } else {
        studyContactRequest({ ...values, idIri: activeContact?.idIri }, 'create');
      }
    }
  };

  return (
    <>
      <Button className="research-contact-btn" onClick={handleResearchContactClick}>
        Rechercher un contact
      </Button>
      <form
        className="contact-form"
        id="contact-form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="top-block">
          <LastNameAutocomplete
            control={control}
            name="lastName"
            error={Boolean(errors.lastName)}
            errorMsg={errors.lastName?.message as string}
          />
          <TextFieldFormCustom
            control={control}
            name="firstName"
            placeholder="Prénom"
            error={Boolean(errors.firstName)}
            errorMsg={errors.firstName?.message as string}
            onFocus={handleFocus}
          />
          <TextFieldFormCustom
            control={control}
            name="phone"
            placeholder="Téléphone"
            error={Boolean(errors.phone)}
            errorMsg={errors.phone?.message as string}
            onFocus={handleFocus}
          />
          <TextFieldFormCustom
            control={control}
            name="email"
            placeholder="email"
            error={Boolean(errors.email)}
            errorMsg={errors.email?.message as string}
            onFocus={handleFocus}
          />
        </div>
        <div className="center-block">
          <ContactRoleSelect
            control={control}
            name="roleIdIri"
            displayEmpty
            noValue="Rôle"
            error={Boolean(errors.roleIdIri)}
            errorMsg={errors.roleIdIri?.message as string}
            onFocus={handleFocus}
          />
          <TextFieldFormCustom
            control={control}
            name="address"
            placeholder="Adresse"
            error={Boolean(errors.address)}
            errorMsg={errors.address?.message as string}
            onFocus={handleFocus}
          />
        </div>
        <div className="bottom-block">
          <TextAreaFormCustom
            control={control}
            name="description"
            className="contact-infos"
            onFocus={handleFocus}
          />
        </div>
        <SubmitButton
          label={
            !activeContact && !studyContactToUpdate
              ? 'Créer un contact'
              : 'Modifier un contact'
          }
          onClick={handleFocus}
        />
      </form>
    </>
  );
}

export default memo(StudyContactForm);
