import { Page } from '@react-pdf/renderer';
import EnvConstraints from './componants/EnvConstraints';
import GenericBloc from './componants/GenericBloc';
import LocalServitudes from './componants/LocalServitudes';
import PageFooter from './componants/PageFooter';
import PageHeader from './componants/PageHeader';
import { pdfStyles } from './pdfStyle';

interface IPageThreeProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  envConstraints: {
    isMonument: boolean;
    isRemarkableHeritage: boolean;
    isArchaeologicalPrescriptionArea: boolean;
    isPEBImpact: boolean;
    PEBValue: string;
  };
  localServitudes: OrpiServitudes;
}

function PageThree(props: IPageThreeProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={props.date} address={props.address} fixed />

      <GenericBloc title="Contraintes non environnementales">
        <EnvConstraints {...props.envConstraints} />
      </GenericBloc>

      <GenericBloc title="Servitudes locales présentes sur le Géoportail de l’urbanisme">
        <LocalServitudes {...props.localServitudes} />
      </GenericBloc>

      <PageFooter fixed />
    </Page>
  );
}

export default PageThree;
