export default function DemandSuccess() {
  return (
    <>
      <p>
        Votre demande a bien été prise en compte.
        <p className="link-for-demo">
          Vous pouvez prendre un rendez-vous pour une démo gratuite dès maintenant{' '}
          <br />
          <a
            // href="https://calendly.com/jeremy-urbanease"
            href="https://calendly.com/teo-28"
            target="_blank"
            rel="external nofollow noreferrer"
          >
            Je prends rendez-vous
          </a>
        </p>
        <br /> Merci pour votre intérêt.
      </p>
    </>
  );
}
