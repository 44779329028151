import { TextField } from '@mui/material';
import { Controller, useFormState } from 'react-hook-form';
import { IAdvancedPlotSearchCommon } from '..';
import styles from '../advancedPlotSearchContainer.module.scss';
import TooltipCustom from '../TooltipCustom';

interface ISirenNumberInputProps extends IAdvancedPlotSearchCommon {
  isOwnerEmpty: boolean;
}

function SirenNumberInput({ control, isOwnerEmpty }: ISirenNumberInputProps) {
  const { errors } = useFormState({ control });

  return (
    <>
      <div className={styles.label}>Numéro SIREN</div>

      <Controller
        control={control}
        name="sirenNumber"
        render={({ field: { value, onChange } }) => (
          <TooltipCustom error={errors.sirenNumber}>
            <TextField
              value={value}
              className={errors.sirenNumber && styles.hasError}
              variant="outlined"
              placeholder="Saisir 9 caractères"
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!isOwnerEmpty}
              type="text"
              error={Boolean(errors.sirenNumber)}
            />
          </TooltipCustom>
        )}
      />
    </>
  );
}

export default SirenNumberInput;
