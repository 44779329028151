import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../features/displayManager/displayManagerSlice';
import { dvfActions, getDvfState } from '../../features/dvf/dvfSlice';
import { foldersActions } from '../../features/folders/foldersSlice';
import { generatePDFActions } from '../../features/generatePDF/generatePDFSlice';
import { getMapState, mapActions } from '../../features/map/mapSlice';
import { studyParamsActions } from '../../features/study/slices/studyParamsSlice';
import { studyActions } from '../../features/study/slices/studySlice';
import panelsActions from '../../redux/Panels/actions';
import { getPanelState } from '../../redux/Panels/reducer';

import plotActions from '../../redux/plot/actions';
import { getPlotState } from '../../redux/plot/reducer';
import { useAppDispatch, useAppSelector } from '../store';

function useRightPanel() {
  const dispatch = useAppDispatch();
  const path = useLocation();
  const { dvfVisible } = useAppSelector(getDvfState);
  const { displayBuildingCentroid } = useAppSelector(getPlotState);
  const { townHall } = useAppSelector(getMapState);
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { entitiesBackup } = useAppSelector(getDisplayManagerState);

  function closeRightPanel() {
    if (!isEmpty(entitiesBackup)) {
      dispatch(displayManagerActions.backupReplace());
    } else {
      dispatch(displayManagerActions.reset());
    }
    if (path.pathname === '/') {
      dispatch(panelsActions.rightPanel.close());
    } else {
      dispatch(panelsActions.rightPanel.closeOnDashboard());
    }

    dispatch(studyActions.reset());
    dispatch(studyParamsActions.reset());
    dispatch(generatePDFActions.reset());

    dispatch(foldersActions.folderRightPanelDatasReset());
    if (dvfVisible) dispatch(dvfActions.setDvfOnMap(false));
    if (displayBuildingCentroid) dispatch(plotActions.buildingCentroid.set(false));
    if (townHall.displayed) dispatch(mapActions.townHallDisplaySet(false));
  }

  return { closeRightPanel, rightPanelDisplay };
}

export default useRightPanel;
