import { StyleSheet, Text, View } from '@react-pdf/renderer';
import CommonBlocTitle from './CommonBlocTitle';

const styles = StyleSheet.create({
  section: {
    width: '34%',
  },
  text: {
    backgroundColor: '#fff',
    fontWeight: 500,
    fontSize: '10px',
    marginBottom: '5px',
  },
  image: { marginBottom: '5px' },
});

interface IConstructibilityCommentProps {
  prospection: IProspectionPDF | null;
}

const ConstructibilityComment = ({ prospection }: IConstructibilityCommentProps) => {
  if (!prospection) {
    return null;
  }

  return (
    <View style={styles.section}>
      <CommonBlocTitle text="Commentaires Constructibilité" />
      <Text style={styles.text}>
        {prospection.constructComment || 'Aucun commentaire'}
      </Text>
    </View>
  );
};

export default ConstructibilityComment;
