import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../App/store';
import {
  ConnectionWorkFlowEnum,
  CONNEXION_PROCESS_DELAY,
} from './ConnectionProcessContent';
import { fetchSweepbrightPdfDataThunk } from '../../externalPdfErrial/externalPdfErrialThunks';

enum ProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  END_PROCESS,
}

interface IFetchPdfDataProcessProps {
  externalId: string;
  updateProcessStep: (value: ConnectionWorkFlowEnum) => void;
}

function FetchPdfDataProcess({
  externalId,
  updateProcessStep,
}: IFetchPdfDataProcessProps) {
  const [internalStep, setInternalStep] = useState<ProcessStepEnum>(
    ProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (internalStep) {
      case ProcessStepEnum.IDLE: {
        setInternalStep(ProcessStepEnum.PROCESS_INIT);
        break;
      }
      case ProcessStepEnum.PROCESS_INIT: {
        delay(() => {
          dispatch(
            fetchSweepbrightPdfDataThunk({
              externalId,
            })
          );
        }, CONNEXION_PROCESS_DELAY);
        setInternalStep(ProcessStepEnum.DATAS_PENDING);
        break;
      }
      case ProcessStepEnum.DATAS_PENDING:
      case ProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(ProcessStepEnum.END_PROCESS);
        break;
      case ProcessStepEnum.END_PROCESS:
        updateProcessStep(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
        break;
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div>
      {internalStep === ProcessStepEnum.DATAS_PENDING && (
        <p>Chargement des données du PDF ...</p>
      )}

      {internalStep === ProcessStepEnum.DATAS_FULFILLED && (
        <p>Données du PDF chargée</p>
      )}
    </div>
  );
}

export default FetchPdfDataProcess;
