export const contactsApiToStoreParser = (datas: any): Contacts => {
  return datas.map((data: any) => contactApiToStoreParser(data));
};

export const contactApiToStoreParser = (data: any): IContact => {
  const parsedData: IContact = {
    sortValues: { lastName: data.lastName },
    idIri: data['@id'],
    id: parseInt(data['@id'].split('/')[2], 10),
    firstName: data.firstName ?? null,
    lastName: data.lastName ?? null,
    name: null,
    phone: data.phone ?? null,
    email: data.email ?? null,
    address: data.address ?? null,
    description: data.description ?? null,
  };

  if (!parsedData.firstName && !parsedData.lastName && data.name) {
    parsedData.lastName = data.name;
  } else {
    parsedData.name = `${parsedData.firstName} ${parsedData.lastName}`;
  }

  return parsedData;
};

export const contactStoreToApiParser = (contact: ContactBase) => {
  const { firstName, lastName, address, email, phone, description } = contact;
  const parsedData = {
    firstName,
    lastName,
    address,
    email,
    phone,
    description,
  };

  return parsedData;
};
