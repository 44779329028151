import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import SVGIconComponent from './SVGIconComponent';

const MarkerIcon = (indexId: number, color: string) => {
  return L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(
      <div>{SVGIconComponent(indexId, color)}</div>
    ),
  });
};

export default MarkerIcon;
