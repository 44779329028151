import { Document } from '@react-pdf/renderer';
import PageOne from './Page1';
import PageTwo from './Page2';
import PageThree from './Page3';
import PageFour from './Page4';
import PageFive from './Page5';
import PageSix from './Page6';

function OrpiPdfContainer({ datas }: { datas: PDFDatasType | null }) {
  if (datas) {
    return (
      <Document>
        <PageOne {...datas} />
        <PageTwo {...datas} />
        <PageThree {...datas} />
        <PageFour {...datas} />
        <PageFive {...datas} />
        <PageSix {...datas} />
      </Document>
    );
  } else {
    return <Document />;
  }
}

export default OrpiPdfContainer;
