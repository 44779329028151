import { Image, StyleSheet, View } from '@react-pdf/renderer';
import DataInfoBloc from './DataInfoBloc';

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#4E55A2',
    marginBottom: '5px',
  },
  imgContainer: { width: '50%' },
  image: {
    width: '225px',
    height: '220px',
    margin: 'auto',
  },
  infosContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    alignItems: 'flex-end',
    width: '48%',
    height: '100%',
  },
});

interface IGeographyProps {
  image: string;
  plotId: string;
  areas: IAreasPDF;
  fiscalLaw: string;
  isDomTom: boolean;
}

const Geography = ({
  image,
  plotId,
  areas,
  fiscalLaw,
  isDomTom,
}: IGeographyProps) => {
  return (
    <View style={styles.section}>
      <View style={styles.imgContainer}>
        <Image
          src={`data:image/${isDomTom ? 'png' : 'jpeg'};base64,${image}`}
          style={styles.image}
        />
      </View>
      <View style={styles.infosContainer}>
        <DataInfoBloc data={plotId} text1="N° de la surface" text2="cadastrale" />
        <DataInfoBloc
          data={areas.calculated}
          text1="Surface Parcelle"
          text2="Cadastre"
          unite="m²"
        />
        <DataInfoBloc
          data={areas.built}
          text1="Surface bâtie recalculée"
          text2="emprise au sol"
          unite="m²"
        />
        <DataInfoBloc
          data={areas.free}
          text1="Surface non"
          text2="bâtie recalculée"
          unite="m²"
        />
        <DataInfoBloc data={fiscalLaw} text1="Zonage fiscal" text2="applicable" />
        <DataInfoBloc
          data={areas.real}
          text1="Surface Parcelle"
          text2="recalculée"
        />
      </View>
    </View>
  );
};

export default Geography;
