import { IErrialPDFFormSectionProps } from './RightPanelModalExternalPdfErrial';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';
import styles from './rightPanelModalExternalPdfErrial.module.scss';

function ProInfosSection({ errors, control }: IErrialPDFFormSectionProps) {
  return (
    <div>
      <h3>Informations sur le professionnel</h3>
      <div className={styles.formSection}>
        <div className={styles.rowInput}>
          <p>Nom</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="lastName"
            error={Boolean(errors.lastName)}
            errorMsg={errors.lastName?.message}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Prénom</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="firstName"
            error={Boolean(errors.firstName)}
            errorMsg={errors.firstName?.message}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Adresse mail</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="email"
            error={Boolean(errors.email)}
            errorMsg={errors.email?.message}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Téléphone</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="phone"
            error={Boolean(errors.phone)}
            errorMsg={errors.phone?.message}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Nom de la structure</p>
          <TextInputFormPdfErrialCustom
            control={control}
            name="companyName"
            error={Boolean(errors.companyName)}
            errorMsg={errors.companyName?.message}
          />
        </div>
      </div>
    </div>
  );
}

export default ProInfosSection;
