import React, { createContext, useState } from 'react';

export default function createContactCtx() {
  const defaultPageValue = 1;
  const defaultItemsPerPageValue = 10;
  const defaultContactsValue = null as Contacts | null;
  const defaultActiveContactValue = null as IContact | null;

  type UpdateNumberType = React.Dispatch<React.SetStateAction<number>>;
  type UpdateContactsType = React.Dispatch<React.SetStateAction<Contacts | null>>;
  type UpdateContactType = React.Dispatch<React.SetStateAction<IContact | null>>;

  const defaultSetPage: UpdateNumberType = () => defaultPageValue;
  const defaultSetItemsPerPage: UpdateNumberType = () => defaultItemsPerPageValue;
  const defaultSetContacts: UpdateContactsType = () => defaultContactsValue;
  const defaultSetActiveContact: UpdateContactType = () => defaultActiveContactValue;

  const ctx = createContext({
    page: defaultPageValue,
    setPage: defaultSetPage,
    itemsPerPage: defaultItemsPerPageValue,
    setItemsPerPage: defaultSetItemsPerPage,
    contacts: defaultContactsValue,
    setContacts: defaultSetContacts,
    activeContact: defaultActiveContactValue,
    setActiveContact: defaultSetActiveContact,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [page, setPage] = useState<number>(defaultPageValue);
    const [itemsPerPage, setItemsPerPage] = useState<number>(
      defaultItemsPerPageValue
    );
    const [contacts, setContacts] = useState<Contacts | null>(defaultContactsValue);
    const [activeContact, setActiveContact] = useState<IContact | null>(
      defaultActiveContactValue
    );

    return (
      <ctx.Provider
        value={{
          page,
          setPage,
          itemsPerPage,
          setItemsPerPage,
          contacts,
          setContacts,
          activeContact,
          setActiveContact,
        }}
        {...props}
      />
    );
  };

  return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}
