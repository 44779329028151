import { useLocation } from 'react-router';
import UrbaGptMapButton from '../../../features/urbaGpt/UrbaGptMapButton';
import CadastreButton from './CadastreButton';
import SatelliteViewButtons from './SatelliteViewButtons';
import './rightBottom.scss';
import { getExternalPdfErrialState } from '../../../features/externalPdfErrial/externalPdfErrialSlice';
import { useAppSelector } from '../../../App/store';
import ZoomButtons from './ZoomButtons';
import { getPanelState } from '../../../redux/Panels/reducer';

export default function RightBottomButtons() {
  const { externalPdfErrialRightPanelDisplay } = useAppSelector(
    getExternalPdfErrialState
  );
  const { rightButtons } = useAppSelector(getPanelState);
  const location = useLocation();
  const path = location.pathname;
  const pathExternalPdfErrial = path === '/external_pdf_errial';

  return (
    <div
      className={`right-bottom-buttons ${
        pathExternalPdfErrial &&
        externalPdfErrialRightPanelDisplay &&
        'external-pdf-errial'
      }`}
    >
      {path === '/' && rightButtons && (
        <>
          <UrbaGptMapButton />
          <CadastreButton />
        </>
      )}
      <div>
        <ZoomButtons />
        <SatelliteViewButtons />
      </div>
    </div>
  );
}
