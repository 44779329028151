import L from 'leaflet';
import { memo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import rouge from '../../../assets/images/pin-urbanease.svg';
import { getMapState } from '../../../features/map/mapSlice';
import { setStudyParamsThunk } from '../../../features/study/services/thunks/studyParamsThunks';
import panelsActions from '../../../redux/Panels/actions';
import { getPanelState } from '../../../redux/Panels/reducer';
import { favoritePlacesActions } from '../../../redux/favoritePlaces/actions';

import { useLocation } from 'react-router';
import { customIcon } from '../../../shared/components/map/customIcon';
import MarkerDivIcon from './MarkerDivIcon';
import styles from './activePlot.module.scss';

const ActivePlotMarker = memo(
  (): JSX.Element => {
    const [firstDisplay, setFirstDisplay] = useState(true);
    const { plotDatas } = useAppSelector(getMapState);
    const { rightPanelDisplay } = useAppSelector(getPanelState);
    const map = useMap();
    const location = useLocation();
    const pathExternalPdfErrial = location.pathname === '/external_pdf_errial';

    useEffect(() => {
      if (!rightPanelDisplay) {
        setFirstDisplay(true);
      }
      map.getContainer().classList.remove('loading-plot');
    }, [plotDatas?.markerLatLng]);

    const dispatch = useAppDispatch();

    const handleRightPanelParcelleOpen = () => {
      if (firstDisplay) {
        setFirstDisplay(false);
      }

      dispatch(
        setStudyParamsThunk({
          type: 'plotStudy',
          noCheckExisting: false,
          idIri: null,
          managmentLoader: false,
        })
      );
      dispatch(favoritePlacesActions.windowDisplay.set(false));
      dispatch(panelsActions.studyContent.show('geographie'));
    };

    useEffect(() => {
      if (rightPanelDisplay) {
        setFirstDisplay(false);
      }
    }, [rightPanelDisplay]);

    return (
      <div>
        {plotDatas?.markerLatLng && (
          <Marker
            zIndexOffset={1000}
            eventHandlers={{
              click: () => {
                if (!pathExternalPdfErrial) handleRightPanelParcelleOpen();
              },
              add: () => {
                // openPopup();
              },
            }}
            position={plotDatas.markerLatLng}
            icon={
              firstDisplay && !pathExternalPdfErrial
                ? L.divIcon({
                    className: styles.icon,
                    html: ReactDOMServer.renderToString(<MarkerDivIcon />),
                  })
                : customIcon(rouge)
            }
          ></Marker>
        )}
      </div>
    );
  }
);

ActivePlotMarker.displayName = 'ActivePlotMarker';
export default ActivePlotMarker;
