import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { contactApiToStoreParser, contactStoreToApiParser } from '../contactParser';

export const updateContact = async (contactIdIri: string, params: ContactBase) => {
  try {
    if (params.firstName && params.lastName) {
      const parseForApi = contactStoreToApiParser(params);

      const result = await updateEntity({
        idIri: contactIdIri,
        body: parseForApi,
      });

      const parsedResponse = contactApiToStoreParser(result);

      return parsedResponse;
    } else {
      return Promise.reject({
        status: 422,
        message: 'one or more params are missing',
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
