import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  section: {
    padding: '0 10px',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#4E55A2',
    color: '#fff',
    marginBottom: '5px',
  },
  addres: { fontSize: '16px' },
  indication: { fontSize: '8px', fontStyle: 'italic' },
  parcelId: { fontSize: '11px', fontWeight: 500 },
});

interface IReferencesProps {
  address: string;
}

const References = ({ address }: IReferencesProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.addres}>{address}</Text>
      <Text style={styles.indication}>
        adresse indicative, sous réserve de vérification
      </Text>
    </View>
  );
};

export default References;
