import * as yup from 'yup';
export const initialValues: AdvancedPlotSearchForm = {
  isDrawingZone: false,
  isBodacc: false,
  maxGround: undefined,
  maxPlot: undefined,
  maxBuiltPercent: undefined,
  minGround: undefined,
  minPlot: undefined,
  minBuiltPercent: undefined,
  ownerName: '',
  plotType: 'all',
  polygon: undefined,
  sirenNumber: '',
  zone: '',
  zoneChild: '',
  types: '',
  empriseBatiMin: undefined,
  empriseBatiMax: undefined,
  buildingHeightMin: undefined,
  buildingHeightMax: undefined,
  copro: '',
  ownerDatas: '',
};

export const radiobuttonsPlotType = [
  {
    value: 'all',
    label: 'Toutes',
  },
  {
    value: 'construct_ground',
    label: 'Bâties',
  },
  {
    value: 'excludebati',
    label: 'Non bâties',
  },
];

const generic = () =>
  yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0, 'Nombre négatif interdit')
    .nullable();

export const formSchema = yup.object().shape({
  zone: yup.string(),
  zoneChild: yup.string(),
  isDrawingZone: yup.bool(),
  isBodacc: yup.bool(),
  plotType: yup.string(),
  minPlot: generic()
    .min(0, 'Nombre négatif interdit')
    .when('maxPlot', {
      is: (val: string | number | undefined) =>
        val !== undefined && val !== 0 && val !== '',
      then: generic().max(yup.ref('maxPlot'), 'Mini doit être inférieur à maxi'),
    }),
  maxPlot: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0: {
        return generic();
      }
      default:
        return generic().when('minPlot', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: generic().min(yup.ref('minPlot'), 'Maxi doit être supérieur à mini'),
        });
    }
  }),
  minGround: generic().when('maxGround', {
    is: (val: number | undefined) => val !== undefined && val !== 0,
    then: generic().max(yup.ref('maxGround'), 'Mini doit être inférieur à maxi'),
  }),
  maxGround: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic().when('minGround', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: generic().min(
            yup.ref('minGround'),
            'Maxi doit être supérieur à mini'
          ),
        });
    }
  }),
  minBuiltPercent: generic()
    .max(100, '100% maximum')
    .when('maxBuiltPercent', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: generic().max(
        yup.ref('maxBuiltPercent'),
        'Mini doit être inférieur à maxi'
      ),
    }),
  maxBuiltPercent: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic()
          .max(100, '100% maximum')
          .when('minBuiltPercent', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: yup
              .number()
              .min(yup.ref('minBuiltPercent'), 'Maxi doit être supérieur à mini'),
          });
    }
  }),
  ownerName: yup
    .string()
    .nullable()
    .transform((v) => v ?? null),
  sirenNumber: yup.lazy((value) => {
    const checkedValue = value ? value.length : 0;

    switch (checkedValue) {
      case 0:
        return yup.string().nullable();
      default:
        return yup
          .string()
          .length(9, 'le n° SIREN doit contenir 9 caractères')
          .matches(/[a-zA-Z0-9]{9}/, 'Lettres et chiffres uniquement');
    }
  }),
  types: yup.string(),
  empriseBatiMin: generic()
    .min(0, 'Nombre négatif interdit')
    .when('empriseBatiMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: generic().max(
        yup.ref('empriseBatiMax'),
        'Mini doit être inférieur à maxi'
      ),
    }),
  empriseBatiMax: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic()
          .min(0, 'Nombre négatif interdit')
          .when('empriseBatiMin', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: generic().min(
              yup.ref('empriseBatiMin'),
              'Maxi doit être supérieur à mini'
            ),
          });
    }
  }),
  buildingHeightMin: generic()
    .min(0, 'Nombre négatif interdit')
    .when('buildingHeightMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: generic().max(
        yup.ref('buildingHeightMax'),
        'Mini doit être inférieur à maxi'
      ),
    }),
  buildingHeightMax: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic().when('buildingHeightMin', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: generic().min(
            yup.ref('buildingHeightMin'),
            'Maxi doit être supérieur a mini'
          ),
        });
    }
  }),
  copro: yup.string(),
  ownerDatas: yup.string(),
});
