import { Image, Text, View } from '@react-pdf/renderer';
import FooterBackground from '../../../../../assets/images/orpiPdf/footerBackground.png';
import UrbaLogoWhite from '../../../../../assets/images/orpiPdf/urbaLogoWhite.png';
import { pdfStyles } from '../pdfStyle';

function PageFooter({ fixed }: { fixed?: boolean }) {
  return (
    <View style={pdfStyles.pageFooter} fixed={fixed}>
      <View style={pdfStyles.pageFooterTextContainer}>
        <Text>
          Les estimations réalisées par Urbanease n’ont pas valeur d’expertise.
          Urbanease ne peut garantir l’opposabilité et l’exhaustivité des données
          mises à disposition dans ses solutions ou dans ce document. Urbanease
          conseil les utilisateurs de se rapprocher de l’administration et des
          autorités compétentes pour vérifier l’exactitude de ces informations et
          leurs modalités d’application. Par ailleurs Urbanease ne saurait être tenue
          responsable de toutes pertes ou manque à gagner découlant de décisions
          fondées sur une étude, analyse ou autre information présente dans ses
          solutions.
        </Text>
      </View>
      <View style={pdfStyles.pageFooterRedZone}>
        <Image src={FooterBackground} />
        <View style={pdfStyles.pageFooterLogoContainer}>
          <Text>Powered by</Text>
          <Image src={UrbaLogoWhite} style={pdfStyles.pageFooterLogoUrba} />
        </View>
      </View>
    </View>
  );
}

export default PageFooter;
