import React from 'react';
import GenericInputResearch from './GenericInputResearch';
import GenericSelectSort from './GenericSelectSort';
import SortOrder from './SortOrder';

interface IFilterSortBlocProps {
  setSearchPattern?: React.Dispatch<React.SetStateAction<string>>;
  setSortKey?: React.Dispatch<React.SetStateAction<string>>;
  setOrder?: React.Dispatch<React.SetStateAction<Order>>;
  selectItems?: ISelectItem[];
  inputPlaceHolder?: string;
  selectPlaceHolder?: string;
}

export default function FilterSortBloc({
  setSearchPattern,
  setSortKey,
  selectItems,
  setOrder,
  inputPlaceHolder,
  selectPlaceHolder,
}: IFilterSortBlocProps) {
  return (
    <div className="filter-sort-bloc">
      {setSearchPattern && (
        <GenericInputResearch
          setSearchPattern={setSearchPattern}
          placeHolder={inputPlaceHolder}
        />
      )}
      {setSortKey && setOrder && selectItems && (
        <div className="right-bloc">
          <GenericSelectSort
            setSortKey={setSortKey}
            items={selectItems}
            noValue={selectPlaceHolder}
          />
          <SortOrder setOrder={setOrder} />
        </div>
      )}
    </div>
  );
}
