import { FormControl, TextareaAutosize } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../../App/store';
import { updateStudyThunk } from '../../../../../../../features/study/services/thunks/studyThunks';
import { ProspectionTabBlocsProps } from '../../../Prospection';
import SubmitButton from '../SubmitButton';
import './constructComment.scss';

export default function ConstructComment({
  study,
  updateAllowed,
}: ProspectionTabBlocsProps) {
  const [comment, setComment] = useState<string>('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setComment(study?.comment || '');
  }, [study]);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  }, []);

  const handleUpdate = useCallback((): void => {
    if (study) {
      dispatch(
        updateStudyThunk({
          body: { comment },
        })
      );
    }
  }, [study, comment]);

  return (
    <div className="construct-comment-block">
      <FormControl variant="outlined">
        <TextareaAutosize
          name="comment"
          value={comment}
          onChange={handleChange}
          placeholder="Commentaire ..."
          minRows={8}
          maxRows={8}
          className="construct-comment"
          disabled={!updateAllowed}
        />
      </FormControl>
      <SubmitButton
        label="Enregistrer le commentaire"
        onClick={handleUpdate}
        disabled={!updateAllowed}
      />
    </div>
  );
}
