import store from '../../../App/store';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { createContact } from '../../contacts/contactRequests';
import { updateContact } from '../../contacts/services/updateContact';
import { studyContactApiToStoreParser } from '../utils/parsers/studyContactParser';
async function createStudyContact(
  body: StudyContactForm,
  studyContact: IContact | null
) {
  try {
    const state = store.getState();
    const contactRoles = state.app.contactRoles.result;
    const { idIri, type } = state.studyParams;
    const { companyIdIri } = state.company;

    if (idIri && companyIdIri && type && contactRoles && body) {
      const baseUrl =
        type === 'folder' ? '/folder_contacts' : '/plot_study_contacts';

      let contactIdIri = studyContact?.idIri ?? null;
      if (studyContact) {
        await updateContact(studyContact?.idIri, body);
      } else {
        const created = await createContact(body, companyIdIri);
        contactIdIri = created.idIri;
      }

      const studyContactResult = await postEntity({
        endpoint: baseUrl,
        body: {
          contact: contactIdIri,
          [type]: idIri,
          contactRole: body.roleIdIri,
        },
      });

      const parsedStudyContact = studyContactApiToStoreParser(
        studyContactResult,
        contactRoles
      );

      return parsedStudyContact;
    } else {
      return Promise.reject(paramsNotFound('createStudyContact'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createStudyContact;
