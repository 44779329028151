import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import { authActions } from '../../../features/auth/authSlice';
import {
  companyActions,
  getCompanyState,
} from '../../../features/company/companySlice';
import { modalsActions } from '../../modals/modalsSlice';
import { changeCompanyThunk, setAuthenticatedCompanyThunk } from '../companyThunks';

import './chooseCompanyModal.scss';

export default function ChooseCompanyModal() {
  const dispatch = useAppDispatch();
  const { companies, companyIdIri, companyId } = useAppSelector(getCompanyState);

  const handleCloseModal = () => {
    dispatch(modalsActions.companyChange(false));
  };

  const handleCompanyClick = (companyToken: TokenCompany) => {
    try {
      if (companyIdIri && companyId) {
        dispatch(changeCompanyThunk({ company: companyToken }));
        dispatch(setAuthenticatedCompanyThunk({ companyId: companyId }));
      } else {
        dispatch(
          companyActions.companyInit({
            companyId: companyToken.id,
            companyIdIri: companyToken.idIri,
          })
        );
      }
      dispatch(authActions.setIsLogged(true));
    } catch (error) {
      console.log(error);
    }
    dispatch(modalsActions.companyChange(false));
  };

  return (
    <GenericModal
      closeModal={handleCloseModal}
      modalType={GenericModalEnum.INFO}
      actionType={
        companyIdIri ? GenericModalActionEnum.OK : GenericModalActionEnum.NONE
      }
      okButtonContent="Annuler"
      title="Merci de sélectionner le compte auquel vous souhaitez vous connecter pour cette session:"
      okCallback={handleCloseModal}
    >
      <div className="choose-company-modal">
        <div className="company-list">
          {companies.map((c: TokenCompany) => (
            <button key={nanoid()} onClick={() => handleCompanyClick(c)}>
              {c.name} {c.postalCode ? `( ${c.postalCode} )` : ''}
            </button>
          ))}
        </div>
      </div>
    </GenericModal>
  );
}
