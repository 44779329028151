import getEntityFromIdIri from '../../../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../../../lib/parsersTools/getIdFromIdIri';

export const studyContactsApiToStoreParser = (
  datas: any[],
  contactRoles: ContactRoles
) => {
  const parsedDatas: StudyContacts = datas.map((m) =>
    studyContactApiToStoreParser(m, contactRoles)
  );

  return parsedDatas;
};

export const studyContactApiToStoreParser = (
  data: any,
  contactRoles: ContactRoles
) => {
  try {
    const d = data.contact;

    const parsedData: IStudyContact = {
      idIri: data['@id'],
      id: getIdFromIdIri(data.contact['@id']),
      contact: {
        sortValues: { lastName: d.lastName },
        idIri: data.contact['@id'],
        id: getIdFromIdIri(data.contact['@id']),
        firstName: d.firstName ?? '',
        lastName: d.lastName ?? '',
        phone: d.phone ?? null,
        email: d.email ?? null,
        address: d.address ?? null,
        description: d.description ?? null,
        name: d.name ?? null,
      },
      role: getEntityFromIdIri(data.contactRole, contactRoles) as ContactRole,
    };

    return parsedData;
  } catch (error) {
    throw new Error('parser error');
  }
};
