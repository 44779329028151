import { nanoid } from '@reduxjs/toolkit';
import { ProcessStepEnum } from '../../auth/connectionProcess/CompanyProcess';
import './chooseCompanyModal.scss';

interface IChooseCompanyContentProps {
  companies: TokenCompanies;
  onCompanyChoice: (company: TokenCompany, step?: ProcessStepEnum) => void;
}

function ChooseCompanyContent({
  companies,
  onCompanyChoice,
}: IChooseCompanyContentProps) {
  const handleCompanyClick = (company: TokenCompany) => {
    onCompanyChoice(company, ProcessStepEnum.COMPANY_CHOSEN);
  };

  return (
    <div className="choose-company-modal">
      <h3>Choix de la companie</h3>
      <div className="company-list">
        {companies.map((c: TokenCompany) => (
          <button key={nanoid()} onClick={() => handleCompanyClick(c)}>
            {c.name} {c.postalCode ? `( ${c.postalCode} )` : ''}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ChooseCompanyContent;
