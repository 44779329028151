import { FormControl, TextareaAutosize, Tooltip } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  popper: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#d20000',
      fontWeight: 700,
      fontSize: '0.8em',
      maxWidth: 'unset',
    },
  },
  root: {
    '&.error-control': {
      backgroundColor: '#ffecfc',
    },
  },
});

interface ITextAreaFormCustomProps {
  name: string;
  className?: string;
  onFocus?: () => void;
  control: any;
  rowsMax?: number;
  rowsMin?: number;
  placeholder?: string;
  errorMessage?: string;
  error?: boolean;
  trigger?: any;
}

function TextAreaInputFormPdfErrial({
  name,
  control,
  onFocus,
  className,
  rowsMax = 8,
  rowsMin = 8,
  placeholder = 'Message ...',

  error,
  errorMessage,
  trigger,
}: ITextAreaFormCustomProps) {
  const { classes } = useStyles();
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, onChange: any) => {
    const value = e.target.value;

    onChange(value);
    trigger && trigger(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl
          variant="outlined"
          classes={classes}
          className={error ? 'error-control' : ''}
        >
          <Tooltip
            open={error}
            title={errorMessage ?? ''}
            placement="top"
            arrow
            classes={classes}
          >
            <TextareaAutosize
              value={value}
              onChange={(e) => handleChange(e, onChange)}
              placeholder={placeholder}
              maxRows={rowsMax}
              minRows={rowsMin}
              className={className}
              onFocus={handleFocus}
            />
          </Tooltip>
        </FormControl>
      )}
    />
  );
}

export default TextAreaInputFormPdfErrial;
