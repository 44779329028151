import useContactRoles from '../../../../../App/hooks/useContactRoles';
import SelectFormCustom from '../../../../../components/Common/FormComponents/SelectFormCustom';

interface IContactRoleSelectProps {
  name: string;
  control: any;
  className?: string;
  label?: nullable<string>;
  disabled?: boolean;
  displayEmpty?: boolean;
  noValue?: string;
  error?: boolean;
  errorMsg?: string;
  onFocus?: () => void;
}
export default function ContactRoleSelect({
  control,
  name,
  className,
  displayEmpty,
  label,
  noValue,
  error,
  errorMsg,
  onFocus,
}: IContactRoleSelectProps) {
  const { contactRolesForSelect } = useContactRoles();
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <SelectFormCustom
      control={control}
      name={name}
      className={className}
      displayEmpty={displayEmpty}
      label={label}
      noValue={noValue}
      items={contactRolesForSelect}
      error={error}
      errorMsg={errorMsg}
      onFocus={handleFocus}
    />
  );
}
