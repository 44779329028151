import CreateIcon from '@mui/icons-material/Create';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { foldersActions } from '../../features/folders/foldersSlice';
import { mapActions } from '../../features/map/mapSlice';
import {
  getMapPluginsUrbaToolbarState,
  mapPluginsActions,
} from '../../features/map/plugins/mapPluginSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { sectorActions } from '../../features/sectors/sectorSlice';
import { IIconActionProps } from './ItemActions';
import TooltipIcon from './TooltipIcon';
import styles from './itemActions.module.scss';

function EditIcon<T>({ type, item, updateAllowed }: IIconActionProps<T>) {
  const [action, setAction] = useState<string>('');
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { isManager, isAdmin } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === 'folder') {
      if (item.parent) {
        setAction('Editer sous-dossier');
      } else {
        setAction('Editer dossier');
      }
    } else {
      if (item.parent) {
        setAction('Editer sous-secteur');
      } else {
        setAction('Editer secteur');
      }
    }
  }, []);

  const handleEdit = () => {
    if (type === 'folder') {
      if (item.parent) {
        dispatch(
          foldersActions.subFolderParentSet((item as IFolder).parent as string)
        );
      }
      dispatch(foldersActions.folderForActionSet(item as IFolder));
      dispatch(modalsActions.folderEdit(true));
    } else {
      if (activeToolbar === null && (isAdmin || isManager)) {
        dispatch(mapActions.franceLayerDisplaySet(false));
        dispatch(mapPluginsActions.edit());
        dispatch(sectorActions.currentSectorSet(item as ISector));
      }
    }
  };

  return (
    <>
      {updateAllowed && (
        <TooltipIcon action={action}>
          <CreateIcon
            className={`${styles.genericIcon} ${styles.edit}`}
            onClick={handleEdit}
          />
        </TooltipIcon>
      )}
    </>
  );
}

export default EditIcon;
