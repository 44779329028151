import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { nanoid } from '@reduxjs/toolkit';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import styles from './selectFormCustom.module.scss';
import TooltipCustom from '../../../features/advancedPlotSearch/advancedPlotSearchContainer/TooltipCustom';

interface ISelectFormCustom {
  name: string;
  control: any;
  items: ISelectItem[];
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  disabled?: boolean;
  className?: string;
  displayEmpty?: boolean;
  noValue?: string;
  error?: boolean;
  errorMsg?: string;
  noChoiceDisabled?: boolean;
  onFocus?: () => void;
  errorTooltip?: FieldError;
}

const useStyles = makeStyles()({
  root: {
    transform: 'translate(14px, 12px) scale(1)',
  },
});

export default function SelectFormCustom({
  name,
  control,
  items = [],
  onChange,
  label,
  disabled,
  className,
  displayEmpty,
  noValue,
  error,
  errorMsg,
  onFocus,
  noChoiceDisabled = true,

  errorTooltip,
}: ISelectFormCustom): JSX.Element {
  const { classes } = useStyles();

  const onChangeIntern = (
    e: ChangeEventCustom,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    field.onChange(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <div className="select-form">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {errorTooltip ? (
              <TooltipCustom error={errorTooltip}>
                <FormControl
                  variant="outlined"
                  disabled={disabled}
                  className={`${className} ${error ? styles.error : ''}`}
                  error={error}
                >
                  {label ? (
                    <InputLabel className={classes.root} id={name}>
                      {label}
                    </InputLabel>
                  ) : null}
                  <Select
                    {...field}
                    labelId={name}
                    onChange={(e) => onChangeIntern(e, field)}
                    displayEmpty={displayEmpty}
                    disabled={disabled}
                    error={error}
                    onFocus={handleFocus}
                  >
                    <MenuItem
                      key={nanoid()}
                      className="select-menu-item"
                      value=""
                      disabled={noChoiceDisabled}
                    >
                      {noValue}
                    </MenuItem>
                    {items.map((elt) => (
                      <MenuItem
                        key={nanoid()}
                        value={elt.value}
                        className="select-menu-item"
                      >
                        {elt.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TooltipCustom>
            ) : (
              <FormControl
                variant="outlined"
                disabled={disabled}
                className={className}
                error={error}
              >
                {label ? (
                  <InputLabel className={classes.root} id={name}>
                    {label}
                  </InputLabel>
                ) : null}
                <Select
                  {...field}
                  labelId={name}
                  onChange={(e) => onChangeIntern(e, field)}
                  displayEmpty={displayEmpty}
                  disabled={disabled}
                  error={error}
                  onFocus={handleFocus}
                >
                  <MenuItem
                    key={nanoid()}
                    className="select-menu-item"
                    value=""
                    disabled={noChoiceDisabled}
                  >
                    {noValue}
                  </MenuItem>
                  {items.map((elt) => (
                    <MenuItem
                      key={nanoid()}
                      value={elt.value}
                      className="select-menu-item"
                    >
                      {elt.display}
                    </MenuItem>
                  ))}
                </Select>
                {error && <FormHelperText>{errorMsg}</FormHelperText>}
              </FormControl>
            )}
          </>
        )}
      />
    </div>
  );
}
