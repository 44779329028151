import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getMapState } from '../../../features/map/mapSlice';
import { getPlotState } from '../../../redux/plot/reducer';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import PLUButton from '../PluButton';
import { getPluState, pluActions } from '../pluSlice';
import styles from './pluLegend.module.scss';
import PluStatus from './PluStatus';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: { paddingBottom: '3px', paddingTop: '3px' },
  label: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
  },
});

const PluLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { geolocDatas } = useAppSelector(getMapState);
  const { pluZone, updateInseeLoader } = useAppSelector(getPluState);
  const { parcelle } = useAppSelector(getPlotState);

  const dispatch = useAppDispatch();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleButtonClick();
  };

  const handleButtonClick = () => {
    dispatch(
      pluZone.displayed ? pluActions.pluLayerHide() : pluActions.pluLayerShow()
    );
  };

  if (!isEmpty(parcelle?.pluStatus)) {
    return (
      <div className={styles.pluLegend}>
        <PluStatus status={parcelle?.pluStatus} />
      </div>
    );
  }

  if (updateInseeLoader) {
    return (
      <div className={`${styles.pluLegend} ${styles.noPlu}`}>
        <IndividualCircularLoader size={100} />
      </div>
    );
  }

  if (pluZone.noUrl) {
    return (
      <div className={`${styles.pluLegend} ${styles.noPlu}`}>
        <p>PLU pas encore disponible.</p>
        <p>Demander la disponibilité du PLU pour cette commune :</p>
        <PLUButton />
      </div>
    );
  }

  return (
    <div className={styles.pluLegend}>
      <Tooltip
        title={
          Boolean(geolocDatas?.city && geolocDatas?.postalCode) && pluZone.layer
            ? "Le PLU n'est pas disponible pour cette commune"
            : 'Vous devez sélectionner une ville'
        }
        placement="top"
        disableHoverListener={
          Boolean(geolocDatas?.city && geolocDatas?.postalCode) &&
          Boolean(pluZone.layer)
        }
      >
        <div className={styles.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={pluZone.displayed}
                onChange={onChange}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={!(geolocDatas?.city && geolocDatas?.postalCode)}
              />
            }
            classes={classes}
            label="Afficher le plan de zone"
            labelPlacement={'end'}
          />
        </div>
      </Tooltip>

      <Button className={styles.pluButton} onClick={handleButtonClick}>
        Afficher sur la carte
      </Button>
    </div>
  );
};

export default memo(PluLegend);
