import { GeoJSON } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import MarkerBase from '../../../components/Map/Common/MarkerBase';

import BrownfieldPopup from './BrownfieldPopup';
import { brownfieldsActions, getBrownfieldsState } from '../brownfieldsSlice';
import { useLayoutEffect } from 'react';
import { getAuthState } from '../../auth/authSlice';
import { getMapState } from '../../map/mapSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { fetchBrownfieldsThunk } from '../brownFieldsThunk';

const jsonStyle = {
  fillColor: '#D16002',
  weight: 3,
  color: '#D16002',
  fillOpacity: 0.4,
};

function BrownfieldsOnMap() {
  const { brownfields, brownfieldsVisible } = useAppSelector(getBrownfieldsState);
  const { deniedZone } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (geolocDatas?.inseeCode || (geolocDatas?.postalCode && !deniedZone)) {
      if (brownfields.apiStatus !== APIStatus.PENDING) {
        dispatch(brownfieldsActions.setDisplayBrownfields(false));
        dispatch(fetchBrownfieldsThunk({ insee: geolocDatas?.inseeCode ?? null }));
      }
    }
  }, [geolocDatas?.inseeCode, geolocDatas?.postalCode]);

  return (
    <>
      {brownfieldsVisible &&
        brownfields.result?.map((m) => {
          return (
            <>
              <GeoJSON data={m.geometry} style={jsonStyle} />
              <MarkerBase markerLatLng={[m.lat, m.lng]} markerUrl="orange">
                <BrownfieldPopup content={m} />
              </MarkerBase>
            </>
          );
        })}
    </>
  );
}

export default BrownfieldsOnMap;
