import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { createDashboardEngineParams } from '../../redux/common/saga';
import contactActions from '../../redux/contacts/action';
import { getContactState } from '../../redux/contacts/reducer';
import { getCompanyState } from '../company/companySlice';

export default function useContacts() {
  const { contacts, totalContacts, contactForAction } = useAppSelector(
    getContactState
  );
  const { companyIdIri } = useAppSelector(getCompanyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyIdIri) {
      const criterias = createDashboardEngineParams({});
      dispatch(contactActions.fetchAll.request(criterias));
    }
  }, [companyIdIri]);

  const getContactById = (idIri: string): nullable<IContact> => {
    return contacts?.find((c) => c.idIri === idIri);
  };

  const contactsForSelect = () => {
    return contacts?.map((c) => ({
      value: c.idIri,
      display: `${c.firstName} ${c.lastName}`,
    }));
  };

  return {
    contacts,
    getContactById,
    contactsForSelect,
    totalContacts,
    contactForAction,
  };
}
