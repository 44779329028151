import { useEffect, useState } from 'react';
import styles from './urbaGpt.module.scss';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getUrbaGptState, urbaGptActions } from './urbaGptSlice';
import { isEmpty } from 'lodash';
import panelsActions from '../../redux/Panels/actions';
import { getPanelState } from '../../redux/Panels/reducer';
import { urbaGptHistoryThunk } from './urbaGptThunk';
import { ReactComponent as LogoIA } from '../../assets/images/logo-ai-white.svg';
import { getAuthState } from '../auth/authSlice';

function UrbaGptMapButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const { conversation, urbaGptIsVisible } = useAppSelector(getUrbaGptState);
  const { isLogged } = useAppSelector(getAuthState);
  const { rightPanelDisplay, leftPanelDisplay } = useAppSelector(getPanelState);
  const [open, setOpen] = useState(urbaGptIsVisible);

  const handleUrbaGpt = () => {
    if (!open) {
      if (isEmpty(conversation.result)) dispatch(urbaGptHistoryThunk());
      if (rightPanelDisplay) dispatch(panelsActions.rightPanel.close());
      if (leftPanelDisplay) dispatch(panelsActions.leftPanel.collapse());
    }
    dispatch(urbaGptActions.setUrbaGptIsVisible(!open));
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(urbaGptIsVisible);
  }, [urbaGptIsVisible]);

  return (
    <div className={styles.urbaGpt}>
      <button
        onClick={() => handleUrbaGpt()}
        className={styles.urbaGptMapBtn}
        disabled={!isLogged}
      >
        <LogoIA /> {open ? 'Masquer UrbaGPT ' : 'UrbaGPT'}
      </button>
    </div>
  );
}

export default UrbaGptMapButton;
