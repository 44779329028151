import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import plotActions from '../../../redux/plot/actions';
import { getPlotState } from '../../../redux/plot/reducer';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';
import { mapActions } from '../../map/mapSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { ConnectionWorkFlowEnum } from './ConnectionProcessContent';

enum ProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  DATAS_REJECTED,
  END_PROCESS,
  ERROR_FETCH_PLOT_PARAM,
  ERROR_INACTIVE_USER,
  ERROR_NOT_FOUND,
  ERROR_NOT_AUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_SERVER,
}

interface IFetchPlotProcessProps {
  queries: { [x: string]: string };
  externalId: string;
  updateProcessStep: (value: ConnectionWorkFlowEnum) => void;
}

function FetchPlotProcess({
  queries,
  externalId,
  updateProcessStep,
}: IFetchPlotProcessProps) {
  const { parcelle } = useAppSelector(getPlotState);
  const [internalStep, setInternalStep] = useState<ProcessStepEnum>(
    ProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (parcelle) {
      setInternalStep(ProcessStepEnum.DATAS_FULFILLED);
    }
  }, [parcelle]);

  useEffect(() => {
    switch (internalStep) {
      case ProcessStepEnum.IDLE: {
        const { latitude, longitude, departement_code } = queries;

        if (
          latitude &&
          longitude &&
          departement_code &&
          latitude !== 'null' &&
          longitude !== 'null' &&
          departement_code !== 'null'
        ) {
          setInternalStep(ProcessStepEnum.PROCESS_INIT);
        } else {
          if (latitude || longitude || departement_code) {
            dispatch(
              modalsActions.alert({
                status: true,
                context: 'noPlotFounded',
                modalType: GenericModalEnum.INFO,
              })
            );
          }

          setInternalStep(ProcessStepEnum.END_PROCESS);
        }
        break;
      }
      case ProcessStepEnum.PROCESS_INIT: {
        const { latitude, longitude, departement_code } = queries;
        const lat = parseFloat(latitude);
        const lon = parseFloat(longitude);

        if (!lat || !lon) {
          setInternalStep(ProcessStepEnum.ERROR_NOT_FOUND);
        }

        addrServProcessAsync(lat, lon).then(
          (response) => {
            if (response) {
              dispatch(mapActions.geolocSet(response));
              dispatch(
                plotActions.fetchPlot.request({
                  data: { lat, lon, department: departement_code },
                })
              );

              setInternalStep(ProcessStepEnum.END_PROCESS);
            }
          },
          (error) => {
            setInternalStep(ProcessStepEnum.ERROR_NOT_FOUND);
          }
        );

        setInternalStep(ProcessStepEnum.DATAS_PENDING);
        break;
      }
      case ProcessStepEnum.DATAS_PENDING:
        break;
      case ProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(ProcessStepEnum.END_PROCESS);

        break;
      case ProcessStepEnum.ERROR_FETCH_PLOT_PARAM:
        break;
      case ProcessStepEnum.ERROR_NOT_FOUND:
        delay(() => {
          updateProcessStep(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
        }, 1000);
        break;
      case ProcessStepEnum.END_PROCESS:
        if (externalId) {
          updateProcessStep(ConnectionWorkFlowEnum.START_FETCH_PDF_DATA_PROCESS);
        } else {
          updateProcessStep(ConnectionWorkFlowEnum.REDIRECT_TO_HOME);
        }
        break;
      default:
        break;
    }
  }, [queries, internalStep]);

  return (
    <div>
      {internalStep === ProcessStepEnum.DATAS_PENDING && (
        <p>Chargement de la parcelle ...</p>
      )}

      {internalStep === ProcessStepEnum.DATAS_FULFILLED && (
        <p>Parcelle chargée, vous allez etre redirigé à l&#039;accueil</p>
      )}

      {internalStep === ProcessStepEnum.ERROR_NOT_FOUND && (
        <p>Parcelle non trouvée, vous allez etre redirigé à l&#039;accueil</p>
      )}
    </div>
  );
}

export default FetchPlotProcess;
