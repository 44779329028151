import {
  SECTOR_DRAW_COLOR,
  SECTOR_EDIT_COLOR,
} from '../../../../../shared/constants';

export const leafletDrawDrawShapeOptions = {
  shapeOptions: {
    stroke: true,
    color: SECTOR_DRAW_COLOR,
    weight: 4,
    opacity: 0.2,
    fill: true,
    clickable: true,
  },
};

export const leafletDrawEditShapeOptions = {
  color: SECTOR_EDIT_COLOR,
  fillColor: SECTOR_EDIT_COLOR,
  dashArray: '10, 10',
  fillOpacity: 0.2,
};
