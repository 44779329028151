import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';

const styles = StyleSheet.create({
  localServitude: {
    marginTop: '10px',
    width: '45%',
  },
  title: {
    color: '#D11227',
  },
  light: { fontWeight: 300, fontSize: '10px' },
});
interface ILocalServitudeProps {
  title: string;
  rows: string[] | null;
}
function LocalServitude(props: ILocalServitudeProps) {
  return (
    <View style={styles.localServitude}>
      <Text style={styles.title}>{props.title}</Text>
      <View>
        {props.rows?.map((m) => (
          <Text key={nanoid()} style={styles.light}>
            - {m}
          </Text>
        ))}
      </View>
    </View>
  );
}

export default LocalServitude;
