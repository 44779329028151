import useRightPanel from '../../../../../App/hooks/useRightPanel';
import { useAppDispatch } from '../../../../../App/store';
import { autocompleteActions } from '../../../../../features/autocomplete/autocompleteSlice';
import { loadersActions } from '../../../../../features/loaders/loaderSlice';
import { mapActions } from '../../../../../features/map/mapSlice';
import { checkAndUpdatePlotStudyDatas } from '../../../../../features/study/services/checkAndUpdatePlotStudyDatas';
import plotActions from '../../../../../redux/plot/actions';
import {
  firstNameAndInitialParser,
  spaceNumberWithCommas,
} from '../../../../../utils/jsFunctions';
import PlotStateBadge from '../../../../Common/StatusElement/PlotStateBadge';
import styles from './folderPlot.module.scss';

interface IPartialPlotStudyProps {
  plotStudy: IPlotStudy;
  color: string;
  isDashboard?: boolean;
  isSub?: boolean;
  index?: number;
}

const FolderPlot = ({
  plotStudy,
  color,
  isDashboard,
  isSub,
  index,
}: IPartialPlotStudyProps) => {
  const { closeRightPanel } = useRightPanel();
  const dispatch = useAppDispatch();

  const handlePlotClick = async () => {
    try {
      if (isDashboard) {
        closeRightPanel();
      }
      if (plotStudy.lng && plotStudy.lat) {
        // dispatch(loaderActions.loaderDisplay.show());
        dispatch(loadersActions.loaderShow());

        const updated = await checkAndUpdatePlotStudyDatas({ ps: plotStudy });

        const studyParams: StudyParamsState = {
          type: 'plotStudy',
          idIri: updated.idIri,
          noCheckExisting: true,
          managmentLoader: false,
        };

        // set plot location study to map reducer
        dispatch(mapActions.plotGeolocDataSet(updated));
        dispatch(
          autocompleteActions.setInputValue((updated.address as string) || '')
        );
        // load plot data for map and others panel tabs
        dispatch(
          plotActions.fetchPlot.request({
            data: {
              lon: updated.lng,
              lat: updated.lat,
              department: updated.department,
              pdf: false,
            },
            studyParams,
          })
        );
      } else {
        throw new Error('Cette parcelle ne peu pas être chargée');
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const area = plotStudy.area
    ? spaceNumberWithCommas(parseFloat((plotStudy.area || 0).toFixed(2))) + ' m²'
    : ' - ';

  return (
    <div
      className={`${styles.plot} ${isSub ? styles.isSub : ''}`}
      onClick={handlePlotClick}
    >
      {!isDashboard && (
        <div className={styles.plotIndex} style={{ backgroundColor: color }}>
          {index}
        </div>
      )}

      <div className={styles.plotContent}>
        <p className={styles.plotNumber}>{plotStudy.fullPlotId?.substring(5)}</p>

        <p className={styles.plotCity}>{`${plotStudy.city} ${
          isDashboard ? '(' + plotStudy.postalCode + ')' : ''
        }`}</p>
        {isDashboard && <p className={styles.plotArea}>{area}</p>}
        <p className={styles.plotResponsable}>
          {firstNameAndInitialParser(
            plotStudy.responsable?.lastName ?? null,
            plotStudy.responsable?.firstName ?? null
          )}
        </p>
        <PlotStateBadge status={plotStudy.status} />
      </div>
    </div>
  );
};

export default FolderPlot;
