import { format } from 'date-fns';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { dpePopupContentParser, dpesParser } from './dpeParser';

const DATA_URL = process.env.REACT_APP_API_URL_DATAS;

export const fetchPopupContent = async (
  dpePoint: DpePoint,
  dpeAdvancedSearchFormData: IDpeAdvancedSearchFormData | null
) => {
  try {
    if (!dpePoint) {
      return null;
    }
    const dpeBody = {
      date_min: dpeAdvancedSearchFormData?.createdAtAfter
        ? format(dpeAdvancedSearchFormData.createdAtAfter as Date, 'yyyy-MM-dd')
        : null,
      date_max: dpeAdvancedSearchFormData?.createdAtBefore
        ? format(dpeAdvancedSearchFormData.createdAtBefore as Date, 'yyyy-MM-dd')
        : null,
      built_area_min: dpeAdvancedSearchFormData?.builtAreaMin,
      built_area_max: dpeAdvancedSearchFormData?.builtAreaMax,
      dpe_min: dpeAdvancedSearchFormData?.dpeMin,
      dpe_max: dpeAdvancedSearchFormData?.dpeMax,
      ges_min: dpeAdvancedSearchFormData?.gesMin,
      ges_max: dpeAdvancedSearchFormData?.gesMax,
      building_type: dpeAdvancedSearchFormData?.buildingType,
    };
    // new object excluding keys with false values
    const filteredBody = await Object.fromEntries(
      Object.entries(dpeBody).filter(([key, value]) => value)
    );

    const response = await postEntity({
      endpoint: `${DATA_URL}/get_dpe_datas_from_geo_adresse/`,
      body: {
        geo_adresse: dpePoint.address,
        latitude: dpePoint.lat.toString(),
        longitude: dpePoint.lng.toString(),
        num_dpe: dpePoint.numDpe,
        insee: dpePoint.insee,
        ...filteredBody,
      },
    });

    const parsedResponse = dpesParser(response as any);
    const popup = dpePopupContentParser(parsedResponse);
    return popup;
  } catch (error) {
    return Promise.reject(error);
  }
};
