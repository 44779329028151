import React from 'react';
import styles from './headerExternalPdfErrial.module.scss';
import Logo from '../../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrowSmall } from '../../../assets/images/right-arrow-small.svg';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import useRightPanel from '../../../App/hooks/useRightPanel';
import { getPanelState } from '../../../redux/Panels/reducer';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../externalPdfErrialSlice';

const HeaderExternalPdfErrial = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isMultiPlotSelectorPdf } = useAppSelector(getExternalPdfErrialState);
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { closeRightPanel } = useRightPanel();

  const handleClick = () => {
    if (isMultiPlotSelectorPdf)
      dispatch(externalPdfErrialActions.setIsMultiPlotSelectorPdf(false));
    if (rightPanelDisplay) closeRightPanel();
    history.push('/');
  };

  return (
    <div className={styles.header}>
      <img className={styles.logo} src={Logo} alt="logo" onClick={handleClick} />
      <button onClick={handleClick}>
        Retour sur Urbanease <RightArrowSmall />
      </button>
    </div>
  );
};

export default HeaderExternalPdfErrial;
