import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import styles from './buildPermitsLegend.module.scss';
interface IHousingFilterProps {
  values: HousingFilterType;
  onChange: (values: HousingFilterType) => void;
}

function HousingFilter({ values, onChange }: IHousingFilterProps) {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof HousingFilterType
  ) => {
    const result = {
      ...values,
      [key]: e.target.value ? parseInt(e.target.value, 10) : null,
    };
    onChange(result);
  };
  return (
    <div className={styles.housingFilterContainer}>
      <p>Nombre de logements autorisés</p>
      <div className={styles.housingFilter}>
        <span>mini</span>
        <TextField
          value={values.mini ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'mini')}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
        />
        <span>maxi</span>
        <TextField
          value={values.maxi ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'maxi')}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
        />
      </div>
    </div>
  );
}

export default HousingFilter;
