import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import NoCity from '../../../components/noRights/NoCity';
import { getMapState } from '../../../features/map/mapSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { fetchZonesAndZoneChildsThunk } from '../../plu/pluThunk';
import {
  advancedPlotSearchActions,
  getAdvancedPlotSearchState,
} from '../advancedPlotSearchSlice';
import AdvancedSearchButtons from './AdvancedSearchButtons';
import BlocContainer from './BlocContainer';
import Zonage from './Zonage';
import styles from './advancedPlotSearchContainer.module.scss';
import Building from './building';
import { formSchema, initialValues } from './formSchema';
import ResearchByOwner from './ownerSearch/ResearchByOwner';
import PlotCalculation from './plotCalculation';
export interface IAdvancedPlotSearchCommon {
  control: any;
}

function AdvancedPlotSearchContainer() {
  const { geolocDatas } = useAppSelector(getMapState);
  const { formData, activeInsee, geoFromDraw } = useAppSelector(
    getAdvancedPlotSearchState
  );
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { isDirty },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<AdvancedPlotSearchForm>({
    defaultValues: formData ?? initialValues,
    resolver: yupResolver(formSchema),
  });

  const resetTempGeo = () => {
    dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
  };

  useEffect(() => {
    // use temp state for eventual removal of polygon before save
    if (formData?.polygon) {
      dispatch(advancedPlotSearchActions.setGeoFromDraw(formData?.polygon));
    }
  }, [formData?.polygon]);

  useEffect(() => {
    //if composant is destructed, reset temp draw
    return () => {
      resetTempGeo();
    };
  }, []);

  useEffect(() => {
    // if city change, reset slice (without plots) and set new insee
    if (activeInsee !== geolocDatas?.inseeCode) {
      dispatch(advancedPlotSearchActions.resetForm());
      dispatch(
        advancedPlotSearchActions.setActiveInsee(geolocDatas?.inseeCode ?? null)
      );
      reset(initialValues);
      dispatch(
        fetchZonesAndZoneChildsThunk({
          city: geolocDatas?.city ?? null,
          postalCode: geolocDatas?.postalCode ?? null,
        })
      );
    }
  }, [geolocDatas?.inseeCode]);

  const onSubmit = (data: AdvancedPlotSearchForm) => {
    if (isDirty || formData) {
      if (geoFromDraw) {
        // remove geometry from temp store and add it ot form
        data.polygon = geoFromDraw;
        resetTempGeo();
      } else if (formData?.polygon) {
        // if polygon is allready created
        data.polygon = formData?.polygon;
      }

      dispatch(modalsActions.advancedSearchResult({ status: true, context: data }));
      dispatch(advancedPlotSearchActions.formDataSet(data));
    }
  };

  if (!geolocDatas?.city) {
    return <NoCity />;
  }

  return (
    <div className={styles.advancedPlotSearchContainer}>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <BlocContainer title="Zonage PLU" blocKey={'zonage'}>
          <Zonage
            control={control}
            city={geolocDatas?.city ?? null}
            postalCode={geolocDatas?.postalCode ?? null}
            inseeCode={geolocDatas?.inseeCode ?? null}
            watch={watch}
            setValue={setValue}
          />
        </BlocContainer>

        <BlocContainer title="Calculs des parcelles" blocKey="plotCalc">
          <PlotCalculation control={control} trigger={trigger} setValue={setValue} />
        </BlocContainer>

        <BlocContainer title="Calculs des bâtiments" blocKey="builtCalc">
          <Building control={control} trigger={trigger} setValue={setValue} />
        </BlocContainer>

        <BlocContainer title="Propriétaires et occupants" blocKey="owner">
          <ResearchByOwner
            control={control}
            setValue={setValue}
            isOwnerEmpty={isEmpty(getValues('ownerName'))}
            isSirenNumberEmpty={isEmpty(getValues('sirenNumber'))}
          />
        </BlocContainer>

        <AdvancedSearchButtons
          control={control}
          reset={reset}
          hasFormData={Boolean(formData)}
        />
      </form>
    </div>
  );
}

export default memo(AdvancedPlotSearchContainer);
