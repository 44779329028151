import { Page, Text } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import GenericBloc from './componants/GenericBloc';
import GenericRisksTab from './componants/GenericRisksTab';
import PageFooter from './componants/PageFooter';
import PageHeader from './componants/PageHeader';
import SubTitleGenericBloc from './componants/SubTitleGenericBloc';
import { pdfStyles } from './pdfStyle';

interface IPageSixProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  catnat: Catnat[];
}
function PageSix({ date, address, catnat }: IPageSixProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={date} address={address} fixed />
      <GenericBloc title="ANNEXE – Liste des arrêtés de Catégories Naturelles sur la Commune">
        {isEmpty(catnat) ? (
          <Text>Aucune donnée recensée</Text>
        ) : (
          catnat.map((elt) => (
            <SubTitleGenericBloc
              subtitle={`• Risque : ${elt.libRisqueJo}`}
              key={nanoid()}
              wrap
            >
              <GenericRisksTab risks={elt.catnatDatas} />
            </SubTitleGenericBloc>
          ))
        )}
      </GenericBloc>
      <PageFooter fixed />
    </Page>
  );
}

export default PageSix;
