import { TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { GoogleSearchContryEnum, getAppState } from '../../App/appSlice';
import { predictionOriginEnum } from '../../App/contexts/InputTabsContext';
import { useAppSelector } from '../../App/store';
import { InputTabsContext } from '../../layout';
import GoogleService from '../../shared/services/GoogleService';
import { getCompanyState } from '../company/companySlice';
import IndividualCircularLoader from '../loaders/individualCircularLoader';
import { getLoadersState } from '../loaders/loaderSlice';
import PredictionsContainer from './PredictionsContainer';
import styles from './autocomplete.module.scss';
import { getAutocompleteState } from './autocompleteSlice';
import { defaultGoogleOptions } from './constants';

interface IAutocompleteProps {
  isCities: boolean;
}

function Autocomplete({ isCities }: IAutocompleteProps) {
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const { addressLoader } = useAppSelector(getLoadersState);
  const { googleSearchType } = useAppSelector(getAppState);
  const { subscription } = useAppSelector(getCompanyState);
  const autocompleteState = useAppSelector(getAutocompleteState);

  const {
    inputValue,
    predictions,
    setInputValue,
    setPredictions,
    setPredictionsDisplay,
    setIsEnterClick,
  } = useContext(InputTabsContext);

  useEffect(() => {
    setSessionToken(nanoid());
  }, []);

  useEffect(() => {
    // if (autocompleteState.inputValue) {
    setInputValue(autocompleteState.inputValue);
    // }
  }, [autocompleteState.inputValue]);

  const isFr = subscription
    ? !(
        googleSearchType === GoogleSearchContryEnum.DOM_TOM ||
        subscription?.hasDomMapAccess
      )
    : true;

  const handleResetSessionToken = () => {
    setSessionToken(nanoid());
  };

  const googleAutocomplete = (value: string) => {
    if (sessionToken) {
      const options = isCities
        ? {
            ...defaultGoogleOptions(isFr, sessionToken),
            types: '(cities)',
          }
        : defaultGoogleOptions(isFr, sessionToken);

      return GoogleService.getPredictions(value, options);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isEmpty(value)) {
      setPredictionsDisplay(false);
    } else {
      setPredictionsDisplay(true);

      googleAutocomplete(value)?.then(
        (response) => {
          setPredictions(response);
        },
        (error) => {
          setPredictions({
            origin: predictionOriginEnum.ADDRESS_SERVER,
            data: [],
          });
        }
      );
    }

    setInputValue(value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const key = e.key;

    if (key === 'Enter' && !isEmpty(predictions)) {
      setIsEnterClick(true);
    }
  };

  return (
    <>
      <div className={styles.addressInputContainer}>
        <TextField
          className={`${styles.addressInput} ${addressLoader && styles.rightMargin}`}
          placeholder={isCities ? 'Choisir une ville' : 'Choisir une adresse'}
          disabled={false}
          type="text"
          value={inputValue}
          variant="outlined"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {addressLoader && <IndividualCircularLoader size={30} fullWidth={false} />}
      </div>

      <PredictionsContainer
        resetSessionToken={handleResetSessionToken}
        sessionToken={sessionToken}
      />
    </>
  );
}

export default Autocomplete;
