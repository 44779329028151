import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import useQuery from '../../../App/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import useCompany from '../../../features/company/useCompany';
import {
  getDashboardOwnerDatasState,
  OwnerDatasSearchTypeEnum,
} from '../../../features/dashboardOwnerDatas/dashboardOwnerDatasSlice';
import {
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
} from '../../../features/dashboardOwnerDatas/dashboardOwnerDatasThunk';
import DataOwnerSearchEngine from '../../../features/dashboardOwnerDatas/DataOwnerSearchEngine';
import OwnerInfos from '../../../features/dashboardOwnerDatas/OwnerInfos';
import CityOwnerTable from '../../../features/dashboardOwnerDatas/ownerTable/CityOwnerTable';
import PlotStudiesTable from '../../../features/dashboardOwnerDatas/plotTable/PlotStudiesTable';

import './DataOwnersTab.scss';

export default function DataOwnersTab() {
  const { queries } = useQuery();
  const history = useHistory();
  useCompany({ loadCompany: true });
  // useSubscription({});
  const dispatch = useAppDispatch();
  useCompany();
  const { searchType } = useAppSelector(getDashboardOwnerDatasState);

  useEffect(() => {
    if (queries.siren) {
      dispatch(
        fetchOwnerInfosFromSirenThunk({
          siren: queries.siren,
        })
      );
      dispatch(
        fetchOwnerPlotFromSirenThunk({
          siren: queries.siren,
          page: 1,
          itemsPerPage: 15,
        })
      );
      history.push('/dashboard');
    }
  }, []);

  return (
    <div className="tab-data-owners">
      <div className="tab-inner">
        <h1>Data Propriétaires</h1>
      </div>
      <div className="tab-body">
        <DataOwnerSearchEngine />

        {searchType === OwnerDatasSearchTypeEnum.PERSON && <OwnerInfos />}

        <Paper className="paper-table">
          {searchType === OwnerDatasSearchTypeEnum.CITY ? (
            <CityOwnerTable />
          ) : (
            <PlotStudiesTable />
          )}
        </Paper>
      </div>
    </div>
  );
}
