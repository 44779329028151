import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import { imageDataParse } from '../../../utils/jsFunctions';
import ConstructibilityComment from './ConstructibilityComment';
import Footer from './Footer';
import Geography from './Geography';
import Header from './Header';
import Prospection from './Prospection';
import References from './References';
import UrbanismeInfo from './UrbanismeInfo';

const styles = StyleSheet.create({
  page: {},
  section: {
    color: '#4E55A2',
    margin: '20px',
  },
  constructProspec: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const ParcelleInfoPDF = ({ datas }: { datas: PlotPDFDocument | null }) => {
  if (datas) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Header />

            <References address={datas.address} />

            <Geography
              areas={datas.areas}
              plotId={datas.plotId}
              fiscalLaw={datas.fiscalLaw}
              image={imageDataParse(datas.plotImg)}
              isDomTom={datas.isDomTomOrCorsica}
            />

            <UrbanismeInfo
              peb={datas.PEB}
              zone={datas.zone}
              seismic={datas.seismic}
              clay={datas.clay}
              image={imageDataParse(datas.zoneImg)}
              isDomTom={datas.isDomTomOrCorsica}
            />

            <View style={styles.constructProspec}>
              <ConstructibilityComment prospection={datas.prospection} />

              <Prospection prospection={datas.prospection} />
            </View>
          </View>
          <Footer />
        </Page>
      </Document>
    );
  } else {
    return <Document />;
  }
};

export default ParcelleInfoPDF;
