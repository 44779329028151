import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../App/store';
import useApp from '../App/useApp';
import ButtonsOnMap from '../components/ButtonsOnMap';
import LeftPanelModal from '../components/LeftPanelModal';
import MapComponent from '../components/Map/Map';
import checkErrialPdfAllow from '../features/auth/utils/checkErrialPdfAllow';
import useCompany from '../features/company/useCompany';
import ErrialInfoModal from '../features/externalPdfErrial/errialInfoModal/ErrialInfoModal';
import { getExternalPdfErrialState } from '../features/externalPdfErrial/externalPdfErrialSlice';
import HeaderExternalPdfErrial from '../features/externalPdfErrial/headerExternalPdfErrial/HeaderExternalPdfErrial';
import RightPanelModalExternalPdfErrial from '../features/externalPdfErrial/rightPanelModalExternalPdfErrial/RightPanelModalExternalPdfErrial';
import FullScreenLoader from '../features/loaders/fullScreenLoader';
import { getMapState, mapActions } from '../features/map/mapSlice';
import styles from './styles/externalPdfErrial.module.scss';

const ExternalPdfErrialPage = (): JSX.Element => {
  const { infoModalDisplay } = useAppSelector(getExternalPdfErrialState);
  const { mapCenter, franceLayerDisplay } = useAppSelector(getMapState);
  useApp();
  const { company } = useCompany({ loadCompany: true });
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      mapActions.mapCenterSet({
        latLng: mapCenter,
        zoom: franceLayerDisplay ? 6 : 17,
      })
    );
  }, []);

  useEffect(() => {
    if (company.result && !checkErrialPdfAllow(company.result?.firm ?? '')) {
      history.push('/');
    }
  }, [company.result]);

  if (!company.result) {
    return <FullScreenLoader />;
  }
  return (
    <div className={styles.externalPdfErrialContainer}>
      <HeaderExternalPdfErrial />
      <LeftPanelModal />
      <RightPanelModalExternalPdfErrial />
      <ButtonsOnMap />
      <MapComponent />
      {infoModalDisplay.display && <ErrialInfoModal />}
    </div>
  );
};

export default ExternalPdfErrialPage;
