import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { ReactComponent as ArrowCloseMultipleSelectPlots } from '../../../assets/images/arrow-close-multiple-select-plots.svg';
import { ReactComponent as ArrowOpenMultipleSelectPlots } from '../../../assets/images/arrow-open-multiple-select-plots.svg';
import { getMapState } from '../../../features/map/mapSlice';
import getBiggestPropertyObjectFromArray from '../../../shared/utils/getBiggestPropertyObjectFromArray';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';
import { getAuthState } from '../../auth/authSlice';
import useCompany from '../../company/useCompany';
import ProgressBarCustom from '../../loaders/progressBarCustom';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../externalPdfErrialSlice';
import OrpiButton from '../generateExternalPDFErrial/orpiButton';
import GoodDetailsSection from './GoodDetailsSection';
import OwnerAndAddressSection from './OwnerAndAddressSection';
import ProInfosSection from './ProInfosSection';
import TextAreaInputFormPdfErrial from './TextAreaInputFormPdfErrial';
import { validationSchemaPdfForm } from './formValidation';
import styles from './rightPanelModalExternalPdfErrial.module.scss';

export interface IErrialPDFFormSectionProps {
  errors: { [x: string]: any };
  control: Control<IExternalPdfErrialForm, object>;
  hideLandArea?: boolean;
}

const MAX_COMMENT_CHARACTERS = 440;

const RightPanelModalExternalPdfErrial = () => {
  const dispatch = useAppDispatch();
  const {
    externalPdfErrialRightPanelDisplay,
    sweepbrightData,
    multiPlotsPdf,
    isMultiPlotSelectorPdf,
    clicked,
  } = useAppSelector(getExternalPdfErrialState);
  const { user } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { getCompanyName } = useCompany();
  const [hideLandArea, setHideLandArea] = useState(false);

  const geolocDataCity = `${geolocDatas?.city ?? ''} ${
    geolocDatas?.postalCode ?? ''
  }`;
  const geolocDataStreet =
    geolocDatas?.houseNumber || geolocDatas?.houseNumber
      ? `${geolocDatas?.houseNumber ?? ''} ${
          (geolocDatas?.streetName?.charAt(0).toLowerCase() ?? '') +
            geolocDatas?.streetName?.slice(1) ?? ''
        }`
      : null;

  const initialPdfForm: IExternalPdfErrialForm = {
    description: '',
    addressCity: '',
    addressStreet: '',
    propertyType: '',
    livingArea: 0,
    landArea: 0,
    numberOfRooms: 0,
    floorNumber: 0,
    IdentityOwnersOnDocument: '',
    lastName: (user?.lastName as string) ?? '',
    firstName: (user?.firstName as string) ?? '',
    email: (user?.email as string) ?? '',
    phone: '',
    companyName: (getCompanyName()?.name as string) ?? '',
  };

  const handlePanel = (): void => {
    dispatch(
      externalPdfErrialActions.setExternalPdfErrialRightPanelDisplay(
        !externalPdfErrialRightPanelDisplay
      )
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm<IExternalPdfErrialForm>({
    defaultValues: initialPdfForm,
    resolver: yupResolver(validationSchemaPdfForm),
  });

  const submitForm = (data: IExternalPdfErrialForm): void => {
    dispatch(externalPdfErrialActions.setExternalPdfErrialFormData(data));
  };

  const watchPropertyType = watch('propertyType', getValues('propertyType'));

  const getAddress = async () => {
    if (
      geolocDatas &&
      geolocDatas.coordinates &&
      (!geolocDatas.houseNumber || !geolocDatas.streetName)
    ) {
      const result = await addrServProcessAsync(
        geolocDatas.coordinates[0],
        geolocDatas.coordinates[1]
      );

      if (result?.houseNumber || result?.streetName) {
        const streetAddress = `${result?.houseNumber ?? ''} ${
          (result?.streetName?.charAt(0).toLowerCase() ?? '') +
            result?.streetName?.slice(1) ?? ''
        }`;

        setValue('addressStreet', streetAddress ?? '');
      } else {
        setValue('addressStreet', '');
      }
    }
  };
  useEffect(() => {
    getAddress();
  }, [geolocDatas]);

  useEffect(() => {
    setHideLandArea(getValues('propertyType') === 'apartment');
  }, [watchPropertyType]);

  useEffect(() => {
    if (sweepbrightData.result) {
      if (
        !getValues().addressCity &&
        sweepbrightData.result.addressCity &&
        !getValues().addressStreet &&
        sweepbrightData.result.addressStreet
      ) {
        setValue(
          'addressCity',
          sweepbrightData.result.addressCity ??
            (geolocDatas?.city ? geolocDataCity : '')
        );
        setValue(
          'addressStreet',
          sweepbrightData.result.addressStreet ??
            (geolocDatas?.streetName ? geolocDataStreet : '')
        );
      } else {
        setValue(
          'addressCity',
          geolocDatas?.city
            ? geolocDataCity
            : sweepbrightData.result.addressCity ?? ''
        );
        setValue(
          'addressStreet',
          geolocDatas?.streetName
            ? geolocDataStreet ?? ''
            : sweepbrightData.result.addressStreet ?? ''
        );
      }

      setValue('description', sweepbrightData.result.description);
      setValue('propertyType', sweepbrightData.result?.propertyType ?? '');
      setValue('livingArea', sweepbrightData.result?.livingArea ?? 0);
      setValue('landArea', sweepbrightData.result?.landArea ?? 0);
      setValue('numberOfRooms', sweepbrightData.result?.numberOfRooms ?? 0);
      setValue('floorNumber', sweepbrightData.result?.floorNumber ?? 0);
      trigger('description');
    } else {
      setValue('addressCity', geolocDataCity);
      setValue('addressStreet', geolocDataStreet ?? '');
    }
  }, [sweepbrightData, geolocDatas?.houseNumber, geolocDatas?.streetName]);

  useEffect(() => {
    if (
      isMultiPlotSelectorPdf &&
      multiPlotsPdf.result &&
      (multiPlotsPdf.result?.length ?? 0) > 0
    ) {
      const plot = getBiggestPropertyObjectFromArray(
        multiPlotsPdf.result,
        'theoricCapacity'
      );
      setValue(
        'addressCity',
        plot.city ? geolocDataCity : sweepbrightData.result?.addressCity ?? ''
      );
      setValue(
        'addressStreet',
        plot.streetName
          ? `${plot.houseNumber ?? ''} ${
              plot.streetName?.charAt(0).toLowerCase() + plot.streetName?.slice(1) ??
              ''
            }`
          : sweepbrightData.result?.addressStreet ?? ''
      );
    }
  }, [multiPlotsPdf.result]);

  return (
    <div
      className={`${styles.rightPanelModal} ${
        externalPdfErrialRightPanelDisplay ? styles.displayed : ''
      }`}
    >
      <div className={styles.arrowWrapper} onClick={handlePanel}>
        {externalPdfErrialRightPanelDisplay ? (
          <ArrowCloseMultipleSelectPlots className={styles.arrow} />
        ) : (
          <ArrowOpenMultipleSelectPlots className={styles.arrow} />
        )}
      </div>

      <div className={styles.rightPanelModalHeader}>
        <h2>Synthèse Document</h2>
      </div>

      <form
        onSubmit={handleSubmit(submitForm)}
        autoComplete="off"
        className={`${styles.rightPanelModalBody} ${clicked ? styles.hidden : ''}`}
      >
        <div>
          <h3>Détails du bien étudié</h3>
          <div className={styles.formSection}>
            <GoodDetailsSection
              control={control}
              errors={errors}
              hideLandArea={hideLandArea}
            />

            <OwnerAndAddressSection control={control} errors={errors} />

            <div
              className={`${styles.commentContainer} ${
                errors.description?.message ? styles.error : ''
              }`}
            >
              <p>
                Commentaire rédigé par l’utilisateur professionnel{' '}
                <span>({MAX_COMMENT_CHARACTERS} caractères max)</span>
              </p>
              <TextAreaInputFormPdfErrial
                control={control}
                name="description"
                rowsMax={3}
                rowsMin={1}
                placeholder="Commentaire ..."
                error={Boolean(errors.description?.message)}
                errorMessage={errors.description?.message}
                trigger={trigger}
                className={errors.description?.message ? styles.error : ''}
              />
            </div>
          </div>
        </div>

        <ProInfosSection control={control} errors={errors} />

        <OrpiButton disabled={!isValid} />
      </form>

      {clicked && (
        <div className={styles.progressBarContainer}>
          <ProgressBarCustom height={25} totalStep={5} widthPercent={80} />
        </div>
      )}
    </div>
  );
};

export default RightPanelModalExternalPdfErrial;
