import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { TileLayer } from 'react-leaflet';
import { useAppSelector } from '../../App/store';
import { getLayerEnvState } from '../../features/envs/envSlice';

// Catégorie 1 : Données sismiques
// Catégorie 2 : Quartiers prioritaires
// Catégorie 3 : Monuments historiques
// Catégorie 4 : PPRI - Zones inondables
// Catégorie 5 : Argiles Datas
// Catégorie 6 : PPRN
// Catégorie 7 : Exposition au bruit

const EnvLayers = memo(() => {
  const { envLayers } = useAppSelector(getLayerEnvState);

  return (
    <>
      {envLayers.result
        ?.filter((e) => e.displayed === true)
        .map((env) => {
          return (
            <TileLayer
              key={nanoid()}
              maxZoom={22}
              zIndex={2}
              opacity={0.6}
              url={env.url ?? ''}
            />
          );
        })}
    </>
  );
});

EnvLayers.displayName = 'EnvLayers';

export default EnvLayers;
