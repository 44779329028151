import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { getAppState } from '../../../../App/appSlice';
import { useAppSelector } from '../../../../App/store';
import TableRowFullColumn from '../../../../components/Common/TableRowFullColumn';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';

import '../../../../components/Dashboard/DirectoryTab/DirectoryTab.scss';
import entityListFromNestedEntities from '../../../../utils/entityListFromNestedEntities';
import FilterSortBloc from '../../../filterSortBloc';
import { folderPlotStudiesForSelect } from '../../../folders/dashboard/datas';
import useFolder from '../../../folders/useFolder';
import { fetchPlotStudies } from '../../../study/services/fetchPlotStudies';
import { getUsersState } from '../../../users/usersSlice';
import ContactPlotStudiesContainer from './ContactPlotStudiesContainer';
import { plotStudiesHeaderCell } from './datas';

interface IContactPlotStudyContent {
  contactIdIri: string;
}

function removeDuplicatesById(array: PlotStudies) {
  const arrayUnique: PlotStudies = [];
  array.forEach((element) => {
    let isUnique = true;
    arrayUnique.forEach((elementUnique) => {
      if (element.idIri === elementUnique.idIri) {
        isUnique = false;
        return;
      }
    });
    if (isUnique) {
      arrayUnique.push(element);
    }
  });
  return arrayUnique;
}

function ContactPlotStudiesContent({ contactIdIri }: IContactPlotStudyContent) {
  const [sortKey, setSortKey] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [plotStudies, setPlotStudies] = useState<SortablePlotStudies>([]);
  const { users } = useAppSelector(getUsersState);
  const { plotStudyStatuses } = useAppSelector(getAppState);
  const { folders } = useFolder({});

  const request = async () => {
    const response = await fetchPlotStudies({
      urlBase: contactIdIri,
      isContactPlotStudies: true,
      users,
      statuses: plotStudyStatuses.result,
      folders: entityListFromNestedEntities(folders) as Folders,
    });
    const filteredPlotStudies = removeDuplicatesById(response);
    const sortableplotStudies = filteredPlotStudies.map((m) => ({
      sortValues: {
        city: m.city,
        area: m.area,
        responsable: m.responsable?.fullName ?? null,
        status: m.status?.label ?? null,
      },
      entity: m,
    }));
    setPlotStudies(sortableplotStudies);
  };

  useEffect(() => {
    if (users && plotStudyStatuses.result && folders && contactIdIri) {
      request();
    }
  }, [folders, plotStudyStatuses.result, contactIdIri, users]);

  return (
    <>
      {!plotStudies ? (
        <TableRowFullColumn colSpan={6}>
          <IndividualCircularLoader size={50} className="loader" />
        </TableRowFullColumn>
      ) : isEmpty(plotStudies) ? (
        <TableRowFullColumn colSpan={6}>
          <p className="no-plot-studies p-max">Aucune étude liée à ce contact</p>
        </TableRowFullColumn>
      ) : (
        <>
          <TableRowFullColumn colSpan={6} className="engine-row">
            <FilterSortBloc
              setSortKey={setSortKey}
              setOrder={setOrder}
              selectItems={folderPlotStudiesForSelect}
              selectPlaceHolder="Trier parcelles par"
            />
          </TableRowFullColumn>

          <TableRowFullColumn colSpan={6} className="content-table-row">
            <Table size="small">
              <TableHead>
                <TableRow>
                  {plotStudiesHeaderCell.map((elt) => (
                    <TableCell key={nanoid()}>{elt.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <ContactPlotStudiesContainer
                contactPlotStudies={plotStudies}
                order={order}
                sortKey={sortKey}
              />
            </Table>
          </TableRowFullColumn>
        </>
      )}
    </>
  );
}

export default memo(ContactPlotStudiesContent);
