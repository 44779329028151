import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface ITooltipCustomProps {
  error: any;
  children: React.ReactElement<any, any>;
}
const useStyles = makeStyles()({
  tooltip: {
    backgroundColor: '#ff4444',
    fontSize: '12px',
    fontWeight: 700,
    // width: '120px',
    // textAlign: 'center',
  },
  arrow: { color: '#ff4444' },
});

function TooltipCustom({ error, children }: ITooltipCustomProps) {
  const { classes } = useStyles();
  return (
    <Tooltip
      classes={classes}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={Boolean(error)}
      title={error?.message ?? ''}
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
}

export default TooltipCustom;
