import { Checkbox } from '@mui/material';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { useAppSelector } from '../../../../../App/store';
import { getAuthState } from '../../../../../features/auth/authSlice';
import AddButton from './AddButton';
import styles from './sectionHeader.module.scss';
import { makeStyles } from 'tss-react/mui';

interface ISectionHeaderProps {
  checkAll: boolean;
  handleCheckAllChange: ChangeEventHandler<HTMLInputElement>;
  handleAdd: MouseEventHandler<Element>;
  disabled?: boolean;
  type: string;
}

const useStyles = makeStyles()({
  root: {
    padding: '9px 3px',
  },
});

function SectionHeader({
  checkAll,
  handleAdd,
  handleCheckAllChange,
  disabled,
  type,
}: ISectionHeaderProps) {
  const { classes } = useStyles();
  const { isAdmin, isManager } = useAppSelector(getAuthState);

  return (
    <div className={styles.sectionHeader}>
      <Checkbox
        color="primary"
        classes={classes}
        size="small"
        checked={checkAll}
        disabled={disabled}
        onChange={handleCheckAllChange}
      />
      <p className={styles.containerLabel}>{`Mes ${type}s de prospection`}</p>
      {type === 'dossier' && (
        <AddButton onClick={handleAdd}>+ {`${type}`}</AddButton>
      )}
      {(isManager || isAdmin) && type === 'secteur' && (
        <AddButton onClick={handleAdd}>+ {`${type}`}</AddButton>
      )}
    </div>
  );
}

export default SectionHeader;
