import { View } from '@react-pdf/renderer';
import { EnvIconTypeEnum } from '../Page4';
import EnvironmentalConstraint from './EnvironmentalConstraint';

interface IEnvironmentalConstraintsProps {
  seismic: number;
  clay: number;
  radon: number;
  coastalErosion: boolean;
}

function EnvironmentalConstraints({
  seismic,
  clay,
  radon,
  coastalErosion,
}: IEnvironmentalConstraintsProps) {
  return (
    <View>
      <EnvironmentalConstraint
        type={EnvIconTypeEnum.SEISMIC}
        title="SISMICITE"
        description="Un tremblement de terre ou séisme, est un ensemble de secousses et de déformations brusques de l'écorce terrestre (surface de la Terre). Le zonage sismique détermine 'importance de l'exposition au risque sismique."
        max={5}
        value={seismic}
      />
      <EnvironmentalConstraint
        type={EnvIconTypeEnum.CLAY}
        title="ARGILE"
        description="Les sols argileux évoluent en fonction de leur teneur en eau. De fortes
        variations d'eau (sécheresse ou d’apport massif d’eau) peuvent donc
        fragiliser progressivement les constructions (notamment les maisons
        individuelles aux fondations superficielles) suite à des gonflements et des tassements du sol, et entrainer des dégâts pouvant être importants. Le zonage argile identifie les zones exposées à ce phénomène de retrait-gonflement selon leur degré d’exposition.
        Pour les terrains constructibles destinés à la construction de maisons
        ou collectifs exposés à un risque (ou aléa) moyen ou fort de retrait-gonflement des argiles, des études de sols préalables à la vente sont
        obligatoires."
        max={3}
        value={clay}
      />
      <EnvironmentalConstraint
        type={EnvIconTypeEnum.RADON}
        title="RADON"
        description="Le radon est un gaz radioactif naturel inodore, incolore et inerte. Ce gaz est présent partout dans les sols et il s’accumule dans les espaces clos, notamment dans les bâtiments."
        max={3}
        value={radon}
      />
      {coastalErosion && (
        <EnvironmentalConstraint
          type={EnvIconTypeEnum.EROSION}
          title="Trait d’Erosion Côtière"
          description="Depuis la promulgation de la loi Climat et Résilience, les nouvelles
        constructions seront interdites dans les zones impactées par une érosion côtière à horizon de 30 ans. Les informations sur l'érosion du trait de côte devront être intégrées dans les Informations Acquéreur Locataire (IAL). La loi institue par ailleurs un droit de préemption au profit des communes figurant sur la liste établie par décret afin de prévenir les conséquences du recul du trait de côte sur les biens qui y sont situés (article 244)."
        />
      )}
    </View>
  );
}

export default EnvironmentalConstraints;
