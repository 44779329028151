import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import '../../prospection.scss';

interface IProspectionContainerProps {
  title: string;
  isOpen?: boolean;
  children: JSX.Element;
  isStudyExists?: boolean;
}
export default function ProspectionContainer({
  title,
  children,
  isOpen = false,
}: IProspectionContainerProps) {
  const [isBodyHidden, setIsBodyHidden] = useState<boolean>(!isOpen);

  const handleContentDisplay = () => {
    setIsBodyHidden(!isBodyHidden);
  };

  return (
    <div className="prospection-body-container">
      <div className="container-header">
        <h2 className="container-title" onClick={handleContentDisplay}>
          {title}
        </h2>
        <ExpandMoreIcon
          className={`icon ${isBodyHidden ? 'down' : ''}`}
          onClick={handleContentDisplay}
        />
      </div>
      <div className={`body-content ${isBodyHidden ? 'hidden' : ''}`}>
        {children}
      </div>
    </div>
  );
}
