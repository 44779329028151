import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../App/store';
import { PLUOtherTypeEnum } from '../../../components/LeftPanelModal/MenuTabs/Urbanisme';
import { getPluState } from '../pluSlice';
import styles from './pluOther.module.scss';
import PluOtherRow from './PluOtherRow';

interface IPluOtherContainerProps {
  type: PLUOtherTypeEnum;
}

function PluOtherContainer({ type }: IPluOtherContainerProps) {
  const { pluOthers } = useAppSelector(getPluState);
  return (
    <div className={styles.pluOtherContainer}>
      {pluOthers
        ?.filter((f) => f.masterCat === type)
        .map((m) => (
          <div key={nanoid()}>
            <PluOtherRow cat={m} />
          </div>
        ))}
    </div>
  );
}

export default PluOtherContainer;
