import { useHistory } from 'react-router';
import BellAlert from '../../features/map/plugins/urbaToolbar/BellAlert';
import CreateToolbarBtn from '../../features/map/plugins/urbaToolbar/CreateToolbarBtn';
import styles from '../../features/map/plugins/urbaToolbar/urbaToolbar.module.scss';
import useProcedures from '../../features/procedures/useProcedures';

function AccountMapBtn() {
  const { proceduresCount } = useProcedures();
  const history = useHistory();

  const handleClick = () => {
    history.push('/account');
  };

  return (
    <CreateToolbarBtn
      icon="ACCOUNT"
      onClick={handleClick}
      isFontAwesomeIcon
      tooltip={<p className={styles.btnTooltip}>Accéder à mon compte</p>}
      hasNotification={proceduresCount > 0}
      notification={<BellAlert />}
    />
  );
}

export default AccountMapBtn;
