import { Button } from '@mui/material';
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { InputTabsContext } from '../../../layout/';
import styles from './inputTabs.module.scss';

const buttons = ['Adresse', 'Parcelle'];

function Tabs() {
  const { inputTab, setInputTab } = useContext(InputTabsContext);

  return (
    <div className={styles.tabsButtons}>
      {buttons.map((e, i) => (
        <Button
          key={uuidv4()}
          className={`${styles.tabBtn} ${inputTab === i ? styles.selected : ''}`}
          onClick={() => setInputTab(i)}
        >
          {e}
        </Button>
      ))}
    </div>
  );
}

export default Tabs;
