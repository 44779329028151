import { Checkbox } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, UseFormSetValue } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { DrawActionEnum } from '../../../map/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsDrawState,
  mapPluginsActions,
} from '../../../map/plugins/mapPluginSlice';
import {
  advancedPlotSearchActions,
  getAdvancedPlotSearchState,
} from '../../advancedPlotSearchSlice';
import styles from '../advancedPlotSearchContainer.module.scss';
interface IDrawCheckboxProps {
  control: any;
  setValue: UseFormSetValue<AdvancedPlotSearchForm>;
}
function DrawCheckbox({ control, setValue }: IDrawCheckboxProps) {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector(getAdvancedPlotSearchState);
  const { action } = useAppSelector(getMapPluginsDrawState);

  useEffect(() => {
    if (action === DrawActionEnum.DRAW_ADVANCED_SEARCH_CANCEL) {
      setValue('isDrawingZone', false);
    }
  }, [action]);

  const handleDrawingZoneChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: any
  ) => {
    const value = e.target.checked;
    onChange(e);
    if (value) {
      dispatch(mapPluginsActions.drawAdvancedSearch());
    } else {
      dispatch(mapPluginsActions.drawStop());
      setValue('polygon', null);
      dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
    }
  };

  return (
    <div className={styles.checkContainer}>
      <Controller
        name="isDrawingZone"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            onChange={(e) => handleDrawingZoneChange(e, onChange)}
            checked={value}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )}
      />
      <div className={styles.checkLabelContainer}>
        <p className={styles.checkLabel}>
          Rechercher dans un secteur géographique spécifique
        </p>
        <p className={styles.checkLabelSub}>
          Cochez l’option pour pouvoir tracer votre secteur de recherche
        </p>
      </div>
    </div>
  );
}

export default DrawCheckbox;
