import { faDownload, faSheetPlastic } from '@fortawesome/free-solid-svg-icons'; //faSheetPlastic
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './redDownloadButton.module.scss';
interface IRedDownloadButton {
  children?: React.ReactNode;
  alt: string;
  link?: boolean;
  disabled?: boolean;
  handleClick: () => void;
}

function RedDownloadButton({
  children,
  handleClick,
  alt,
  link,
  disabled,
}: IRedDownloadButton) {
  return (
    <button
      className={`${styles.redDownloadButton} ${disabled && styles.disabled}  `}
      onClick={handleClick}
      disabled={disabled}
    >
      {link ? (
        <FontAwesomeIcon icon={faSheetPlastic} />
      ) : (
        <FontAwesomeIcon icon={faDownload} />
      )}
      <p>{children}</p>
    </button>
  );
}

export default RedDownloadButton;
