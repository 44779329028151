import { fetchDatas } from '../../../../services/axiosFiles/genericCrud';

/**
 * @param  {string |null} city
 * @param {string |null} postalCode
 * @param {string |null} pattern
 * @returns string[]
 */
export async function getOwnerAutocomplete(
  city: string | null,
  postalCode: string | null,
  pattern: string | null
) {
  try {
    if (city && postalCode && pattern) {
      const result = await fetchDatas('/search_owner', {
        city_name: city,
        city_zipcode: postalCode,
        str_search: pattern,
      });

      const parse = result.result.map((m: any) => m.name) as string[];

      return parse;
    } else {
      return Promise.reject({ status: 0, message: 'missing pattern params' });
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
