import { useEffect, useState } from 'react';
import { getAppState } from '../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import entityListFromNestedEntities from '../../utils/entityListFromNestedEntities';
import { getCompanyState } from '../company/companySlice';
import { getUsersState } from '../users/usersSlice';
import { getFoldersState } from './foldersSlice';
import { fetchFoldersThunk } from './services/thunks/foldersThunk';

interface IUseFolderProps {
  forceLoad?: boolean;
  noLoad?: boolean;
}
export default function useFolder({ forceLoad, noLoad = false }: IUseFolderProps) {
  const {
    folders,
    deployedFolder,
    deployedSubFolder,
    folderForAction,
    totalFolders,
    isLoading,
  } = useAppSelector(getFoldersState);

  const { companyIdIri } = useAppSelector(getCompanyState);
  // const [folderNames, setFolderNames] = useState<IFolderNames[]>();
  const [folderList, setFolderList] = useState<Folders>([]);
  const { plotStudyStatuses } = useAppSelector(getAppState);
  const { users } = useAppSelector(getUsersState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !noLoad &&
      (forceLoad || !folders.result) &&
      companyIdIri &&
      folders.apiStatus === APIStatus.IDLE &&
      plotStudyStatuses.result &&
      users
    ) {
      dispatch(fetchFoldersThunk({ companyIdIri }));
    }
  }, [companyIdIri, plotStudyStatuses.result, users]);

  useEffect(() => {
    if (folders.result) {
      const fList: Folders = entityListFromNestedEntities(folders.result) as Folders;

      setFolderList(fList);
    }
  }, [folders.result]);

  const foldersForSelectParse = () => {
    return folderList?.map((f) => {
      return {
        value: f.idIri,
        display: f.name,
        isSubfolder: Boolean(f.parent),
      };
    });
  };

  const selectedFolder = (idIri: string | null): IFolder | null => {
    if (!idIri) {
      return null;
    }
    return folders.result?.find((f) => f.idIri === idIri) ?? null;
  };

  const isSubFolder = (idIri: string | null) => {
    let result = false;

    folders.result?.forEach((f) => {
      const folder = f.subs?.find((sf) => sf.idIri === idIri);
      if (folder) {
        result = true;
      }
    });

    return result;
  };

  const getFolderByIdIriFromList = (idIri: string) => {
    return folderList.find((f) => f.idIri === idIri) as IFolder;
  };

  return {
    folders: folders.result,
    foldersApiStatus: folders.apiStatus,
    foldersForSelect: foldersForSelectParse() || [],
    selectedFolder,
    deployedFolder,
    folderForAction,
    deployedSubFolder,
    isSubFolder,
    totalFolders,
    folderList,
    isLoading,
    getFolderByIdIriFromList,
  };
}
