import { Control } from 'react-hook-form';
import { StudyMatrixFormValues } from '.';
import TextFieldFormCustom from '../../../../../components/Common/FormComponents/TextFieldFormCustom';

interface IGGenericMatriceFormInputProps {
  name: string;
  control: Control<StudyMatrixFormValues, object> | undefined;
  className?: string;
  label: nullable<string>;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  unit?: string;
  readOnly?: boolean;
  onChange?: ChangeEventCustom;
}

export default function GenericMatriceFormInput({
  label,
  name,
  control,
  disabled,
  className = '',
  unit,
  onChange,
  readOnly,
}: IGGenericMatriceFormInputProps) {
  return (
    <div className="generic-matrice-input">
      <label>{`${label} :`}</label>
      <TextFieldFormCustom
        name={name}
        control={control}
        disabled={disabled}
        type="number"
        className={`input-field ${className}`}
        readOnly={readOnly}
        onChange={onChange}
        onFocus={(e) => e.target.select()}
      />
      <span>{unit}</span>
    </div>
  );
}
