import FormCheckbox from '../FormCheckbox';
import OwnerSelect from './OwnerSelect';
import SirenNumberInput from './SirenNumberInput';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Controller, UseFormSetValue } from 'react-hook-form';
import { IAdvancedPlotSearchCommon } from '..';
import styles from '../advancedPlotSearchContainer.module.scss';
import { radiobuttonsCopro, radiobuttonsOwner } from './constants';

interface IResearchByOwnerProps extends IAdvancedPlotSearchCommon {
  control: any;
  setValue: UseFormSetValue<AdvancedPlotSearchForm>;
  isOwnerEmpty: boolean;
  isSirenNumberEmpty: boolean;
}

function ResearchByOwner({
  control,
  setValue,
  isOwnerEmpty,
  isSirenNumberEmpty,
}: IResearchByOwnerProps) {
  return (
    <div className={styles.ownerResearch}>
      <FormCheckbox
        control={control}
        name="isBodacc"
        label="Rechercher sociétés en difficulté uniquement"
      />
      <hr />

      <div className={styles.radioContainer}>
        <Controller
          name="copro"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              onChange={onChange}
              row
              value={value}
              className={styles.radioGroup}
            >
              {radiobuttonsCopro.map((elmt) => (
                <FormControlLabel
                  className={styles.radioLabel}
                  key={nanoid()}
                  value={elmt.value}
                  control={<Radio className={styles.radio} />}
                  label={elmt.label}
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>
          )}
        />
        <Controller
          name="ownerDatas"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              onChange={onChange}
              row
              value={value}
              className={styles.radioGroup}
            >
              {radiobuttonsOwner.map((elmt) => (
                <FormControlLabel
                  className={styles.radioLabel}
                  key={nanoid()}
                  value={elmt.value}
                  control={<Radio className={styles.radio} />}
                  label={elmt.label}
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>
          )}
        />
      </div>
      <hr />
      <div className={styles.label}>Saisir un nom OU un n° siren</div>
      <div className={styles.field}>
        <OwnerSelect
          control={control}
          setValue={setValue}
          isSirenNumberEmpty={isSirenNumberEmpty}
        />
      </div>
      <div className={styles.field}>
        <SirenNumberInput control={control} isOwnerEmpty={isOwnerEmpty} />
      </div>
    </div>
  );
}

export default ResearchByOwner;
