import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getCompanyState } from '../../features/company/companySlice';
import { getAuthState } from '../auth/authSlice';
import { fetchCompanyThunk } from './companyThunks';

interface IUseCompanyProps {
  loadCompany: boolean;
}
export default function useCompany(params?: IUseCompanyProps) {
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector(getAuthState);
  const { company, companyIdIri, companyId, companies } = useAppSelector(
    getCompanyState
  );

  useEffect(() => {
    // load company
    if (isLogged && companyIdIri && params?.loadCompany && !company.result) {
      dispatch(fetchCompanyThunk({ companyIdIri }));
    }
  }, [companyIdIri]);

  const getCompanyName = () => {
    const co = companies.find((c) => c.idIri === companyIdIri);
    if (co) {
      return co;
    } else {
      return null;
    }
  };

  return { company, companyIdIri, companyId, companies, getCompanyName };
}
