import { TextField } from '@mui/material';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { contactContext } from '../../study/components/studyContact/rightPanelContactBloc/StudyContactContent';
import { fetchContacts } from '../contactRequests';
import ContactChoiceContainer from './ContactChoiceContainer';
import styles from './contactForm.module.scss';

interface ILastNameAutocompleteProps {
  control: any;
  error?: boolean;
  errorMsg?: string;
  name: string;
}
function LastNameAutocomplete({
  control,
  name,
  error,
  errorMsg,
}: ILastNameAutocompleteProps): React.ReactElement {
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { setContacts } = useContext(contactContext);

  const handleChange = async (e: ChangeEventCustom, onChange: any) => {
    const value = e.target.value;
    onChange(value);
    if (value.length >= 3) {
      try {
        const list = await fetchContacts({ search: value }, companyIdIri);

        setContacts(list);
      } catch (error: any) {
        console.log('error fetch contact', error.status, error.message);
      }
    }
  };

  return (
    <div className={styles.autocompleteContainer}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            type="text"
            value={value}
            variant="outlined"
            error={error}
            helperText={errorMsg}
            placeholder="Nom"
            onChange={(e) => handleChange(e, onChange)}
          />
        )}
      />
      {<ContactChoiceContainer />}
    </div>
  );
}

export default LastNameAutocomplete;
