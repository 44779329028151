import { Tooltip } from '@mui/material';
import ItemActions from '../../../components/folderAndSectorActions/ItemActions';
import { useStylesFunc } from '../../../components/folderAndSectorActions/style';
import {
  DASHBOARD_PROSPECTION_ENTITY_NAME_LENGTH,
  LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH,
} from '../../../shared/constants';
import { stringCut } from '../../../shared/utils/stringFunctions';
import LeftPanelFolderIndex from './LeftPanelFolderIndex';
import styles from './folder.module.scss';

interface IFolderHeaderProps {
  folder: IFolder;
  isDashboard?: boolean;
  deployedFolder?: nullable<IFolder>;
  deployedSubFolder?: nullable<IFolder>;
}

export default function FolderHeader({
  folder,
  isDashboard,
  deployedFolder,
  deployedSubFolder,
}: IFolderHeaderProps) {
  const useStyles = useStylesFunc({ isPrimary: true });
  const { classes } = useStyles();

  return (
    <div
      className={`${styles.folderHeader} ${folder.parent ? styles.subfolder : ''} ${
        isDashboard ? 'dashboard' : ''
      }`}
      style={{ borderLeft: '4px solid ' + folder.markerColor }}
    >
      {folder.parent && <div className={styles.headerLibelle}>Sous-dossier</div>}

      {!isDashboard && <LeftPanelFolderIndex folder={folder} />}

      <div className={styles.titleContainer}>
        <Tooltip
          title={folder.name}
          placement="top-start"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <h3 className={styles.title}>
            {stringCut(
              folder.name,
              isDashboard
                ? DASHBOARD_PROSPECTION_ENTITY_NAME_LENGTH
                : LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH
            )}
          </h3>
        </Tooltip>

        {!folder.parent ? (
          <div className={styles.subCount}>{`${folder?.subCount} sous-dossier${
            folder?.subCount ?? 0 > 1 ? 's' : ''
          }, ${folder.plotStudiesCount} parcelle${
            folder.plotStudiesCount > 1 ? 's' : ''
          }`}</div>
        ) : (
          <div className={styles.subCount}>{`${folder.plotStudiesCount} parcelle${
            folder.plotStudiesCount > 1 ? 's' : ''
          }`}</div>
        )}
      </div>

      <div
        className={`${styles.iconContainer} ${isDashboard && styles.isDashboard}`}
      >
        <ItemActions
          type="folder"
          item={folder as IFolderSector<IFolder>}
          isOpen={
            folder.idIri === deployedSubFolder?.idIri ||
            folder.idIri === deployedFolder?.idIri
          }
          isDashboard={isDashboard}
        />
      </div>
    </div>
  );
}
