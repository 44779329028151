import { Dialog, DialogContent, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { modalsActions } from '../../modals/modalsSlice';
import { foldersActions, getFoldersState } from '../foldersSlice';
import {
  folderCreateThunk,
  folderUpdateThunk,
} from '../services/thunks/foldersThunk';
import FolderPinIconConatiner from './FolderPinIconConatiner';
import './editFolderModal.scss';

interface IEditFolderModalProps {
  open: boolean;
}

const EditFolderModal = ({ open }: IEditFolderModalProps) => {
  const [name, setName] = useState('');
  const [markerColor, setMarkerColor] = useState('#10B2F7');
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { folderForAction, subFolderParent } = useAppSelector(getFoldersState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (folderForAction) {
      setName(folderForAction.name);
      setMarkerColor(folderForAction.markerColor);
    }
  }, [folderForAction]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleMarkerColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMarkerColor(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(foldersActions.folderForActionReset());
    dispatch(foldersActions.subFolderParentReset());
    dispatch(modalsActions.folderEdit(false));
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      folderForAction
        ? folderUpdateThunk({
            folder: {
              name,
              markerColor,
              indexId: folderForAction.indexId,
            },
            folderIdIri: folderForAction.idIri,
            callback: handleCloseModal,
          })
        : folderCreateThunk({
            name,
            markerColor,
            parentFolder: subFolderParent ?? null,
            company: companyIdIri as string,
            callback: handleCloseModal,
          })
    );
  };

  return (
    <Dialog
      className="edit-folder-modal"
      fullWidth={true}
      maxWidth="xs"
      open={open}
      aria-labelledby="edit-folder-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent className="edit-folder-modal-container">
        <div className="xicon-wrapper" onClick={handleCloseModal}>
          <CloseIcon className="closeicon" />
        </div>
        <div className="title">
          {folderForAction ? `Editer le dossier` : 'Créer un dossier'}
        </div>
        <form className="folder-name" autoComplete="off" onSubmit={handleSubmit}>
          <div className="text-field">
            <TextField
              id="editFolderName"
              name="editFolderName"
              value={name}
              placeholder="Nom du dossier"
              type="text"
              variant="outlined"
              fullWidth={true}
              onChange={handleNameChange}
              required
            />
          </div>

          <div className="sub-title">Modifier la couleur du repère sur la carte</div>
          <FolderPinIconConatiner
            onMarkerColorChange={handleMarkerColorChange}
            markerColor={markerColor}
          />
          <div className="pin-btn">
            <button type="submit" className="ajouter-btn">
              Ajouter
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditFolderModal;
