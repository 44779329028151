import { Page } from '@react-pdf/renderer';
import GenericBloc from './componants/GenericBloc';
import GoodDetails from './componants/GoodDetails';
import PageFooter from './componants/PageFooter';
import PageHeader from './componants/PageHeader';
import PlotDetails from './componants/PlotDetails';
import UrbanismeInfos from './componants/UrbanismeInfos';
import { pdfStyles } from './pdfStyle';

interface IPageTwoProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  plotIds: string[];
  zonePlu: string;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
  goodDetails: {
    roomCount: number;
    floorCount: number;
    goodTypology: string;
    homeArea: number;
    landArea: number;
    comment: string;
  };
}

function PageTwo(datas: IPageTwoProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={datas.date} address={datas.address} fixed />

      <GenericBloc title="Détail de la parcelle">
        <PlotDetails {...datas.plotDatas} plotIds={datas.plotIds} />
      </GenericBloc>

      <GenericBloc title="Détail du bien">
        <GoodDetails {...datas.goodDetails} />
      </GenericBloc>

      <GenericBloc title="Informations d'urbanisme">
        <UrbanismeInfos
          zone={datas.zonePlu}
          parcelsOnZonePicture={datas.plotDatas.parcelsOnZonePicture}
        />
      </GenericBloc>

      <PageFooter fixed />
    </Page>
  );
}

export default PageTwo;
