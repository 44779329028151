import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import styles from './dateFilter.module.scss';
import DatePickerBase from './DatePickerBase';
interface IDateFilterProps {
  label: string;
  onChange: (value: DateFilterType) => void;
  dates: DateFilterType;
  minDate: string;
  hasDay?: boolean;
}
const checkValidStartEnd = (dates: DateFilterType) => {
  const newDates = { ...dates };
  if (newDates.start && newDates.end && newDates.start > newDates.end) {
    const newEndDate = endOfMonth(newDates.start);
    newDates.end = validDateFn(newEndDate);
  }

  return newDates;
};

const validDateFn = (date: Date) => {
  return date > new Date() ? new Date() : date;
};

function DateFilter({ label, onChange, dates, minDate, hasDay }: IDateFilterProps) {
  const handleChange = (e: Date | null, key: keyof DateFilterType) => {
    if (e) {
      let date = e;
      if (!hasDay) {
        date = key === 'start' ? startOfMonth(e) : endOfMonth(e);
      } else {
        date = key === 'start' ? startOfDay(e) : endOfDay(e);
      }
      // can't get futur date
      const validDate = validDateFn(date);

      let value = { ...dates, [key]: validDate };
      // check if end > start
      value = checkValidStartEnd(value);

      onChange(value);
    } else {
      // if e === null
      onChange({ ...dates, [key]: null });
    }
  };

  return (
    <div className={styles.dateFilterContainer}>
      <p>{label}</p>
      <div className={styles.dateFilter}>
        <DatePickerBase
          date={dates.start}
          minDate={minDate}
          onChange={handleChange}
          type="start"
          hasDay={hasDay}
        />{' '}
        et{' '}
        <DatePickerBase
          date={dates.end}
          minDate={minDate}
          onChange={handleChange}
          type="end"
          hasDay={hasDay}
        />
      </div>
    </div>
  );
}

export default DateFilter;
