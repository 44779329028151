import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import { memo, useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import AdvancedSearchMarkers from '../../features/advancedPlotSearch/map/AdvancedSearchMarkers';
import AdvancedSearchZone from '../../features/advancedPlotSearch/map/AdvancedSearchZone';
import BrownfieldsOnMap from '../../features/brownfields/components/BrownfieldsOnMap';
import BuildPermitsOnMap from '../../features/buildPermits/buildPermitsOnMap';
import DpeMarkersClusterGroup from '../../features/builtCaracteristics/dpe/dpeOnMap/DpeMarkersClusterGroup';
import DvfMarker from '../../features/dvf/DvfMarker';
import GeorisqueLayer from '../../features/georisques/GeorisqueLayer';
import NationalServitudesLayer from '../../features/nationalServitudes/NationalServitudesLayer';
import MultiPlotSelectLayer from '../../features/plot/multiplotSelectLayer';
import PlotServitudesLayers from '../../features/plotServitudes/plotServitudesLayers';
import ChangeView from './ChangeView';
import ControlTooltips from './ControlTooltips';
import EnvLayers from './EnvLayers';
import EventLayer from './EventLayer';
import FrancePolygon from './FrancePolygon';
import './Map.scss';
import ActivePlotBuildingMarkers from './activePlot/ActivePlotBuildingMarkers';
import ActivePlotGeo from './activePlot/ActivePlotGeo';
import ActivePlotMarker from './activePlot/ActivePlotMarker';

import { useLocation } from 'react-router';
import { useAppSelector } from '../../App/store';
import EntitiesDisplayLayer from '../../features/displayManager/components/DisplayManagerLayer';
import { getExternalPdfErrialState } from '../../features/externalPdfErrial/externalPdfErrialSlice';
import FolderPlotStudiesFitbound from '../../features/folders/map/FolderPlotStudiesFitbound';
import AltimetryLayer from '../../features/map/plugins/altimetryPlugin/AltimetryLayer';
import DrawPlugin from '../../features/map/plugins/drawPlugin/DrawPlugin';
import MeasureLayer from '../../features/map/plugins/measurePlugin/components/MeasureLayer';
import PluLayer from './PluLayer';
import SatelliteLayer from './SatelliteLayer';
import TownHallMarker from './TownHallMarker';
import ZoomButtonsMap from './ZoomButtonsMap';
import PmTiles from './pmTiles';

const Map = () => {
  const {
    externalPdfErrialRightPanelDisplay,
    isMultiPlotSelectorPdf,
  } = useAppSelector(getExternalPdfErrialState);
  const [externalPdfErrial, setExternalPdfErrial] = useState<boolean>(
    externalPdfErrialRightPanelDisplay
  );
  const location = useLocation();
  const path = location.pathname;
  const pathExternalPdfErrial = path === '/external_pdf_errial';

  useEffect(() => {
    setExternalPdfErrial(externalPdfErrialRightPanelDisplay);
  }, [externalPdfErrialRightPanelDisplay]);

  return (
    <MapContainer
      id="leafletMap"
      scrollWheelZoom={true}
      className={`urbn-map ${
        pathExternalPdfErrial && externalPdfErrial && 'external-pdf-errial'
      }`}
      zoomControl={false}
      doubleClickZoom={false}
    >
      {path === '/' && (
        <>
          <DrawPlugin />
          <MeasureLayer />
          <AltimetryLayer />
        </>
      )}
      <GeorisqueLayer />

      <ChangeView />

      <EventLayer />

      <DvfMarker />

      <PluLayer />

      <FrancePolygon />

      <SatelliteLayer />

      <PmTiles />

      {!isMultiPlotSelectorPdf && (
        <>
          <ActivePlotGeo />
          <ActivePlotMarker />
        </>
      )}
      <FolderPlotStudiesFitbound />
      <ActivePlotBuildingMarkers />

      <TownHallMarker />

      <EntitiesDisplayLayer />

      <MultiPlotSelectLayer />

      <AdvancedSearchMarkers />

      <AdvancedSearchZone />

      <EnvLayers />

      <BuildPermitsOnMap />

      <DpeMarkersClusterGroup />

      <BrownfieldsOnMap />

      <ControlTooltips />

      <NationalServitudesLayer />

      <PlotServitudesLayers />

      <ZoomButtonsMap />
    </MapContainer>
  );
};

export default memo(Map);
