import { Checkbox } from '@mui/material';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../displayManager/displayManagerSlice';
import { fetchFolderAndSubsPlotStudiesForDisplayThunk } from '../../displayManager/services/thunks/fetchFolderAndSubsPlotStudiesForDisplayThunk';
import { getIdIriTabFromFolderAndSubs } from '../../displayManager/utils';
import styles from './folder.module.scss';
import { makeStyles } from 'tss-react/mui';

interface ILeftPanelFolderIndexProps {
  folder: IFolder;
}

const useStyles = makeStyles()({
  root: {
    padding: '5px',
  },
});

export default function LeftPanelFolderIndex({
  folder,
}: ILeftPanelFolderIndexProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { displayedFolders } = useAppSelector(getDisplayManagerState);

  const handleFolderCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? dispatch(
          fetchFolderAndSubsPlotStudiesForDisplayThunk({
            folder,
          })
        )
      : dispatch(
          displayManagerActions.entitiesRemoveByParent(
            getIdIriTabFromFolderAndSubs(folder)
          )
        );
  };

  return (
    <>
      <Checkbox
        classes={classes}
        color="primary"
        size="small"
        checked={displayedFolders.includes(folder.idIri)}
        onChange={handleFolderCheckboxChange}
      />

      <div className={styles.index} style={{ backgroundColor: folder.markerColor }}>
        {folder.indexId}
      </div>
    </>
  );
}
