export const AccountTabsValues = [
  { id: 'abonnement', title: 'Abonnement', role: ['user'] },
  { id: 'team', title: `Gérer l'équipe de prospection`, role: ['admin', 'manager'] },
  { id: 'pwd_change', title: 'Changer de mot de passe', role: ['user'] },
  { id: 'faq', title: 'FAQ et Formation', role: ['user'] },
  { id: 'rdv', title: 'Mes rendez-vous à venir', role: ['user'] },
];

export const dashboardTabsValues = [
  { id: 'directory', libelle: 'Annuaire', role: ['user'] },
  { id: 'agenda', libelle: 'Agenda', role: ['user'] },
  { id: 'reporting', libelle: 'Reporting', role: ['admin', 'manager'] },
  { id: 'folders', libelle: 'Dossiers', role: ['user'] },
  { id: 'dataowner', libelle: 'Datas Proprietaires', role: ['user'] },
];
