import { StyleSheet, Text, View } from '@react-pdf/renderer';
import LocalServitude from './LocalServitude';

const styles = StyleSheet.create({
  localServitudes: { fontSize: '12px' },
  noServitudes: { fontSize: '12px', fontWeight: 300, width: '90%' },
  subTitle: { marginTop: '-10px', fontWeight: 300 },
  servitudesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftSection: { width: '45%', marginRight: '20px' },
  rightSection: { width: '45%' },
});
interface ILocalServitudesProps {
  ponctPresc: string[] | null;
  linPresc: string[] | null;
  infoSurf: string[] | null;
  infoPonc: string[] | null;
  servOth: string[] | null;
  scot: string[] | null;
}
function LocalServitudes(props: ILocalServitudesProps) {
  const allError = Object.values(props).every((e) => e === null);
  const allEmpty = Object.values(props).every((e) => e && e.length === 0);

  const display = (tab: string[] | null) => (tab?.length ?? 0) > 0;

  if (allEmpty) {
    return (
      <View style={styles.noServitudes}>
        <Text>
          Les données publiques ne font état d&#39;aucune servitudes impactant la/les
          parcelle(s) à l&#39;étude. îl est recommandé de contacter les services
          instructeurs de la commune et de consulter l&#39;intégralité des documents
          d&#39;urbanisme pour vérixer l&#39;absence de servitudes sur la/les
          parcelle(s).
        </Text>
      </View>
    );
  }

  if (allError) {
    return (
      <View style={styles.noServitudes}>
        <Text>
          Les données publiques concernant les servitudes sur la/les parcelle(s) à
          l&#39;étude ne sont pas disponibles pour le moment. Il est conseillé de
          contacter les services instructeurs de la commune et de consulter les
          documents d&#39;urbanisme pour vérifier s&#39;il y a des servitudes sur
          la/les parcelle(s) étudiée(s).
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.localServitudes}>
      <Text style={styles.subTitle}>
        Liste non exhaustive sous réserve de vérification dans les documents
        d&#0039;urbanisme opposables
      </Text>
      <View style={styles.servitudesContainer}>
        {display(props.ponctPresc) && (
          <LocalServitude
            title="Prescriptions Ponctuelles"
            rows={props.ponctPresc}
          />
        )}
        {display(props.linPresc) && (
          <LocalServitude title="Prescriptions Linéaires" rows={props.linPresc} />
        )}
        {display(props.infoSurf) && (
          <LocalServitude title="Informations Surfaciques" rows={props.infoSurf} />
        )}
        {display(props.infoPonc) && (
          <LocalServitude title="Informations Ponctuelles" rows={props.infoPonc} />
        )}
        {display(props.servOth) && (
          <LocalServitude title="Autres servitudes" rows={props.servOth} />
        )}
        {display(props.scot) && (
          <LocalServitude
            title="Schema de Cohérence territorial"
            rows={props.scot}
          />
        )}
      </View>
    </View>
  );
}

export default LocalServitudes;
