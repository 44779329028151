import { memo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import BackArrow from '../../assets/images/fermerback.png';
import Logo from '../../assets/images/logo.png';
import RightArrow from '../../assets/images/rightArrow.png';
import { getAuthState } from '../../features/auth/authSlice';
import LeftPanelErrialBloc from '../../features/externalPdfErrial/leftPanelModalExternalPDFErrial/LeftPanelErrialBloc';
import { InputTabsContext } from '../../layout';
import panelsActions from '../../redux/Panels/actions';
import { getPanelState } from '../../redux/Panels/reducer';
import { favoritePlacesActions } from '../../redux/favoritePlaces/actions';
import InputTabs from './InputTabs';
import MenuTabs from './MenuTabs';
import './styles/leftPanelModal.scss';

function LeftPanelModal() {
  const { closeRightPanel } = useRightPanel();
  const { leftPanelDisplay, rightPanelDisplay } = useAppSelector(getPanelState);
  const { user } = useAppSelector(getAuthState);
  const { resetContext } = useContext(InputTabsContext);
  const location = useLocation();
  const path = location.pathname;
  const pathHome = path === '/';
  const pathExternalPdfErrial = path === '/external_pdf_errial';
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user) {
      resetContext();
    }
  }, [user]);

  const handleCollapseIcon = () => {
    dispatch(
      leftPanelDisplay
        ? panelsActions.leftPanel.collapse()
        : panelsActions.leftPanel.uncollapse()
    );
    dispatch(favoritePlacesActions.windowDisplay.set(false));
    if (rightPanelDisplay) closeRightPanel();
  };

  return (
    <div
      className={`left-panel ${
        !leftPanelDisplay && !pathExternalPdfErrial && 'collapsed-panel'
      } ${pathExternalPdfErrial && 'external-pdf-errial'}`}
    >
      {pathHome && (
        <div
          className={`collapse-icon-container ${
            !leftPanelDisplay && 'collapsed-icon'
          }`}
          onClick={handleCollapseIcon}
        >
          <img src={!leftPanelDisplay ? RightArrow : BackArrow} alt="icon" />
        </div>
      )}

      {leftPanelDisplay || pathExternalPdfErrial ? (
        <>
          <div className="left-panel-search-container">
            {pathHome && (
              <div className="left-panel-header">
                <img src={Logo} alt="logo" />
              </div>
            )}
            <InputTabs />

            {pathExternalPdfErrial && <LeftPanelErrialBloc />}
          </div>

          {pathHome && <MenuTabs isCollapsed={!leftPanelDisplay} />}
        </>
      ) : (
        pathHome && <MenuTabs isCollapsed={!leftPanelDisplay} />
      )}
    </div>
  );
}

export default memo(LeftPanelModal);
