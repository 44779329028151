import DeleteIcon from '../../../../../assets/images/delete.png';
import EditIcon from '../../../../../assets/images/edit.png';
import RulerIcon from '../../../../../assets/images/measure.png';
import AltimetryIcon from '../../../../../assets/images/toolbar/altimetryIcon.svg';
import FolderIcon from '../../../../../assets/images/toolbar/bouton-multi-folder.svg';
import MultiplotStart from '../../../../../assets/images/toolbar/multiplot-start.svg';
import CreateIcon from '../../../../../assets/images/toolbar/polygon.png';
import { MultiselectToolbarActionEnum } from '../../../../plot/multiPlotSelectFeature/types/multiselectToolbarEnums';
import AltimetryActionEnum from '../../altimetryPlugin/types/altimetryActionEnum';
import { DrawIconEnum } from '../../drawPlugin/types/drawActionEnum';
import { MeasureToolbarButtonEnum } from '../../measurePlugin/types/measureActionEnum';

const getToolbarIcon = (icon: IconType | null) => {
  switch (icon) {
    case DrawIconEnum.DRAW_ICON_START:
      return CreateIcon;
    case DrawIconEnum.DRAW_ICON_EDIT_SAVE:
      return EditIcon;
    case 'SAVE_MULTIPLOT':
      return FolderIcon;
    case DrawIconEnum.DRAW_ICON_DELETE:
      return DeleteIcon;
    case MeasureToolbarButtonEnum.MEASURE_START:
      return RulerIcon;
    case AltimetryActionEnum.ALTIMETRY_START:
      return AltimetryIcon;
    case MultiselectToolbarActionEnum.MULTISELECT_START:
      return MultiplotStart;
    default:
      return null;
  }
};

export default getToolbarIcon;
