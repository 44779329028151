import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '../../../../../App/store';
import SelectFormCustom from '../../../../../components/Common/FormComponents/SelectFormCustom';
import TextAreaFormCustom from '../../../../../components/Common/FormComponents/TextAreaFormCustom';
import SubmitButton from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/SubmitButton';

import { studyContactContext } from '../../../../../components/RightPanelModal/TabsContent/bodys/Prospection';
import createStudyProcedure from '../../../services/createStudyProcedure';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import ProcedureTypeSelect from './ProcedureTypeSelect';
import UserSelect from './UserSelect';
import {
  initalProcedureValues,
  procedureValidationSchema,
} from './procedureValidation';
import DatePickerCustom from '../../../../../components/Common/DatePickerCustom';

interface IProcedureFormProps {
  closeForm?: () => void;
  onCreate: (data: IStudyProcedure) => void;
}

function StudyProcedureForm({
  closeForm,
  onCreate,
}: IProcedureFormProps): JSX.Element {
  const { state } = useContext(studyContactContext);
  const { type, idIri } = useAppSelector(getStudyParamsState);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ProcedureFormValues>({
    defaultValues: initalProcedureValues,
    resolver: yupResolver(procedureValidationSchema()),
  });

  const contactsForSelect = (): ISelectItem[] => {
    return (
      state?.map((c) => ({
        value: c.idIri as string,
        display: `${c.contact.firstName} ${c.contact.lastName}`,
      })) ?? []
    );
  };

  const handleCreate = async (body: StudyProcedureExtended) => {
    try {
      const sp = await createStudyProcedure(body);
      onCreate(sp);
      reset();
      if (closeForm) {
        closeForm();
      }
    } catch (error) {
      console.log('error creatinf studProdedure', error);
    }
  };
  useEffect(() => {
    if (idIri) {
      setValue('studyIdIri', idIri);
    }
  }, [idIri]);

  const handleFormSubmit = (values: ProcedureFormValues) => {
    if (isEmpty(errors)) {
      const body: StudyProcedureExtended = {
        ...values,
        contact: null,
        contactRole: null,
      };
      if (values.studyContactIdIri) {
        const contact =
          (state?.find(
            (f) => f.idIri === values.studyContactIdIri
          ) as IStudyContact) ?? null;
        body.contact = contact?.contact.idIri;
        body.contactRole = contact.role.idIri;
      }
      delete body.studyContactIdIri;

      handleCreate(body);
    }
  };

  return (
    <form className="procedure-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="top-block">
        <UserSelect
          control={control}
          name="responsable"
          error={Boolean(errors.responsable)}
          errorMsg={errors.responsable?.message as string}
        />
        <ProcedureTypeSelect
          control={control}
          name="type"
          error={Boolean(errors.type)}
          errorMsg={errors.type?.message as string}
        />
        {type !== 'sector' && (
          <SelectFormCustom
            control={control}
            name="studyContactIdIri"
            className=""
            displayEmpty
            noValue={isEmpty(state) ? 'Ajouter un contact' : 'Choisir un contact'}
            items={contactsForSelect()}
            error={Boolean(errors.studyContactIdIri)}
            errorMsg={errors.studyContactIdIri?.message as string}
          />
        )}
        <Controller
          control={control}
          name="eventDate"
          render={({ field: { onChange, value } }) => (
            <DatePickerCustom
              value={new Date(value)}
              onChange={(e: Date | null) => onChange(e?.toISOString() as string)}
              format="d MMM yyyy"
              actions={['accept', 'cancel']}
            />
          )}
        />
      </div>
      <div className="bottom-block">
        <TextAreaFormCustom
          control={control}
          name="comment"
          className="procedure-comment"
        />
      </div>

      <SubmitButton label="Ajouter une démarche" />
    </form>
  );
}

export default StudyProcedureForm;
