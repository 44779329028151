import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IFormResponseIconProps {
  failure?: boolean;
}

function FormResponseIcon({ failure }: IFormResponseIconProps) {
  return (
    <div className={`signup-demand-response-icon-container ${failure && 'red'}`}>
      <FontAwesomeIcon icon={failure ? faXmark : faCheck} />
    </div>
  );
}

export default FormResponseIcon;
