import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../App/store';
import { studyActions } from '../../../../../../features/study/slices/studySlice';
import { RecursiveSubFolderUpdatedElement } from '../../../../../../features/study/utils/studyEnums';
import useUsers from '../../../../../../features/users/useUsers';
import SelectCustom from '../../../../../Common/FormComponents/SelectCustom';
interface IPlotResponsableSelectProps {
  responsable: User;
  disabled?: boolean;
  disableLabel?: boolean;
  onUpdate: (params: UpdateStudyManagmentProps) => void;
}

export default function ResponsableSelect({
  responsable,
  disabled,
  disableLabel,
  onUpdate,
}: IPlotResponsableSelectProps) {
  const { usersForSelect, getUserById } = useUsers({});
  const [userIdIri, setUserIdIri] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (responsable) {
      setUserIdIri(responsable?.idIri);
    } else {
      setUserIdIri(undefined);
    }
  }, [responsable]);

  const handleChange = (e: ChangeEventCustom) => {
    const userIdIri = e.target.value;

    setUserIdIri(userIdIri);
    dispatch(
      studyActions.setRecursiveSubFolderUpdatedElement(
        RecursiveSubFolderUpdatedElement.RESPONSABLE
      )
    );
    onUpdate({ responsable: getUserById(userIdIri) as User });
  };

  return (
    <div className="responsable-select">
      {!disableLabel && <label>Responsable :</label>}
      <SelectCustom
        name="responsable"
        noValue="choisir un responsable"
        items={usersForSelect()}
        value={userIdIri ?? ''}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
      />
    </div>
  );
}
