import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    width: '25%', // 22% for 4 blocs
    color: '#4E55A2',
    backgroundColor: '#fff',
    alignItems: 'center',
    border: '1px solid #eee',
    borderRadius: '5px',
    padding: '5px 0',
  },
  later: { fontSize: '12px', marginBottom: '4px', color: '#f00' },
  hidden: {
    display: 'none',
  },
  dataStyle: { fontSize: '15px', marginBottom: '4px', color: '#4E55A2' },
  dataStyleRed: { fontSize: '15px', marginBottom: '4px', color: '#f00' },
  textStyle: { fontSize: '9px', color: '#5C5C5D' },
});

interface IUrbanismeInfoBlocProps {
  data: IEnvPDFDisplay;
  text: string;
  color?: string;
}
const UrbanismeInfoBloc = ({ data, text, color }: IUrbanismeInfoBlocProps) => {
  if (color) {
    return (
      <View style={styles.section}>
        <Text
          style={
            color === 'red' && data.indice ? styles.dataStyleRed : styles.dataStyle
          }
        >
          {data.libelle}
        </Text>
        <Text style={styles.textStyle}>{text}</Text>
      </View>
    );
  } else {
    return (
      <View style={styles.section}>
        <Text style={data.indice ? styles.dataStyleRed : styles.dataStyle}>
          {data.indice}
          <Text style={styles.dataStyle}>{` ${data.libelle}`}</Text>
        </Text>
        <Text style={styles.textStyle}>{text}</Text>
      </View>
    );
  }
};

export default UrbanismeInfoBloc;
