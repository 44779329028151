import { CircularProgress } from '@mui/material';
import styles from './individualCiruclarLoader.module.scss';

interface IIndividualCircularLoaderProps {
  size: number;
  className?: string;
  fullWidth?: boolean;
}

const IndividualCircularLoader = ({
  size = 200,
  className = '',
  fullWidth = true,
}: IIndividualCircularLoaderProps): JSX.Element => {
  return (
    <div
      className={`${styles.loaderCircular} ${className}${
        fullWidth && styles.fullWidth
      }`}
    >
      <CircularProgress size={size} />
    </div>
  );
};

export default IndividualCircularLoader;
